import { Pipe, PipeTransform } from '@angular/core';
import { TruncateTextPipe } from '../truncate-text/truncate-text.pipe';

/**
 * We want to make sure that we are covering at-least 30 base pairs to make sure the
 * transformed string is not misinterpreted by the end user.
 *
 * Based on the statistics report from 'Lara Sucheston-Campbell', it looks like if we
 * consider the 41 character length of the string value, it should cover the requirement
 * of 30 base pairs.
 *
 * Reference: https://rds-csi.atlassian.net/browse/ISTN-4416?focusedCommentId=148524
 */
const THIRTY_BASE_PAIRS_CHARACTER_COUNT = 41;

@Pipe({
  name: 'transformToThirtyBasePairs'
})
export class TransformToThirtyBasePairsPipe implements PipeTransform {
  transform(value: string): string {
    return new TruncateTextPipe().transform(value, THIRTY_BASE_PAIRS_CHARACTER_COUNT);
  }
}
