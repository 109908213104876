// Adapted from:
// https://github.com/oktadeveloper/okta-angular-sign-in-widget-example
// https://github.com/okta/okta-signin-widget

import { Injectable } from '@angular/core';

import { CommonService } from 'app/services/common.service';
import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js';

import { AuthService } from 'app/services/auth.service';

@Injectable()
export class Okta {
  constructor(private authService: AuthService) {}

  createWidget(recoveryToken?: string) {
    // We might need to update some options to support HTTPS (eg. for nightly and dev envs)
    // https://github.com/okta/okta-signin-widget/blob/master/MIGRATING.md#https-is-enforced-by-default
    const options: any = {
      baseUrl: this.authService.baseUrl,
      language: CommonService.getUserLocale(),
      i18n: {
        en: {
          'primaryauth.title': 'Log on',
          'primaryauth.submit': 'Log on',
          needhelp: 'Need help to log on?',
          goback: 'Back to Log on',
          'errors.E0000004': 'Log on failed!'
        }
      }
    };

    if (recoveryToken) options.recoveryToken = recoveryToken;

    return new OktaSignIn(options);
  }
}
