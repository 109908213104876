// This module defines some custom flexlayout responsive breakpoints
// https://github.com/angular/flex-layout/wiki/Breakpoints

import { BREAKPOINT } from '@angular/flex-layout';

const RESPONSIVE_BREAKPOINTS = [
  {
    alias: 'lt-sm-custom',
    mediaQuery: 'screen and (max-width: 800px)'
  },
  {
    alias: 'lt-md-custom',
    mediaQuery: 'screen and (max-width: 1023px)'
  }
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: RESPONSIVE_BREAKPOINTS,
  multi: true
};
