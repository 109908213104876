import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PNItemNode, PertinentNegative } from '../model/entities/pertinentNegative';
import { ListItem } from '../model/entities/baseEntity';

@Injectable()
export class PertinentNegativeDatabase {
  dataChange = new BehaviorSubject<PNItemNode[]>([]);

  get data(): PNItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize([]);
  }

  initialize(pertinentNegatives: PertinentNegative[]) {
    const data = this.buildTree(pertinentNegatives);
    this.dataChange.next(data);
  }

  /**
   * Convert pertinentNegative list to tree node list where biomarkers are children of diseases.
   * Add an empty node in each branch so that an autoComplete will be added for adding new.
   */
  buildTree(pertinentNegatives: PertinentNegative[]): PNItemNode[] {
    const tree = pertinentNegatives.map((negative) => {
      const diagnosis = negative.diagnosis;
      const biomarkers = negative.biomarkers;
      const diseaseNode = new PNItemNode(diagnosis.id, diagnosis.name, null, []);
      diseaseNode.children = biomarkers.map(
        (biomarker) => new PNItemNode(biomarker.id, biomarker.name, diseaseNode, null)
      );
      diseaseNode.children.push(PNItemNode.emptyBiomarker(diseaseNode));
      return diseaseNode;
    });
    tree.push(PNItemNode.emptyDisease());
    return tree;
  }

  updateDisease(diseaseNode: PNItemNode, disease: ListItem) {
    diseaseNode.id = disease.id;
    diseaseNode.value = disease.value;
    diseaseNode.children.push(PNItemNode.emptyBiomarker(diseaseNode));
    this.data.push(PNItemNode.emptyDisease());
    this.dataChange.next(this.data);
  }

  updateBiomarker(biomarkerNode: PNItemNode, biomarker: ListItem) {
    biomarkerNode.id = biomarker.id;
    biomarkerNode.value = biomarker.value;
    biomarkerNode.parent.children.push(PNItemNode.emptyBiomarker(biomarkerNode.parent));
    this.dataChange.next(this.data);
  }

  removeDisease(diseaseNode: PNItemNode) {
    const id = this.data.indexOf(diseaseNode);
    this.data.splice(id, 1);
    this.dataChange.next(this.data);
  }

  removeBiomarker(biomarkerNode: PNItemNode) {
    const id = biomarkerNode.parent.children.indexOf(biomarkerNode);
    biomarkerNode.parent.children.splice(id, 1);
    this.dataChange.next(this.data);
  }

  /**
   *  Convert tree node list to pertinentNegative list and trim duplicates
   */
  buildPertinentNegatives() {
    let trimmed = false;
    const existingDiseases = {};
    const existingBiomarkers = {};
    this.data.forEach((disease) => {
      if (disease.id === null || disease.children.length === 1) {
        return;
      }

      if (!existingDiseases[disease.id]) {
        existingDiseases[disease.id] = { id: disease.id, name: disease.value };
        existingBiomarkers[disease.id] = [];
      }

      disease.children.forEach((biomarker) => {
        if (biomarker.id === null) {
          return;
        }
        if (!existingBiomarkers[disease.id][biomarker.id]) {
          existingBiomarkers[disease.id][biomarker.id] = {
            id: biomarker.id,
            name: biomarker.value
          };
        } else {
          trimmed = true;
        }
      });
    });

    const pertinentNegatives = Object.keys(existingDiseases).map((diseaseId) => {
      const diagnosis = existingDiseases[diseaseId];
      const biomarkers = Object.keys(existingBiomarkers[diseaseId]).map(
        (bioId) => existingBiomarkers[diseaseId][bioId]
      );
      return { diagnosis, biomarkers };
    });

    return { pertinentNegatives, trimmed };
  }
}
