import { BaseEntity } from './baseEntity';
export class DbnsfpAnnotation extends BaseEntity {
  siftScore: number;
  siftRankScore: number;
  siftPrediction: string;
  polyphen2HvarScore: number;
  polyphen2HvarRankScore: number;
  polyphen2Prediction: string;
  metaLrScore: number;
  metaLrRankScore: number;
  metaLrRankPrediction: string;
  gerpRs: number;
  gerpRsRankScore: number;
  dbscSnvAdaScore: number;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
