import { Component, Input } from '@angular/core';
import { VARIANT_FILTER_TYPE } from 'app/model/valueObjects/variantFilter';

@Component({
  selector: 'app-default-biomarker-filter-text',
  templateUrl: './default-biomarker-filter-text.component.html'
})
export class DefaultBiomarkerFilterTextComponent {
  @Input() biomarkerType: string;
  variantFilterType = VARIANT_FILTER_TYPE;
}
