<div id="report-header-container" [formGroup]="assayForm">
  <div class="company-header">

    <div class="company-info-section">
      <div class="company-title-container">
        <div class="company-logo">
          <input id="logo-upload" type="file" (change)="onFileChange($event)" #fileInput />
          <div *ngIf="editable && (assayForm.get('logo').invalid || !assayForm.get('logo').value)">
            <div class="upload-link">
              <owc-typography [variant]="'body1'" class="cursor-pointer" (click)="canUpdateLogo ? fileInput.click() : null"
                i18n="ReportHeader.Label@@click-to-add-logo">
                + click to add logo
              </owc-typography>
              <div *ngIf="assayForm.get('logo').invalid" [ngSwitch]="firstLogoError">
                <owc-typography *ngSwitchCase="'big'" class="error-msg" variant="caption"
                  i18n="ReportHeader.Notes(error)@@image-should-be-smaller-than-300x180-px">
                  Image should be smaller than 300x180 px
                </owc-typography>
                <owc-typography *ngSwitchCase="'small'" class="error-msg" variant="caption"
                  i18n="ReportHeader.Notes(error)@@image-should-be-larger-than-34x34-px">
                  Image should be larger than 34x34 px
                </owc-typography>
                <owc-typography *ngSwitchCase="'filesize'" class="error-msg" variant="caption"
                  i18n="ReportHeader.Notes(error)@@error-file-size-is-larger-than-1-mb">
                  Error: File size is larger than 1 MB
                </owc-typography>
              </div>
            </div>
            <owc-typography [variant]="'caption'" i18n="ReportHeader.Notes(caption)@@minimum-size-34x34-maximum-size-300x180">
              minimum size 34x34, maximum size 300x180
            </owc-typography>
          </div>
          <!-- assayForm can be in the 'disabled' state, and we still want to show the logo but have it not be editable (ISTN-4121) -->
          <!-- Form status values are mutually exclusive - https://angular.io/api/forms/AbstractControl#status -->
          <div [class.clear-logo]="editable && assayForm.get('logo').valid"
              *ngIf="!assayForm.get('logo').invalid && assayForm.get('logo').value">
            <div class="logo-container">
              <img alt="Custom Report Logo" [src]="logoSrc"/>
            </div>
            <owc-icon name="circle_clear" type="filled" *ngIf="editable && assayForm.get('logo').valid"
              class="clear-icon" (click)="clearFile()"></owc-icon>
          </div>
        </div>
      </div>
      <div class="company-contact-container">
        <ng-container *ngIf="editable">
          <input id="addressLine1" formControlName="addressLine1"
                (keydown.enter)="avoidSubmit($event)"
                maxlength={{fieldLengths.addressField}}
                placeholder="address line 1" i18n-placeholder="@@address-line-1"
                spellcheck="false" appNoAutoComplete>
          <input id="addressLine2" formControlName="addressLine2"
                (keydown.enter)="avoidSubmit($event)"
                maxlength={{fieldLengths.addressField}}
                placeholder="address line 2" i18n-placeholder="@@address-line-2"
                spellcheck="false" appNoAutoComplete>
          <input id="addressLine3" formControlName="addressLine3"
                (keydown.enter)="avoidSubmit($event)"
                maxlength={{fieldLengths.addressField}}
                placeholder="address line 3" i18n-placeholder="@@address-line-3"
                spellcheck="false" appNoAutoComplete>
        </ng-container>

        <ng-container *ngIf="!editable">
          <owc-typography [variant]="'caption'" class="address-display">{{data?.reportTemplate?.addressLine1}}</owc-typography>
          <owc-typography [variant]="'caption'" class="address-display">{{data?.reportTemplate?.addressLine2}}</owc-typography>
          <owc-typography [variant]="'caption'" class="address-display">{{data?.reportTemplate?.addressLine3}}</owc-typography>
        </ng-container>
      </div>
    </div>
    <div class="include-roche-logo-container">
      <owc-checkbox id="include-roche-logo-checkbox" *ngIf="editable" formControlName="rocheLogo" ngDefaultControl
        i18n="ReportHeader.Notes@@include-roche-logo">
        Include Roche logo
      </owc-checkbox>
      <div class="roche-logo-image" [class.hidden-image]="!this.assayForm.get('rocheLogo').value"></div>
    </div>
  </div>
</div>
