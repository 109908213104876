import { FormGroup } from '@angular/forms';

export function CheckBeforeInput(checkControl: string, inputControl: string, reg: RegExp) {
  return (group: FormGroup): { [key: string]: any } => {
    const check = group.controls[checkControl];
    const input = group.controls[inputControl];

    if (check.value) {
      if (!input.value && input.value !== 0) {
        return { missingValue: true };
      } else if (!reg.test(input.value)) {
        return { invalidValue: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}
