import { Component } from '@angular/core';
import { LoaderService } from 'app/services/loader.service';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {
  constructor(public loaderService: LoaderService) {}
}
