import { BaseEntity } from './baseEntity';
export class ManualOverride extends BaseEntity {
  id: string;
  revision: number;
  diagnosisId: string;
  tier?: string;
  tierExplanation?: string;
  tierReasons?: string[];
  report?: boolean;
  reportExplanation?: string;
  lastModifiedByUserName?: string;
  lastModifiedByUserId?: string;
  lastModifiedAt: string;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
