import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PertinentNegativeBiomarker } from 'app/model/entities/biomarker';
import { VariantService } from 'app/services/variant.service';
import { CaseNavigationService } from 'app/services/case-navigation.service';
import { has as _has } from 'lodash';
import { Observable, Subject, filter, of, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-pertinent-negative-chip',
  templateUrl: './pertinent-negative-chip.component.html',
  styleUrls: ['./pertinent-negative-chip.component.scss']
})
export class PertinentNegativeChipComponent implements OnInit, OnDestroy {
  @Input() biomarker: PertinentNegativeBiomarker;
  @Input() reportMode: boolean;
  @Input() caseId: string;
  hasPermissionForEditBiomarkerMetadata$: Observable<boolean>;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private variantService: VariantService,
    private caseNavigationService: CaseNavigationService
  ) {}

  ngOnInit() {
    this.hasPermissionForEditBiomarkerMetadata$ = this.getHasPermissionForEditBiomarkerMetadata$();
  }

  togglePertinentNegative(): void {
    this.getHasPermissionForEditBiomarkerMetadata$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((hasPermission) => hasPermission),
        tap(() => {
          this.variantService.updateBiomarkerMetadata(this.caseId, this.biomarker, {
            pertinentNegative: !this.biomarker.pertinentNegative
          });
          this.caseNavigationService.setLastVisitedId('pertinentNegativeSection');
        })
      )
      .subscribe();
  }

  getHasPermissionForEditBiomarkerMetadata$(): Observable<boolean> {
    return of(_has(this.biomarker._links, 'editBiomarkerMetadata'));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
