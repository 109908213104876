import { AuthService } from 'app/services/auth.service';

// Load data in these services before bootstapping the application
export function appInitializerFactory(authService: AuthService) {
  return () =>
    Promise.all([authService.loadCurrentUser(), authService.loadAuthConfig()]).catch((e) => {
      alert(
        'Failed to initialize the application. Please try refreshing the page.\n\nIf this error persists contact support.'
      );
      throw e;
    });
}
