import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { get as _get } from 'lodash';
import { Localization } from 'app/model/valueObjects/localization';
import { CommonService } from 'app/services/common.service';
import { CategoryOrVariant, VariantCard } from 'app/model/entities/analytics';

@Component({
  selector: 'app-variant-frequency-panel',
  templateUrl: './variant-frequency-panel.component.html',
  styleUrls: ['./variant-frequency-panel.component.scss']
})
export class VariantFrequencyPanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() alternativeVariantCard: VariantCard;
  @Input() variantCard: VariantCard;
  @Input() casesApproved: number;
  @Input() categoryOrVariant: CategoryOrVariant;
  @Input() logoSrc: string;

  activeVariantCard: VariantCard;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public localization: Localization, public commonService: CommonService) {}

  ngOnInit() {
    this.activeVariantCard = this.variantCard;
  }

  /* this handler in needed as the OnInit would have the old
   * variantCard when the page is loaded more than once */
  ngOnChanges() {
    this.activeVariantCard = this.variantCard;
  }

  changeActiveCard(activeCard) {
    this.activeVariantCard = activeCard;
  }

  get geneStats() {
    return !this.activeVariantCard.category
      ? 0
      : this.activeVariantCard.category.numCases / this.activeVariantCard.diagnosis.numCases;
  }

  get diagnosisStats() {
    return this.casesApproved ? this.activeVariantCard.diagnosis.numCases / this.casesApproved : 0;
  }

  variantStats(variant) {
    return variant.numCases / this.activeVariantCard.category.numCases;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  combinedVariantName(variantNames) {
    return CommonService.stringVariantName(variantNames);
  }
}
