<div class="chip-therapy" [ngClass]="therapyClass$ | async" fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="start stretch">
  <div class="chip-therapy-icon-container">
    <div class="icon-therapy-lens">
      <owc-icon name="circle_clear" type="filled" *ngIf="type === 'resistanceCause'" class="md-12 icon-therapy-lens"></owc-icon>
    </div>
  </div>
  <div class="therapy-names mat-body-2">
    <div class="bold-text therapy-name">{{names.join(' + ')}}</div>
    <div class="resistance-tag">
      <span *ngIf="type === 'resistanceCause'" i18n="TherapyChip.Notes@@resistance">resistance</span>
      <span *ngIf="type === 'resistance'" i18n="TherapyChip.Notes@@altered-response">altered response</span>
    </div>
  </div>
</div>
