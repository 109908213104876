import { Directive, ElementRef, OnInit } from '@angular/core';
/**
 * @export @class AutoFocusDirective
 * @selector autoFocus
 *
 * @issue Sometimes HTML DOM nodes are not auto-focusing due to various reasons. For example, if the asynchronous BE API calls take longer than usual.
 *
 * @solution `AutoFocusDirective` will focus on the DOM element reference as soon as it's being rendered on the UI. Use `autoFocus` directive to the HTML element to see the magic.
 *
 * @example `<input formControlName="caseId" autoFocus>`
 */
@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private focusElement: ElementRef) {}

  ngOnInit(): void {
    this.focusElement?.nativeElement?.focus();
  }
}
