import { BaseEntity } from './baseEntity';

export class ClinicalAnnotation extends BaseEntity {
  geneSummaries: string[];
  variantBriefs: string[];
  variantSummaries: string[];
  groupSummaries: string[];
  groupBriefs: string[];
  combinationSummaries: string[];
  combinationBriefs: string[];

  constructor(jsonData: any) {
    super(jsonData);

    if (!this.arrayExist(jsonData.geneSummaries)) {
      this.geneSummaries = null;
    }

    if (!this.arrayExist(jsonData.variantBriefs)) {
      this.variantBriefs = null;
    }

    if (!this.arrayExist(jsonData.variantSummaries)) {
      this.variantSummaries = null;
    }

    if (!this.arrayExist(jsonData.groupSummaries)) {
      this.groupSummaries = null;
    }

    if (!this.arrayExist(jsonData.groupBriefs)) {
      this.groupBriefs = null;
    }

    if (!this.arrayExist(jsonData.combinationSummaries)) {
      this.combinationSummaries = null;
    }

    if (!this.arrayExist(jsonData.combinationBriefs)) {
      this.combinationBriefs = null;
    }
  }

  get isGeneContentEmpty() {
    return !this.geneSummaries;
  }

  get isVariantContentEmpty() {
    return (
      !this.variantBriefs &&
      !this.variantSummaries &&
      !this.groupBriefs &&
      !this.groupSummaries &&
      !this.combinationBriefs &&
      !this.combinationSummaries
    );
  }

  private arrayExist(arr) {
    return arr && arr.filter((data) => data && data.trim()).length;
  }
}
