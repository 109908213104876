import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { TermsPageComponent } from './terms-page/terms-page.component';

@NgModule({
  imports: [SharedModule],
  declarations: [LoginPageComponent, TermsPageComponent]
})
export class LoginModule {}
