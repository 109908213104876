import { BaseEntity } from './baseEntity';
export class Coordinate extends BaseEntity {
  id?: string;
  allelicVariant?: AllelicVariant;
  copyNumberVariant?: CopyNumberVariant;
  partialGeneDeletion?: PartialGeneDeletion;
  partialGeneDuplication?: PartialGeneDuplication;
  geneFusion?: GeneFusion;
  wildtype?: Wildtype;
  tmb?: Tmb;
  msi?: Msi;
  hrd?: Hrd;
  gloh?: GenomicLoh;
  intragenicDeletion?: {};
  intragenicDuplication?: {};
  intragenicInversion?: {};
  fivePrimePartnerlessRearrangement?: {};
  threePrimePartnerlessRearrangement?: {};

  constructor(jsonData: any) {
    super(jsonData);
    if (jsonData.allelicVariant) {
      this.allelicVariant = new AllelicVariant(jsonData.allelicVariant);
    }
    if (jsonData.copyNumberVariant) {
      this.copyNumberVariant = new CopyNumberVariant(jsonData.copyNumberVariant);
    }
    if (jsonData.geneFusion) {
      this.geneFusion = new GeneFusion(jsonData.geneFusion);
    }
    if (jsonData.wildtype) {
      this.wildtype = new Wildtype(jsonData.wildtype);
    }
    if (jsonData.tmb) {
      this.tmb = new Tmb(jsonData.tmb);
    }
    if (jsonData.hrd) {
      this.hrd = new Tmb(jsonData.hrd);
    }
    if (jsonData.msi) {
      this.msi = new Msi(jsonData.msi);
    }
  }
}

export class AllelicVariant extends BaseEntity {
  chr: string;
  startPos: number;
  endPos: number;
  ref: string;
  alt: string;
  varType: string;
  hugoGene: string;
  hgvsPShort: string;
  hgvsP: string;
  hgvsC: string;
  hgvsDisplayName?: string;
  transcriptId: string;
}

export class CopyNumberVariant extends BaseEntity {
  gene: string;
  copyNumber: string;
  chr: string;
  startPos: number;
  endPos: number;
  consequence: string;
}

export class PartialGeneDeletion extends BaseEntity {
  gene: string;
  copyNumber: string;
  chr: string;
  startPos: number;
  endPos: number;
}

export class PartialGeneDuplication extends BaseEntity {
  gene: string;
  copyNumber: string;
  chr: string;
  startPos: number;
  endPos: number;
}

export class GeneFusion extends BaseEntity {
  fivePrimeGene: string;
  fivePrimeBreakpoint: number;
  fivePrimeChr: string;
  threePrimeGene: string;
  threePrimeBreakpoint: number;
  threePrimeChr: string;
}

export class Wildtype extends BaseEntity {
  genes: string[];
}

export class Tmb extends BaseEntity {
  level: string;
  value?: number;
}

export class Msi extends BaseEntity {
  level: string;
  value?: number;
}

export class Hrd extends BaseEntity {
  level: string;
  value?: number;
  qcStatus?: QC_STATUS;
}

export class GenomicLoh extends BaseEntity {
  level: string;
  value?: number;
}

export enum QC_STATUS {
  PASS = 'PASS',
  FAIL = 'FAIL'
}

export enum HRD_LEVEL {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  UNKNOWN_THRESHOLD = 'Unknown_Threshold',
  UNCATEGORIZED = 'Uncategorized'
}
