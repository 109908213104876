import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * Examples on how to use doughnut-percentages
 * <app-doughnut-percentages [percentages]="[0.9, 0.8, 0.7]"></app-doughnut-percentages>
 * <app-doughnut-percentages [percentages]="[0.9, 0.8]"></app-doughnut-percentages>
 * <app-doughnut-percentages [percentages]="[0.9]"></app-doughnut-percentages>
 */

@Component({
  selector: 'app-doughnut-percentages',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './doughnut-percentages.component.html',
  styleUrls: ['./doughnut-percentages.component.scss']
})
export class DoughnutPercentagesComponent implements OnInit {
  @Input() percentages: number[];
  @Input() size? = 48;

  r1; // radius
  r2;
  r3;
  c1; // circumference
  c2;
  c3;
  x; // cx
  y; // y

  ngOnInit(): void {
    this.x = this.size / 2;
    this.y = this.x;
    this.r1 = this.x * 0.9;
    this.r2 = this.x * 0.7;
    this.r3 = this.x * 0.5;
    this.c1 = this.getCircumference(this.r1);
    this.c2 = this.getCircumference(this.r2);
    this.c3 = this.getCircumference(this.r3);
  }

  getCircumference(r): number {
    return Math.PI * r * 2;
  }
}
