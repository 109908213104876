<mat-form-field oneFormField>
  <mat-label>{{placeholder}}</mat-label>
  <input matInput
         [formControl]="inputControl"
         (keypress)="numberInputValidation($event)"
         (blur)="blurEvent.emit(true)"  i18n-placeholder
         spellcheck="false" appNoAutoComplete>
  <mat-error *ngIf="errorCode$ | async as errorCode">
    <span *ngIf="errorCode === 'required'" i18n="Validation for required field|Message to be displayed as a validation@@required-field-validation">This field is required</span>
    <span *ngIf="errorCode === 'pattern' || errorCode === 'max'" i18n="Validation for input pattern|Message to be displayed as a validation@@field-pattern-validation">Invalid value</span>
  </mat-error>
</mat-form-field>
