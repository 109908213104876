import { CaseWarning } from './caseWarning';
import { AnnotatedVariant, GeneRearrangement } from './annotatedVariant';
import { CosmicAnnotation } from './cosmicAnnotation';
import { ClinvarAnnotation } from './clinvarAnnotation';
import { TcgaAnnotation } from './tcgaAnnotation';
import { PopFreqAnnotation } from './popFreqAnnotation';
import { Coordinate } from './coordinate';
import { ManualOverride } from './manualOverride';
import { CivicAnnotation } from './civicAnnotation';
import { MitelmanAnnotation } from './mitelmanAnnotation';
import { DetailedVariantAnalytics } from './analytics';
import { Mate } from './mate';

export interface CNVGenomicRange {
  gene: string;
  chr: string;
  startPos: number;
  endPos: number;
  consequence?: string;
}

export class DetailedAnnotatedVariant extends AnnotatedVariant {
  effects?: string[];
  variantGroups?: string[];
  cosmicAnnotation?: CosmicAnnotation;
  tcgaAnnotation?: TcgaAnnotation;
  civicAnnotation?: CivicAnnotation;
  mitelmanAnnotation?: MitelmanAnnotation;
  originalCoordinates: Coordinate[];
  mates: Mate[];
  manuallyEntered: boolean;
  manualOverrides: ManualOverride[];
  caseWarning: CaseWarning;
  analytics: DetailedVariantAnalytics;
  cnvGenomicRange?: CNVGenomicRange;
  genomicBreakpoints: Omit<GeneRearrangement, 'type'>;

  constructor(jsonData: any) {
    super(jsonData);
    //  Only one type of Cosmic annotation will exist at a time
    if (jsonData.cosmicAnnotation) {
      this.cosmicAnnotation = new CosmicAnnotation(jsonData.cosmicAnnotation);
    }
    if (jsonData.cosmicFusionAnnotation) {
      this.cosmicAnnotation = new CosmicAnnotation(jsonData.cosmicFusionAnnotation);
    }
    if (jsonData.cosmicCnvAnnotation) {
      this.cosmicAnnotation = new CosmicAnnotation(jsonData.cosmicCnvAnnotation);
    }
    if (jsonData.tcgaAnnotation) {
      this.tcgaAnnotation = new TcgaAnnotation(jsonData.tcgaAnnotation);
    }
    if (jsonData.populationFrequency) {
      this.populationFrequency = new PopFreqAnnotation(jsonData.populationFrequency);
    }
    if (jsonData.clinvarAnnotation) {
      this.clinvarAnnotation = new ClinvarAnnotation(jsonData.clinvarAnnotation);
    }
    if (jsonData.civicAnnotation) {
      this.civicAnnotation = new CivicAnnotation(jsonData.civicAnnotation);
    }
    if (jsonData.mitelmanAnnotation) {
      this.mitelmanAnnotation = new MitelmanAnnotation(jsonData.mitelmanAnnotation);
    }
    if (jsonData.originalCoordinates) {
      this.originalCoordinates = jsonData.originalCoordinates.map(
        (coordinate) => new Coordinate(coordinate)
      );
    }
    if (jsonData.geneFusionCall) {
      this.manuallyEntered = jsonData.geneFusionCall.manuallyEntered;
    }
    if (jsonData.geneFusionCall && jsonData.geneFusionCall.mates) {
      this.mates = jsonData.geneFusionCall.mates.map((mate) => new Mate(mate));
    }
    if (jsonData.manualOverrides) {
      this.manualOverrides = jsonData.manualOverrides.map(
        (manualOverride) => new ManualOverride(manualOverride)
      );
    }
  }
}
