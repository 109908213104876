<owc-modal-dialog disable-scroll-lock [(ngModel)]="visible">
  <div slot="header" class="dialog-title">
    <span i18n="Popup window title|Title for RUO modal popup@@disclaimer">Disclaimer</span>
    <img class="roche-logo" alt="Roche logo" src="assets/images/Roche_Logo.svg" />
  </div>

  <div class="content-texts">
    <p i18n="RUO modal content line 1@@ruo-modal-content-disclaimer-section">
      <app-name></app-name> (nMP) provided by Roche Sequencing Solutions, Inc. is intended for research-use-only (RUO) purposes.
      The information displayed by nMP is solely for RUO purposes, not to be used in any clinical diagnostic practice, and does not constitute a claim that nMP is anything other than a RUO tool.
    </p>

    <p i18n="RUO modal content line 2@@ruo-modal-content-ruo-purpose-section">
      <strong>RUO purposes:</strong> The software tool nMP is designed and intended for research purposes only.
      It is not intended for use in clinical diagnostic procedures or for providing medical advice or treatment recommendations.
      nMP should not be used as a substitute for professional medical judgment or as a basis for making medical decisions.
    </p>

    <p i18n="RUO modal content line 3@@ruo-modal-content-information-displayed-section">
      <strong>Information displayed:</strong> nMP provides information from  resources such as databases or literature, which are used for research and informational purposes.
      This information is provided to assist researchers in their investigations and should not be interpreted as diagnostic or clinical guidance.
    </p>

    <p i18n="RUO modal content line 4@@ruo-modal-content-user-responsibility-section">
      <strong>User responsibility:</strong> Users of nMP are solely responsible for the appropriate and lawful use of the tool.
      It is the user's responsibility to ensure that the data and results obtained from nMP are used in compliance with applicable laws, regulations, and ethical guidelines.
      The user should exercise caution and professional judgment when interpreting the information provided by nMP.</p>
  </div>

  <div slot="actions" class="actions">
    <owc-button variant="secondary" flat (click)="close()" i18n="RuoPopupComponent.Button|Text for close button@@close">Close</owc-button>
  </div>
</owc-modal-dialog>
