/* eslint-disable  @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';
import { startWith, map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { LastUpdated } from '../model/valueObjects/lastUpdatedEnum';
import { Localization } from 'app/model/valueObjects/localization';
import { VariantName } from 'app/model/entities/annotatedVariant';
import { delimiterCharMapper } from 'app/pipes/locale-number.pipe';
import { PipelineInputFileFormat } from '../model/entities/secondaryAnalysisFileFormat';

export const HEADER_ETAG = 'X-Ist-ETag';
export const HEADER_IF_NONE_MATCH = 'X-Ist-If-None-Match';
export const UI_NAV_BAR_HEIGHT = 118;
export const SECONDARY_APP_NAME = 'navify® Therapy Matcher';
export const APP_NAME = 'navify® Mutation Profiler';
export const PMID_BASE_URL = 'https://pubmed.ncbi.nlm.nih.gov/';

export interface Replacements {
  [key: string]: string;
}

@Injectable()
export class CommonService {
  static phiMaskString = '*****';

  constructor(
    private localization: Localization,
    private titleService: Title,
    private router: Router
  ) {}

  static getUserLocale() {
    const firstPreferredLanguage = navigator.languages
      ? navigator.languages[0]
      : navigator.language;
    if (firstPreferredLanguage) {
      return firstPreferredLanguage.split('-')[0] || 'en';
    }
    return 'en';
  }

  static reloadApp(path = '/') {
    window.location.replace(path);
  }

  static getSeparatorCharacter(value: string): string {
    return delimiterCharMapper[value] || delimiterCharMapper.period; // default to period if not found
  }

  static getScrollPositionY(): number {
    return window.pageYOffset;
  }

  static scrollToPositionY(offset: number) {
    // https://stackoverflow.com/a/4210821/359001
    document.body.scrollTop = offset;
    document.documentElement.scrollTop = offset;
  }

  // Use this approach rather than regex to avoid worrying about special characters - https://stackoverflow.com/a/1145525/359001
  // Can switch to String.prototype.replaceAll when it is supported - https://github.com/tc39/proposal-string-replaceall
  static stringReplaceAll(str: string, replacements: string[][]): string {
    replacements.forEach((replacement) => {
      str = str.split(replacement[0]).join(replacement[1]);
    });

    return str;
  }

  parseFloatLocale(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }

    // remove thousand separator, convert decimal to dot before parsing
    return parseFloat(
      CommonService.stringReplaceAll(value, [
        [this.thousandSepChar, ''],
        [this.decimalChar, '.']
      ])
    );
  }

  parseIntLocale(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }

    // remove thousand separator before parsing
    return parseInt(CommonService.stringReplaceAll(value, [[this.thousandSepChar, '']]), 10);
  }

  get decimalChar(): string {
    return CommonService.getSeparatorCharacter(this.localization.decimalPlace);
  }

  get thousandSepChar(): string {
    return CommonService.getSeparatorCharacter(this.localization.thousandSeparator);
  }

  get dynamicPatterns() {
    return CommonService.createDynamicPatterns(this.thousandSepChar, this.decimalChar);
  }

  static createDynamicPatterns(thousandSepChar: string, decimalChar: string) {
    return {
      // At most two decimals, allows integer, allows without starting 0 (example: .99 is valid):
      PERCENT_2: new RegExp(
        `^((0|[1-9])\\d?)?(\\${decimalChar}\\d{1,2})?$|^100(\\${decimalChar}0{1,2})?$`
      ),
      // Same as above ^, but with 4 decimal places:
      PERCENT_4: new RegExp(
        `^((0|[1-9])\\d?)?(\\${decimalChar}\\d{1,4})?$|^100(\\${decimalChar}0{1,4})?$`
      ),
      INTEGER_NON_NEGATIVE_LOCALE: new RegExp(
        `^\\d{1,3}(\\${thousandSepChar}\\d{3})*$|^(0|[1-9][0-9]*)$`
      ),
      // Adapted from: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch06s11.html
      decimalPlaces: (maxDecimalPlaces) =>
        new RegExp(
          `^(([0-9]{1,3})?(\\${thousandSepChar}?[0-9]{3})*(\\${decimalChar}[0-9]{1,${maxDecimalPlaces}})?)$`
        ),
      anyCharExcept: (char) => new RegExp(`^[^${char}]*$`)
    };
  }

  static get fileNamePatterns(): { [key in PipelineInputFileFormat]: RegExp } {
    return {
      [PipelineInputFileFormat.BAM]: /\.bam$/,
      [PipelineInputFileFormat.BED]: /\.bed(|\.gz)$/,
      [PipelineInputFileFormat.BEDPE]: /\.bedpe(|\.gz)$/,
      [PipelineInputFileFormat.CSV]: /\.csv(|\.gz)$/,
      [PipelineInputFileFormat.JSON]: /\.json$/,
      [PipelineInputFileFormat.TSV]: /\.tsv(|\.gz)$/,
      [PipelineInputFileFormat.VCF]: /\.vcf(|\.gz)$/
    };
  }

  static get patterns() {
    return {
      NO_SPACE: /^\S*$/,
      // 1-100 Percentage check
      PERCENTAGE: /^[1-9][0-9]?$|^100$/,
      // Zero and positive Integer
      INTEGER_NON_NEGATIVE: /^(0|[1-9][0-9]*)$/,
      INTEGER_NON_NEGATIVE_WITH_E: /^(\d+[eE]\d+|\d*)$/,
      // positive Integer
      INTEGER_POSITIVE: /^\d*[1-9]\d*$/,
      INTEGER_POSITIVE_WITH_E: /^\d*[1-9]([eE][0-9]{1})?\d*$/,
      IP_ADDRESS:
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      LOWERCASE_IDENTIFIER: /^[a-z0-9_]+$/,
      TRIMMED: /^\S$|^\S.*\S$/, // no leading/trailing space
      VCF_FORMAT: /^(?:INFO|FORMAT):[a-zA-Z0-9]+$/,
      UUID_FORMAT:
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i
    };
  }

  static get genderList() {
    return ['MALE', 'FEMALE', 'OTHER', 'UNKNOWN'];
  }

  static get fieldLengths() {
    return {
      shortField: 100,
      addressField: 50,
      longField: 255,
      idField: 50,
      explanationField: 1000,
      summaryField: 2000,
      briefSuggestField: 500,
      variantValueField: 50,
      charField200: 200,
      clinicalBriefField: 4000
    };
  }

  static clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  static checkAndMaskPHI(canViewPHI: boolean, value: string): string {
    return canViewPHI ? value : CommonService.phiMaskString;
  }

  static fieldRequiredHint(control: AbstractControl): boolean {
    return control.untouched && !control.value && control.value !== 0;
  }

  static fieldRequiredError(control: AbstractControl, controlName: string): boolean {
    return (
      control.get(controlName).touched &&
      control.get(controlName).errors &&
      control.get(controlName).errors.required
    );
  }

  // Enforce that a given option is selected from an autocomplete input
  // Should not be necessary when this is fixed: https://github.com/angular/material2/issues/3334
  static validateOption(control) {
    // Expect control.value to be an object. Invalid when it is a string
    return typeof control.value === 'string' ? { option: true } : null;
  }

  static validateNotPristine(fg: FormGroup) {
    return fg.pristine ? { pristine: true } : null;
  }

  static validateNotEmpty(fg: FormGroup) {
    const isEmpty = Object.keys(fg.controls).reduce((result, cName) => {
      return (
        result &&
        (fg.get(cName).value === null ||
          fg.get(cName).value === '' ||
          fg.get(cName).value === undefined)
      );
    }, true);
    return isEmpty ? { empty: true } : null;
  }

  static initAutoComplete(
    field: AbstractControl,
    optionsArray: any[],
    maxDisplayOptions: number = 500
  ): Observable<any[]> {
    optionsArray = optionsArray || [];
    return field.valueChanges.pipe(
      startWith(null),
      map((val: string) =>
        val
          ? CommonService.filterOptions(val, optionsArray).slice(0, maxDisplayOptions)
          : optionsArray.slice(0, maxDisplayOptions)
      )
    );
  }

  static filterOptions(val: string, optionsArray: any[], optionField = 'value'): string[] {
    return optionsArray.filter((option) => {
      if (typeof val !== 'string') return true;
      const value = val.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      return new RegExp(value, 'gi').test(option[optionField]);
    });
  }

  static copyToClipboard(text: string) {
    // https://techoverflow.net/2018/03/30/copying-strings-to-the-clipboard-using-pure-javascript/
    // Create new element
    const el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = text;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  }

  static calculateElapsedTime(lastUpdated: Date): LastUpdated {
    if (!lastUpdated) return LastUpdated.unknown;

    const minutesElapsed = DateTime.now().diff(DateTime.fromJSDate(lastUpdated)).as('minutes');

    if (minutesElapsed <= LastUpdated.one_minute_ago) {
      // If equal or less than a minute
      return LastUpdated.up_to_date;
    } else if (
      minutesElapsed > LastUpdated.one_minute_ago &&
      minutesElapsed <= LastUpdated.five_minutes_ago
    ) {
      // If greater than a minute but <= 5 min.
      return LastUpdated.five_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.five_minutes_ago &&
      minutesElapsed <= LastUpdated.ten_minutes_ago
    ) {
      // If greater than 5 min. but <= 10 min.
      return LastUpdated.ten_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.ten_minutes_ago &&
      minutesElapsed <= LastUpdated.fifteen_minutes_ago
    ) {
      // If greater than 10 min. but <= 15 min.
      return LastUpdated.fifteen_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.fifteen_minutes_ago &&
      minutesElapsed <= LastUpdated.twenty_minutes_ago
    ) {
      // If greater than 15 min. but <= 20 min.
      return LastUpdated.twenty_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.twenty_minutes_ago &&
      minutesElapsed <= LastUpdated.thirty_minutes_ago
    ) {
      // If greater than 20 min. but <= 30 min.
      return LastUpdated.thirty_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.thirty_minutes_ago &&
      minutesElapsed <= LastUpdated.forty_five_minutes_ago
    ) {
      // If greater than 30 min. but <= 45 min.
      return LastUpdated.forty_five_minutes_ago;
    } else if (
      minutesElapsed > LastUpdated.forty_five_minutes_ago &&
      minutesElapsed <= LastUpdated.an_hour_ago
    ) {
      // If greater than 45 min. but <= 1 hour.
      return LastUpdated.an_hour_ago;
    } else {
      // Any thing greater than an hour.
      return LastUpdated.more_than_an_hour_ago;
    }
  }

  // Returns corresponding index from input array
  static setHighlightedHeader(sectionIds: string[], defaultSection: number): number {
    const scrollTop =
      (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop) +
      UI_NAV_BAR_HEIGHT;
    let highlightedSection = defaultSection;
    sectionIds.forEach((id, idx) => {
      const section = document.getElementById(id);
      if (section && scrollTop >= section.offsetTop - 10) {
        highlightedSection = idx;
      }
    });
    return highlightedSection;
  }

  static combinedVariantNames(variants: Array<VariantName[]>): string {
    return variants.map((v) => CommonService.stringVariantName(v)).join(', ');
  }

  static stringVariantName(variants: VariantName[]): string {
    return variants
      .map((variant) => {
        const name = `${variant.first || ''} ${variant.last || ''}`.trim();
        return name + (variant.alias ? ` (${variant.alias})` : '');
      })
      .join(', ');
  }

  static replaceInURL(url: string, replacements: Replacements): string {
    return Object.keys(replacements).reduce((acc, key) => {
      return acc.replace(new RegExp(`\\(${key}\\)`, 'g'), replacements[key]);
    }, url);
  }

  // Truncate to specified number of decimals
  // Adapted from (https://stackoverflow.com/a/12810744)
  static truncateDecimal(num: number, decimals = 2) {
    // Catch all scientific notation value like 0.888e-8
    let numStr = this.convertExponentialToDecimal(num).toString();
    if (numStr.includes('.')) {
      numStr = numStr.slice(0, numStr.indexOf('.') + decimals + 1);
    }
    return parseFloat(numStr);
  }

  static convertExponentialToDecimal(exponentialNumber: number) {
    // sanity check - is it exponential number
    const str = exponentialNumber.toString();
    if (str.indexOf('e') !== -1) {
      const exponent = parseInt(str.split('-')[1], 10);
      // use .toFixed() to convert
      return exponentialNumber.toFixed(exponent);
    } else {
      return exponentialNumber;
    }
  }

  static convertBytesToGb(bytes: number = 0): number {
    return bytes / 1e9;
  }

  /**
   *
   * @param versionWithBuildNo Matches the string of format 2.3.2.xxxxxxx and truncate `.xxxxxxx`. If not matched, returns the original string.
   * @returns Trimmed version number. For ex: 2.3.2.xxxxxxx -> 2.3.2
   */
  static truncateBuildNumberFromVersion(versionWithBuildNo: string): string {
    const versionRegEx = /^(.+)(\..{7})$/g;
    const matchGroups = versionRegEx.exec(versionWithBuildNo);
    return matchGroups?.length > 1 ? matchGroups[1] : versionWithBuildNo;
  }

  static getFirstError(field: string, form: FormGroup): string | null {
    const errors = form.get(field).errors;
    return errors ? Object.keys(errors)[0] : null;
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle ? `${newTitle} - ${APP_NAME}` : APP_NAME);
  }

  displayOption(option) {
    return option ? option.value : null;
  }

  displayForbiddenPage() {
    this.router.navigateByUrl('/forbidden', { skipLocationChange: true });
  }

  formatDateISO(inputDate: string): string {
    const outputDate = DateTime.fromFormat(inputDate, this.localization.dateFormat);
    return outputDate.toFormat('yyyy-LL-dd');
  }

  uuidValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const uuidRegex = CommonService.patterns.UUID_FORMAT;
      const valid = uuidRegex.test(control.value);
      return valid ? null : { invalidUuid: { value: control.value } };
    };
  }
}
