import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CaseNavigationService {
  private _lastVisitedId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  get lastVisitedId() {
    return this._lastVisitedId.asObservable();
  }

  setLastVisitedId(id) {
    this._lastVisitedId.next(id);
  }

  clearLastVisitedId() {
    this._lastVisitedId.next(null);
  }
}
