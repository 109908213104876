<ng-container [ngSwitch]="last.toLowerCase()">
  <span *ngSwitchCase="'amplification'" i18n="VariantLastName.Notes@@amplification-a574c3">amplification</span>
  <span *ngSwitchCase="'deletion'" i18n="VariantLastName.Notes@@deletion-2bc054">deletion</span>
  <span *ngSwitchCase="'fusion'" i18n="VariantLastName.Notes@@fusion-4dd069">fusion</span>
  <span *ngSwitchCase="'high'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-high">High</span>
  <span *ngSwitchCase="'low'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-low">Low</span>
  <span *ngSwitchCase="'intermediate'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-intermediate">Intermediate</span>
  <span *ngSwitchCase="'undetermined'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-undetermined">Undetermined</span>
  <span *ngSwitchCase="'unknown'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-unknown">Unknown</span>
  <span *ngSwitchCase="'equivocal'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-equivocal">Equivocal</span>
  <span *ngSwitchCase="'stable'"
    i18n="VariantLastName.Notes|Single word for status@@single-word-stable">Stable</span>
  <span *ngSwitchDefault [attr.title]="last">{{ last | truncateText: truncateLength }}</span>
</ng-container>
