export enum CaseLinkKeys {
  APPROVE_CASE = 'approveCase',
  CANCEL_CASE = 'cancelCase',
  CREATE_REVISION = 'createRevision',
  CHECK_START_ANALYSIS = 'checkStartAnalysis',
  CHECK_STATUS = 'checkStatus',
  CREATE_LAB_NOTE = 'createLabNote',
  DELETE_CASE = 'deleteCase',
  EDIT_CASE = 'editCase',
  EDIT_CASE_CLINICAL = 'editCaseClinical',
  EDIT_CASE_DISPLAY_SETTINGS = 'editCaseDisplaySettings',
  EDIT_ORDERER = 'editOrderer',
  EDIT_SUBSCRIBERS_CASE = 'editSubscribersCase',
  PREVIEW_REPORT = 'previewReport',
  REJECT_CASE = 'rejectCase',
  SUBMIT_CASE_FOR_APPROVAL = 'submitCaseForApproval',
  UPDATE_VIRTUAL_GENE_PANEL = 'updateVirtualGenePanel',
  UPLOAD = 'upload',
  VIEW_ASSAY = 'viewAssay',
  VIEW_CASE = 'viewCase',
  VIEW_CASE_RESULTS = 'viewCaseResults',
  VIEW_CASE_DISPLAY_SETTINGS = 'viewCaseDisplaySettings',
  VIEW_LAB_NOTES = 'viewLabNotes',
  VIEW_PATIENT = 'viewPatient',
  VIEW_PATIENT_REVISION = 'viewPatientRevision',
  VIEW_SUBSCRIBERS_CASE = 'viewSubscribersCase'
}
