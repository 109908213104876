import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { get as _get } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs/operators';
import { TmbMsiThresholdVersion } from 'app/model/valueObjects/tmbMsiThreshold';

@Injectable({
  providedIn: 'root'
})
export class TmbMsiThresholdVersionService {
  private _tmbMsiThresholdVersionByVersionMap = {};
  private _tmbMsiThresholdVersion = new Subject<TmbMsiThresholdVersion>();
  private _tmbMsiThresholdVersionLoading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get tmbMsiThresholdVersion(): Observable<TmbMsiThresholdVersion> {
    return this._tmbMsiThresholdVersion.asObservable();
  }

  get tmbMsiThresholdVersionLoading() {
    return this._tmbMsiThresholdVersionLoading.asObservable();
  }

  loadTmbMsiThresholdVersion(version: string) {
    if (!version) {
      this._tmbMsiThresholdVersion.next(TmbMsiThresholdVersion.V1);
    } else if (this._tmbMsiThresholdVersionByVersionMap[version]) {
      this._tmbMsiThresholdVersion.next(this._tmbMsiThresholdVersionByVersionMap[version]);
    } else {
      this._tmbMsiThresholdVersionLoading.next(true);
      return this.http
        .get<any>(this.authService.getURL('rocheContent', { version }))
        .pipe(finalize(() => this._tmbMsiThresholdVersionLoading.next(false)))
        .subscribe((json) => {
          this._tmbMsiThresholdVersionByVersionMap[version] = _get(
            json,
            'tmbMsiThresholdVersion',
            _get(json, 'tmbMsiHrdThresholdVersion', TmbMsiThresholdVersion.V1)
          );
          this._tmbMsiThresholdVersion.next(this._tmbMsiThresholdVersionByVersionMap[version]);
        });
    }
  }
}
