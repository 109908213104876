<ng-container [ngSwitch]="timeZone">
  <ng-container *ngSwitchCase="'Pacific/Niue'">(GMT-11:00) Niue</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Pago_Pago'">(GMT-11:00) Pago Pago</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Honolulu'">(GMT-10:00) Hawaii Time</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Rarotonga'">(GMT-10:00) Rarotonga</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Tahiti'">(GMT-10:00) Tahiti</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Marquesas'">(GMT-09:30) Marquesas</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Gambier'">(GMT-09:00) Gambier</ng-container>
  <ng-container *ngSwitchCase="'America/Anchorage'">(GMT-08:00) Alaska Time</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Pitcairn'">(GMT-08:00) Pitcairn</ng-container>
  <ng-container *ngSwitchCase="'America/Dawson'">(GMT-07:00) Dawson</ng-container>
  <ng-container *ngSwitchCase="'America/Phoenix'"
    >(GMT-07:00) Mountain Time - Arizona</ng-container
  >
  <ng-container *ngSwitchCase="'America/Dawson_Creek'"
    >(GMT-07:00) Mountain Time - Dawson Creek</ng-container
  >
  <ng-container *ngSwitchCase="'America/Hermosillo'"
    >(GMT-07:00) Mountain Time - Hermosillo</ng-container
  >
  <ng-container *ngSwitchCase="'America/Los_Angeles'">(GMT-07:00) Pacific Time</ng-container>
  <ng-container *ngSwitchCase="'America/Tijuana'"
    >(GMT-07:00) Pacific Time - Tijuana</ng-container
  >
  <ng-container *ngSwitchCase="'America/Vancouver'"
    >(GMT-07:00) Pacific Time - Vancouver</ng-container
  >
  <ng-container *ngSwitchCase="'America/Whitehorse'"
    >(GMT-07:00) Pacific Time - Whitehorse</ng-container
  >
  <ng-container *ngSwitchCase="'America/Belize'">(GMT-06:00) Belize</ng-container>
  <ng-container *ngSwitchCase="'America/Boise'">(GMT-06:00) Boise</ng-container>
  <ng-container *ngSwitchCase="'America/Regina'"
    >(GMT-06:00) Central Time - Regina</ng-container
  >
  <ng-container *ngSwitchCase="'America/Tegucigalpa'"
    >(GMT-06:00) Central Time - Tegucigalpa</ng-container
  >
  <ng-container *ngSwitchCase="'America/Costa_Rica'">(GMT-06:00) Costa Rica</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Easter'">(GMT-06:00) Easter Island</ng-container>
  <ng-container *ngSwitchCase="'America/El_Salvador'">(GMT-06:00) El Salvador</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Galapagos'">(GMT-06:00) Galapagos</ng-container>
  <ng-container *ngSwitchCase="'America/Guatemala'">(GMT-06:00) Guatemala</ng-container>
  <ng-container *ngSwitchCase="'America/Managua'">(GMT-06:00) Managua</ng-container>
  <ng-container *ngSwitchCase="'America/Denver'">(GMT-06:00) Mountain Time</ng-container>
  <ng-container *ngSwitchCase="'America/Mazatlan'"
    >(GMT-06:00) Mountain Time - Chihuahua, Mazatlan</ng-container
  >
  <ng-container *ngSwitchCase="'America/Edmonton'"
    >(GMT-06:00) Mountain Time - Edmonton</ng-container
  >
  <ng-container *ngSwitchCase="'America/Yellowknife'"
    >(GMT-06:00) Mountain Time - Yellowknife</ng-container
  >
  <ng-container *ngSwitchCase="'America/Cancun'">(GMT-05:00) America Cancun</ng-container>
  <ng-container *ngSwitchCase="'America/Bogota'">(GMT-05:00) Bogota</ng-container>
  <ng-container *ngSwitchCase="'America/Chicago'">(GMT-05:00) Central Time</ng-container>
  <ng-container *ngSwitchCase="'America/Mexico_City'"
    >(GMT-05:00) Central Time - Mexico City</ng-container
  >
  <ng-container *ngSwitchCase="'America/Winnipeg'"
    >(GMT-05:00) Central Time - Winnipeg</ng-container
  >
  <ng-container *ngSwitchCase="'America/Guayaquil'">(GMT-05:00) Guayaquil</ng-container>
  <ng-container *ngSwitchCase="'America/Jamaica'">(GMT-05:00) Jamaica</ng-container>
  <ng-container *ngSwitchCase="'America/Lima'">(GMT-05:00) Lima</ng-container>
  <ng-container *ngSwitchCase="'America/Panama'">(GMT-05:00) Panama</ng-container>
  <ng-container *ngSwitchCase="'America/Rio_Branco'">(GMT-05:00) Rio Branco</ng-container>
  <ng-container *ngSwitchCase="'America/Asuncion'">(GMT-04:00) Asuncion</ng-container>
  <ng-container *ngSwitchCase="'America/Barbados'">(GMT-04:00) Barbados</ng-container>
  <ng-container *ngSwitchCase="'America/Boa_Vista'">(GMT-04:00) Boa Vista</ng-container>
  <ng-container *ngSwitchCase="'America/Campo_Grande'">(GMT-04:00) Campo Grande</ng-container>
  <ng-container *ngSwitchCase="'America/Caracas'">(GMT-04:00) Caracas</ng-container>
  <ng-container *ngSwitchCase="'America/Cuiaba'">(GMT-04:00) Cuiaba</ng-container>
  <ng-container *ngSwitchCase="'America/Curacao'">(GMT-04:00) Curacao</ng-container>
  <ng-container *ngSwitchCase="'America/Detroit'">(GMT-04:00) Detroit</ng-container>
  <ng-container *ngSwitchCase="'America/New_York'">(GMT-04:00) Eastern Time</ng-container>
  <ng-container *ngSwitchCase="'America/Iqaluit'"
    >(GMT-04:00) Eastern Time - Iqaluit</ng-container
  >
  <ng-container *ngSwitchCase="'America/Toronto'"
    >(GMT-04:00) Eastern Time - Toronto</ng-container
  >
  <ng-container *ngSwitchCase="'America/Grand_Turk'">(GMT-04:00) Grand Turk</ng-container>
  <ng-container *ngSwitchCase="'America/Guyana'">(GMT-04:00) Guyana</ng-container>
  <ng-container *ngSwitchCase="'America/Havana'">(GMT-04:00) Havana</ng-container>
  <ng-container *ngSwitchCase="'America/La_Paz'">(GMT-04:00) La Paz</ng-container>
  <ng-container *ngSwitchCase="'America/Manaus'">(GMT-04:00) Manaus</ng-container>
  <ng-container *ngSwitchCase="'America/Martinique'">(GMT-04:00) Martinique</ng-container>
  <ng-container *ngSwitchCase="'America/Nassau'">(GMT-04:00) Nassau</ng-container>
  <ng-container *ngSwitchCase="'America/Port_of_Spain'">(GMT-04:00) Port of Spain</ng-container>
  <ng-container *ngSwitchCase="'America/Port-au-Prince'"
    >(GMT-04:00) Port-au-Prince</ng-container
  >
  <ng-container *ngSwitchCase="'America/Porto_Velho'">(GMT-04:00) Porto Velho</ng-container>
  <ng-container *ngSwitchCase="'America/Puerto_Rico'">(GMT-04:00) Puerto Rico</ng-container>
  <ng-container *ngSwitchCase="'America/Santiago'">(GMT-04:00) Santiago</ng-container>
  <ng-container *ngSwitchCase="'America/Santo_Domingo'">(GMT-04:00) Santo Domingo</ng-container>
  <ng-container *ngSwitchCase="'America/Araguaina'">(GMT-03:00) Araguaina</ng-container>
  <ng-container *ngSwitchCase="'America/Halifax'"
    >(GMT-03:00) Atlantic Time - Halifax</ng-container
  >
  <ng-container *ngSwitchCase="'America/Belem'">(GMT-03:00) Belem</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Bermuda'">(GMT-03:00) Bermuda</ng-container>
  <ng-container *ngSwitchCase="'America/Argentina/Buenos_Aires'"
    >(GMT-03:00) Buenos Aires</ng-container
  >
  <ng-container *ngSwitchCase="'America/Cayenne'">(GMT-03:00) Cayenne</ng-container>
  <ng-container *ngSwitchCase="'America/Argentina/Cordoba'">(GMT-03:00) Cordoba</ng-container>
  <ng-container *ngSwitchCase="'America/Fortaleza'">(GMT-03:00) Fortaleza</ng-container>
  <ng-container *ngSwitchCase="'America/Maceio'">(GMT-03:00) Maceio</ng-container>
  <ng-container *ngSwitchCase="'America/Montevideo'">(GMT-03:00) Montevideo</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Palmer'">(GMT-03:00) Palmer</ng-container>
  <ng-container *ngSwitchCase="'America/Paramaribo'">(GMT-03:00) Paramaribo</ng-container>
  <ng-container *ngSwitchCase="'America/Punta_Arenas'">(GMT-03:00) Punta Arenas</ng-container>
  <ng-container *ngSwitchCase="'America/Recife'">(GMT-03:00) Recife</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Rothera'">(GMT-03:00) Rothera</ng-container>
  <ng-container *ngSwitchCase="'America/Bahia'">(GMT-03:00) Salvador</ng-container>
  <ng-container *ngSwitchCase="'America/Sao_Paulo'">(GMT-03:00) Sao Paulo</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Stanley'">(GMT-03:00) Stanley</ng-container>
  <ng-container *ngSwitchCase="'America/Thule'">(GMT-03:00) Thule</ng-container>
  <ng-container *ngSwitchCase="'America/St_Johns'"
    >(GMT-02:30) Newfoundland Time - St. Johns</ng-container
  >
  <ng-container *ngSwitchCase="'America/Godthab'">(GMT-02:00) Godthab</ng-container>
  <ng-container *ngSwitchCase="'America/Miquelon'">(GMT-02:00) Miquelon</ng-container>
  <ng-container *ngSwitchCase="'America/Noronha'">(GMT-02:00) Noronha</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/South_Georgia'"
    >(GMT-02:00) South Georgia</ng-container
  >
  <ng-container *ngSwitchCase="'Atlantic/Cape_Verde'">(GMT-01:00) Cape Verde</ng-container>
  <ng-container *ngSwitchCase="'Africa/Abidjan'">(GMT+00:00) Abidjan</ng-container>
  <ng-container *ngSwitchCase="'Africa/Accra'">(GMT+00:00) Accra</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Azores'">(GMT+00:00) Azores</ng-container>
  <ng-container *ngSwitchCase="'Africa/Bissau'">(GMT+00:00) Bissau</ng-container>
  <ng-container *ngSwitchCase="'Africa/Casablanca'">(GMT+00:00) Casablanca</ng-container>
  <ng-container *ngSwitchCase="'America/Danmarkshavn'">(GMT+00:00) Danmarkshavn</ng-container>
  <ng-container *ngSwitchCase="'Africa/El_Aaiun'">(GMT+00:00) El Aaiun</ng-container>
  <ng-container *ngSwitchCase="'Etc/GMT'">(GMT+00:00) GMT (no daylight saving)</ng-container>
  <ng-container *ngSwitchCase="'Africa/Monrovia'">(GMT+00:00) Monrovia</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Reykjavik'">(GMT+00:00) Reykjavik</ng-container>
  <ng-container *ngSwitchCase="'America/Scoresbysund'">(GMT+00:00) Scoresbysund</ng-container>
  <ng-container *ngSwitchCase="'UTC'">UTC</ng-container>
  <ng-container *ngSwitchCase="'Africa/Algiers'">(GMT+01:00) Algiers</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Canary'">(GMT+01:00) Canary Islands</ng-container>
  <ng-container *ngSwitchCase="'Europe/Dublin'">(GMT+01:00) Dublin</ng-container>
  <ng-container *ngSwitchCase="'Atlantic/Faroe'">(GMT+01:00) Faeroe</ng-container>
  <ng-container *ngSwitchCase="'Africa/Lagos'">(GMT+01:00) Lagos</ng-container>
  <ng-container *ngSwitchCase="'Europe/Lisbon'">(GMT+01:00) Lisbon</ng-container>
  <ng-container *ngSwitchCase="'Europe/London'">(GMT+01:00) London</ng-container>
  <ng-container *ngSwitchCase="'Africa/Ndjamena'">(GMT+01:00) Ndjamena</ng-container>
  <ng-container *ngSwitchCase="'Africa/Sao_Tome'">(GMT+01:00) Sao Tome</ng-container>
  <ng-container *ngSwitchCase="'Africa/Tunis'">(GMT+01:00) Tunis</ng-container>
  <ng-container *ngSwitchCase="'Europe/Amsterdam'">(GMT+02:00) Amsterdam</ng-container>
  <ng-container *ngSwitchCase="'Europe/Andorra'">(GMT+02:00) Andorra</ng-container>
  <ng-container *ngSwitchCase="'Europe/Berlin'">(GMT+02:00) Berlin</ng-container>
  <ng-container *ngSwitchCase="'Europe/Brussels'">(GMT+02:00) Brussels</ng-container>
  <ng-container *ngSwitchCase="'Europe/Budapest'">(GMT+02:00) Budapest</ng-container>
  <ng-container *ngSwitchCase="'Africa/Cairo'">(GMT+02:00) Cairo</ng-container>
  <ng-container *ngSwitchCase="'Europe/Belgrade'"
    >(GMT+02:00) Central European Time - Belgrade</ng-container
  >
  <ng-container *ngSwitchCase="'Europe/Prague'"
    >(GMT+02:00) Central European Time - Prague</ng-container
  >
  <ng-container *ngSwitchCase="'Africa/Ceuta'">(GMT+02:00) Ceuta</ng-container>
  <ng-container *ngSwitchCase="'Europe/Copenhagen'">(GMT+02:00) Copenhagen</ng-container>
  <ng-container *ngSwitchCase="'Europe/Gibraltar'">(GMT+02:00) Gibraltar</ng-container>
  <ng-container *ngSwitchCase="'Africa/Johannesburg'">(GMT+02:00) Johannesburg</ng-container>
  <ng-container *ngSwitchCase="'Africa/Khartoum'">(GMT+02:00) Khartoum</ng-container>
  <ng-container *ngSwitchCase="'Europe/Luxembourg'">(GMT+02:00) Luxembourg</ng-container>
  <ng-container *ngSwitchCase="'Europe/Madrid'">(GMT+02:00) Madrid</ng-container>
  <ng-container *ngSwitchCase="'Europe/Malta'">(GMT+02:00) Malta</ng-container>
  <ng-container *ngSwitchCase="'Africa/Maputo'">(GMT+02:00) Maputo</ng-container>
  <ng-container *ngSwitchCase="'Europe/Monaco'">(GMT+02:00) Monaco</ng-container>
  <ng-container *ngSwitchCase="'Europe/Kaliningrad'"
    >(GMT+02:00) Moscow-01 - Kaliningrad</ng-container
  >
  <ng-container *ngSwitchCase="'Europe/Oslo'">(GMT+02:00) Oslo</ng-container>
  <ng-container *ngSwitchCase="'Europe/Paris'">(GMT+02:00) Paris</ng-container>
  <ng-container *ngSwitchCase="'Europe/Rome'">(GMT+02:00) Rome</ng-container>
  <ng-container *ngSwitchCase="'Europe/Stockholm'">(GMT+02:00) Stockholm</ng-container>
  <ng-container *ngSwitchCase="'Europe/Tirane'">(GMT+02:00) Tirane</ng-container>
  <ng-container *ngSwitchCase="'Africa/Tripoli'">(GMT+02:00) Tripoli</ng-container>
  <ng-container *ngSwitchCase="'Europe/Vienna'">(GMT+02:00) Vienna</ng-container>
  <ng-container *ngSwitchCase="'Europe/Warsaw'">(GMT+02:00) Warsaw</ng-container>
  <ng-container *ngSwitchCase="'Africa/Windhoek'">(GMT+02:00) Windhoek</ng-container>
  <ng-container *ngSwitchCase="'Europe/Zurich'">(GMT+02:00) Zurich</ng-container>
  <ng-container *ngSwitchCase="'Asia/Amman'">(GMT+03:00) Amman</ng-container>
  <ng-container *ngSwitchCase="'Europe/Athens'">(GMT+03:00) Athens</ng-container>
  <ng-container *ngSwitchCase="'Asia/Baghdad'">(GMT+03:00) Baghdad</ng-container>
  <ng-container *ngSwitchCase="'Asia/Beirut'">(GMT+03:00) Beirut</ng-container>
  <ng-container *ngSwitchCase="'Europe/Bucharest'">(GMT+03:00) Bucharest</ng-container>
  <ng-container *ngSwitchCase="'Europe/Chisinau'">(GMT+03:00) Chisinau</ng-container>
  <ng-container *ngSwitchCase="'Asia/Damascus'">(GMT+03:00) Damascus</ng-container>
  <ng-container *ngSwitchCase="'Asia/Gaza'">(GMT+03:00) Gaza</ng-container>
  <ng-container *ngSwitchCase="'Europe/Helsinki'">(GMT+03:00) Helsinki</ng-container>
  <ng-container *ngSwitchCase="'Europe/Istanbul'">(GMT+03:00) Istanbul</ng-container>
  <ng-container *ngSwitchCase="'Asia/Jerusalem'">(GMT+03:00) Jerusalem</ng-container>
  <ng-container *ngSwitchCase="'Europe/Kiev'">(GMT+03:00) Kiev</ng-container>
  <ng-container *ngSwitchCase="'Europe/Minsk'">(GMT+03:00) Minsk</ng-container>
  <ng-container *ngSwitchCase="'Europe/Moscow'">(GMT+03:00) Moscow+00 - Moscow</ng-container>
  <ng-container *ngSwitchCase="'Africa/Nairobi'">(GMT+03:00) Nairobi</ng-container>
  <ng-container *ngSwitchCase="'Asia/Nicosia'">(GMT+03:00) Nicosia</ng-container>
  <ng-container *ngSwitchCase="'Asia/Qatar'">(GMT+03:00) Qatar</ng-container>
  <ng-container *ngSwitchCase="'Europe/Riga'">(GMT+03:00) Riga</ng-container>
  <ng-container *ngSwitchCase="'Asia/Riyadh'">(GMT+03:00) Riyadh</ng-container>
  <ng-container *ngSwitchCase="'Europe/Sofia'">(GMT+03:00) Sofia</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Syowa'">(GMT+03:00) Syowa</ng-container>
  <ng-container *ngSwitchCase="'Europe/Tallinn'">(GMT+03:00) Tallinn</ng-container>
  <ng-container *ngSwitchCase="'Europe/Vilnius'">(GMT+03:00) Vilnius</ng-container>
  <ng-container *ngSwitchCase="'Asia/Baku'">(GMT+04:00) Baku</ng-container>
  <ng-container *ngSwitchCase="'Asia/Dubai'">(GMT+04:00) Dubai</ng-container>
  <ng-container *ngSwitchCase="'Indian/Mahe'">(GMT+04:00) Mahe</ng-container>
  <ng-container *ngSwitchCase="'Indian/Mauritius'">(GMT+04:00) Mauritius</ng-container>
  <ng-container *ngSwitchCase="'Europe/Samara'">(GMT+04:00) Moscow+01 - Samara</ng-container>
  <ng-container *ngSwitchCase="'Indian/Reunion'">(GMT+04:00) Reunion</ng-container>
  <ng-container *ngSwitchCase="'Asia/Tbilisi'">(GMT+04:00) Tbilisi</ng-container>
  <ng-container *ngSwitchCase="'Asia/Yerevan'">(GMT+04:00) Yerevan</ng-container>
  <ng-container *ngSwitchCase="'Asia/Kabul'">(GMT+04:30) Kabul</ng-container>
  <ng-container *ngSwitchCase="'Asia/Tehran'">(GMT+04:30) Tehran</ng-container>
  <ng-container *ngSwitchCase="'Asia/Aqtau'">(GMT+05:00) Aqtau</ng-container>
  <ng-container *ngSwitchCase="'Asia/Aqtobe'">(GMT+05:00) Aqtobe</ng-container>
  <ng-container *ngSwitchCase="'Asia/Ashgabat'">(GMT+05:00) Ashgabat</ng-container>
  <ng-container *ngSwitchCase="'Asia/Dushanbe'">(GMT+05:00) Dushanbe</ng-container>
  <ng-container *ngSwitchCase="'Asia/Karachi'">(GMT+05:00) Karachi</ng-container>
  <ng-container *ngSwitchCase="'Indian/Kerguelen'">(GMT+05:00) Kerguelen</ng-container>
  <ng-container *ngSwitchCase="'Indian/Maldives'">(GMT+05:00) Maldives</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Mawson'">(GMT+05:00) Mawson</ng-container>
  <ng-container *ngSwitchCase="'Asia/Yekaterinburg'"
    >(GMT+05:00) Moscow+02 - Yekaterinburg</ng-container
  >
  <ng-container *ngSwitchCase="'Asia/Tashkent'">(GMT+05:00) Tashkent</ng-container>
  <ng-container *ngSwitchCase="'Asia/Colombo'">(GMT+05:30) Colombo</ng-container>
  <ng-container *ngSwitchCase="'Asia/Calcutta'">(GMT+05:30) India Standard Time</ng-container>
  <ng-container *ngSwitchCase="'Asia/Katmandu'">(GMT+05:45) Katmandu</ng-container>
  <ng-container *ngSwitchCase="'Asia/Almaty'">(GMT+06:00) Almaty</ng-container>
  <ng-container *ngSwitchCase="'Asia/Bishkek'">(GMT+06:00) Bishkek</ng-container>
  <ng-container *ngSwitchCase="'Indian/Chagos'">(GMT+06:00) Chagos</ng-container>
  <ng-container *ngSwitchCase="'Asia/Dhaka'">(GMT+06:00) Dhaka</ng-container>
  <ng-container *ngSwitchCase="'Asia/Omsk'">(GMT+06:00) Moscow+03 - Omsk</ng-container>
  <ng-container *ngSwitchCase="'Asia/Thimphu'">(GMT+06:00) Thimphu</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Vostok'">(GMT+06:00) Vostok</ng-container>
  <ng-container *ngSwitchCase="'Indian/Cocos'">(GMT+06:30) Cocos</ng-container>
  <ng-container *ngSwitchCase="'Asia/Yangon'">(GMT+06:30) Rangoon</ng-container>
  <ng-container *ngSwitchCase="'Asia/Bangkok'">(GMT+07:00) Bangkok</ng-container>
  <ng-container *ngSwitchCase="'Indian/Christmas'">(GMT+07:00) Christmas</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Davis'">(GMT+07:00) Davis</ng-container>
  <ng-container *ngSwitchCase="'Asia/Saigon'">(GMT+07:00) Hanoi</ng-container>
  <ng-container *ngSwitchCase="'Asia/Hovd'">(GMT+07:00) Hovd</ng-container>
  <ng-container *ngSwitchCase="'Asia/Jakarta'">(GMT+07:00) Jakarta</ng-container>
  <ng-container *ngSwitchCase="'Asia/Krasnoyarsk'"
    >(GMT+07:00) Moscow+04 - Krasnoyarsk</ng-container
  >
  <ng-container *ngSwitchCase="'Asia/Brunei'">(GMT+08:00) Brunei</ng-container>
  <ng-container *ngSwitchCase="'Antarctica/Casey'">(GMT+08:00) Casey</ng-container>
  <ng-container *ngSwitchCase="'Asia/Shanghai'">(GMT+08:00) China Time - Beijing</ng-container>
  <ng-container *ngSwitchCase="'Asia/Choibalsan'">(GMT+08:00) Choibalsan</ng-container>
  <ng-container *ngSwitchCase="'Asia/Hong_Kong'">(GMT+08:00) Hong Kong</ng-container>
  <ng-container *ngSwitchCase="'Asia/Kuala_Lumpur'">(GMT+08:00) Kuala Lumpur</ng-container>
  <ng-container *ngSwitchCase="'Asia/Macau'">(GMT+08:00) Macau</ng-container>
  <ng-container *ngSwitchCase="'Asia/Makassar'">(GMT+08:00) Makassar</ng-container>
  <ng-container *ngSwitchCase="'Asia/Manila'">(GMT+08:00) Manila</ng-container>
  <ng-container *ngSwitchCase="'Asia/Irkutsk'">(GMT+08:00) Moscow+05 - Irkutsk</ng-container>
  <ng-container *ngSwitchCase="'Asia/Singapore'">(GMT+08:00) Singapore</ng-container>
  <ng-container *ngSwitchCase="'Asia/Taipei'">(GMT+08:00) Taipei</ng-container>
  <ng-container *ngSwitchCase="'Asia/Ulaanbaatar'">(GMT+08:00) Ulaanbaatar</ng-container>
  <ng-container *ngSwitchCase="'Australia/Perth'"
    >(GMT+08:00) Western Time - Perth</ng-container
  >
  <ng-container *ngSwitchCase="'Asia/Dili'">(GMT+09:00) Dili</ng-container>
  <ng-container *ngSwitchCase="'Asia/Jayapura'">(GMT+09:00) Jayapura</ng-container>
  <ng-container *ngSwitchCase="'Asia/Yakutsk'">(GMT+09:00) Moscow+06 - Yakutsk</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Palau'">(GMT+09:00) Palau</ng-container>
  <ng-container *ngSwitchCase="'Asia/Pyongyang'">(GMT+09:00) Pyongyang</ng-container>
  <ng-container *ngSwitchCase="'Asia/Seoul'">(GMT+09:00) Seoul</ng-container>
  <ng-container *ngSwitchCase="'Asia/Tokyo'">(GMT+09:00) Tokyo</ng-container>
  <ng-container *ngSwitchCase="'Australia/Adelaide'"
    >(GMT+09:30) Central Time - Adelaide</ng-container
  >
  <ng-container *ngSwitchCase="'Australia/Darwin'"
    >(GMT+09:30) Central Time - Darwin</ng-container
  >
  <ng-container *ngSwitchCase="'Antarctica/DumontDUrville'"
    >(GMT+10:00) Dumont D'Urville</ng-container
  >
  <ng-container *ngSwitchCase="'Australia/Brisbane'"
    >(GMT+10:00) Eastern Time - Brisbane</ng-container
  >
  <ng-container *ngSwitchCase="'Australia/Hobart'"
    >(GMT+10:00) Eastern Time - Hobart</ng-container
  >
  <ng-container *ngSwitchCase="'Australia/Melbourne'"
    >(GMT+10:00) Eastern Time - Melbourne</ng-container
  >
  <ng-container *ngSwitchCase="'Australia/Sydney'"
    >(GMT+10:00) Eastern Time - Melbourne, Sydney</ng-container
  >
  <ng-container *ngSwitchCase="'Pacific/Guam'">(GMT+10:00) Guam</ng-container>
  <ng-container *ngSwitchCase="'Asia/Vladivostok'"
    >(GMT+10:00) Moscow+07 - Vladivostok</ng-container
  >
  <ng-container *ngSwitchCase="'Pacific/Port_Moresby'">(GMT+10:00) Port Moresby</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Chuuk'">(GMT+10:00) Truk</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Efate'">(GMT+11:00) Efate</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Guadalcanal'">(GMT+11:00) Guadalcanal</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Kosrae'">(GMT+11:00) Kosrae</ng-container>
  <ng-container *ngSwitchCase="'Asia/Magadan'">(GMT+11:00) Moscow+08 - Magadan</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Norfolk'">(GMT+11:00) Norfolk</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Noumea'">(GMT+11:00) Noumea</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Pohnpei'">(GMT+11:00) Ponape</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Auckland'">(GMT+12:00) Auckland</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Fiji'">(GMT+12:00) Fiji</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Funafuti'">(GMT+12:00) Funafuti</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Kwajalein'">(GMT+12:00) Kwajalein</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Majuro'">(GMT+12:00) Majuro</ng-container>
  <ng-container *ngSwitchCase="'Asia/Kamchatka'"
    >(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy</ng-container
  >
  <ng-container *ngSwitchCase="'Pacific/Nauru'">(GMT+12:00) Nauru</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Tarawa'">(GMT+12:00) Tarawa</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Wake'">(GMT+12:00) Wake</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Wallis'">(GMT+12:00) Wallis</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Apia'">(GMT+13:00) Apia</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Enderbury'">(GMT+13:00) Enderbury</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Fakaofo'">(GMT+13:00) Fakaofo</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Tongatapu'">(GMT+13:00) Tongatapu</ng-container>
  <ng-container *ngSwitchCase="'Pacific/Kiritimati'">(GMT+14:00) Kiritimati</ng-container>
</ng-container>
