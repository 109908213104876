import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from 'app/services/auth.service';

@Pipe({ name: 'userFullname' })
export class UserFullnamePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(uuid: string): Observable<string> {
    if (!uuid) {
      return;
    }

    return this.authService.getUserFullName(uuid);
  }
}
