import { NgModule } from '@angular/core';
import { DebounceClickDirective } from './debounce-click.directive';
import { NoAutoCompleteDirective } from './no-auto-complete.directive';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { SortOrderIconSetterDirective } from './sort-order-icon-setter.directive';
import { AutoCloseMenuDirective } from 'app/directives/auto-close-menu.directive';
import { FilterInputDirective } from './filter-input.directive';

@NgModule({
  declarations: [
    NoAutoCompleteDirective,
    DebounceClickDirective,
    AutoFocusDirective,
    SortOrderIconSetterDirective,
    AutoCloseMenuDirective,
    FilterInputDirective
  ],
  exports: [
    NoAutoCompleteDirective,
    DebounceClickDirective,
    AutoFocusDirective,
    SortOrderIconSetterDirective,
    AutoCloseMenuDirective,
    FilterInputDirective
  ]
})
export class DirectiveModule {}
