<h1 mat-dialog-title oneDialogTitle class="header-page-title" fxLayout="row" fxLayoutAlign="space-between center">
  <ng-container [ngSwitch]="popupCode">
    <div i18n="PopupWindow.Title@@discard-unsaved-changes-question" *ngSwitchCase="'LEAVE_PAGE_DIALOG'">Discard unsaved changes?</div>
    <div i18n="PopupWindow.Title@@revert-to-roche-version-and-delete-current-version-question" *ngSwitchCase="'REVERT_VARIANT_DIALOG'">Revert to Roche version and delete current version?</div>
    <div i18n="PopupWindow.Title@@this-action-is-disabled" *ngSwitchCase="'CASE_APPROVAL_DIALOG'">This action is disabled</div>
    <div i18n="PopupWindow.Title@@reset-password-question" *ngSwitchCase="'USER_RESET_PASSWORD'">Reset password?</div>
    <div i18n="PopupWindow.Title@@delete-user-question" *ngSwitchCase="'USER_DELETE_DIALOG'">Delete user?</div>
    <div i18n="PopupWindow.Title@@user-deleted" *ngSwitchCase="'USER_DELETED_NOTIFICATION_DIALOG'">User deleted</div>
    <div i18n="PopupWindow.Title@@do-you-want-to-continue-question" *ngSwitchCase="'CLOSE_SECTION_DIALOG'">Do you want to continue?</div>
    <app-ruo-ivd-mapper *ngSwitchCase="'DELETE_CASE_DIALOG'">
      <div IVD i18n="PopupWindow.Title@@delete-case-question">Delete case?</div>
      <div RUO i18n="PopupWindow.Title@@delete-research-case-question">Delete research case?</div>
    </app-ruo-ivd-mapper>
    <app-ruo-ivd-mapper *ngSwitchCase="'CANCEL_CASE_DIALOG'">
      <div IVD i18n="PopupWindow.Title@@cancel-case-question">Cancel case?</div>
      <div RUO i18n="PopupWindow.Title@@cancel-research-case-question">Cancel research case?</div>
    </app-ruo-ivd-mapper>
    <div i18n="PopupWindow.Title@@reload-application-to-apply-settings-question" *ngSwitchCase="'APP_SETTINGS_SAVED'">Reload application to apply settings?</div>
    <div i18n="PopupWindow.Title@@bulk-edit-list" *ngSwitchCase="'BULK_EDIT_LIST'">Bulk edit list</div>
    <div i18n="PopupWindow.Title@@hiding-clinical-brief" *ngSwitchCase="'HIDE_BRIEF'">Hiding clinical brief</div>
    <div i18n="PopupWindow.Title@@add-to-report-question" *ngSwitchCase="'ADD_TO_REPORT_DIALOG'">Add to report?</div>
    <div i18n="PopupWindow.Notes@@version-change-completed" *ngSwitchCase="'ROCHE_VERSION_CHANGE'">Version change completed</div>
    <div i18n="PopupWindow.Title@@unable-to-load-the-file-due-to-the-following-errors" *ngSwitchCase="'VARIANT_TAG_FILE_ERROR'">Unable to load the file due to the following errors</div>
    <div i18n="PopupWindow.Title@@review-uploaded-file-contents" *ngSwitchCase="'THRESHOLD_PARSE_CONFIRM'">Review uploaded file contents</div>
    <div *ngSwitchCase="'THRESHOLD_PARSE_ERROR'"><mat-icon class="threshold-parse-error-icon" fontSet="material-icons">info</mat-icon> <ng-container i18n="PopupWindow.Notes@@unable-to-load-the-file-due-to-the-following-errors">Unable to load the file due to the following errors</ng-container></div>
    <div i18n="PopupWindow.Title@@api-key" *ngSwitchCase="'API_KEY_GENERATED'">API Key</div>
    <div i18n="PopupWindow.Title@@start-upgrade" *ngSwitchCase="'START_UPGRADE'">Start upgrade</div>
    <div i18n="PopupWindow.Title@@apply-changes-to-all-selected-variants-question" *ngSwitchCase="'RECLASSIFY_VARIANTS'">Apply changes to all selected variants?</div>
    <div fxLayout="row" fxLayoutGap="8px" *ngSwitchCase="'CHANGE_ROCHE_CURATED_CONTENT'"><div><mat-icon class="warning-icon">alarm_filled</mat-icon></div> <div i18n="PopupWindow.Notes@@change-roche-curated-content-version-question">Change Roche curated content version?</div></div>
    <div fxLayout="row" fxLayoutGap="8px" *ngSwitchCase="'CHANGE_TRANSCRIPTOME'"><div><mat-icon class="warning-icon">alarm_filled</mat-icon></div> <div i18n="PopupWindow.Notes@@change-transcriptome-question">Change Transcriptome?</div></div>
  </ng-container>
</h1>

<div mat-dialog-content oneDialogContent class="card-body" [class.full-width]="data?.fullWidthContent">
  <ng-container [ngSwitch]="popupCode">
    <div *ngSwitchCase="'USER_RESET_PASSWORD'">
        <ng-container i18n="PopupWindow.Notes@@an-email-with-a-link-to-reset-password-will-be-sent-to">An email with a link to reset password will be sent to</ng-container> {{ data.email }}.
    </div>
    <div i18n="PopupWindow.Notes@@you-have-unsaved-changes-on-this-page-if-you-leave-now-you-might-lose-these-changes" *ngSwitchCase="'LEAVE_PAGE_DIALOG'">You have unsaved changes on this page. If you leave now, you might lose these changes.</div>
    <div i18n="PopupWindow.Notes@@when-you-revert-to-the-roche-version-the-current-version-of-the-brief-will-be-deleted" *ngSwitchCase="'REVERT_VARIANT_DIALOG'">When you revert to the Roche version the current version of the brief will be deleted.</div>
    <div i18n="PopupWindow.Notes@@the-status-of-the-report-may-have-been-changed-recently-dismiss-the-dialog-and-refresh-the-page-to-see-an-updated-version" *ngSwitchCase="'CASE_APPROVAL_DIALOG'">The status of the report may have been changed recently. Dismiss the dialog and refresh the page to see an updated version.</div>
    <div i18n="PopupWindow.Notes@@are-you-sure-you-would-like-to-remove-this-user-account-question" *ngSwitchCase="'USER_DELETE_DIALOG'">Are you sure you would like to remove this user account?</div>
    <div i18n="PopupWindow.Notes@@saving-an-empty-brief-causes-a-revert-to-the-roche-brief-this-brief-is-hidden-till-you-chose-to-show-it" *ngSwitchCase="'HIDE_BRIEF'">Saving an empty brief causes a revert to the Roche brief. This brief is hidden till you chose to show it.</div>
    <div *ngSwitchCase="'USER_DELETED_NOTIFICATION_DIALOG'">
      <p>
        <ng-container i18n="PopupWindow.Paragraph@@in-the-user-logs-this-user-will-be-identified-by-the-internal-identifier">In the user logs, this user will be identified by the internal identifier:</ng-container> {{ data.id }}
      </p>
      <p i18n="PopupWindow.Paragraph@@please-save-this-information-for-your-records">Please save this information for your records.</p>
    </div>
    <div i18n="PopupWindow.Notes@@you-have-unsaved-changes-on-this-page-if-you-continue-you-may-lose-some-of-your-changes" *ngSwitchCase="'CLOSE_SECTION_DIALOG'">You have unsaved changes on this page. If you continue, you may lose some of your changes.</div>
    <div *ngSwitchCase="'CHANGE_ROCHE_CURATED_CONTENT'">
      <span i18n="PopupWindow.Notes@@changing-the-roche-curated-content-version-may-cause-some-of-the-information-related-to-this-assay-to-be-altered">Changing the Roche curated content version may cause some of the information related to this assay to be altered.</span>
      <ul>
        <li i18n="PopupWindow.List@@pertinent-negatives">Pertinent Negatives</li>
        <li i18n="PopupWindow.List@@virtual-gene-panels">Virtual Gene Panels</li>
        <li *ngIf="data.showTMBThresholds" i18n="PopupWindow.List@@tmb-msi-thresholds">TMB/MSI/HRDsig Thresholds</li>
        <li *ngIf="!data.callRegionClinical; else ivdClinicalRegion" i18n="PopupWindow.List@@region">Region</li>
        <ng-template #ivdClinicalRegion>
          <li i18n="PopupWindow.List@@clinical-region">Clinical Region
          </li>
        </ng-template>
      </ul>
    </div>
    <div *ngSwitchCase="'CHANGE_TRANSCRIPTOME'">
      <span i18n="PopupWindow.Notes@@changing-the-transcriptome-may-cause-some-of-the-information-related-to-this-assay-to-be-altered">Changing the Transcriptome may cause some of the information related to this assay to be altered.</span>
      <ul>
        <li i18n="PopupWindow.List@@pertinent-negatives">Pertinent Negatives</li>
        <li i18n="PopupWindow.List@@virtual-gene-panels">Virtual Gene Panels</li>
      </ul>
    </div>
    <div *ngSwitchCase="'DELETE_CASE_DIALOG'">
      <app-ruo-ivd-mapper>
        <p IVD i18n="PopupWindow.Paragraph@@deleting-a-case-will-permanently-remove-it-from-your-records">Deleting a case will permanently remove it from your records.</p>
        <p RUO i18n="PopupWindow.Paragraph@@deleting-a-research-case-will-permanently-remove-it-from-your-records">Deleting a research case will permanently remove it from your records.</p>
      </app-ruo-ivd-mapper>
    </div>
    <div *ngSwitchCase="'CANCEL_CASE_DIALOG'">
      <app-ruo-ivd-mapper>
        <p IVD i18n="PopupWindow.Paragraph@@a-cancelled-case-will-stay-in-your-records-but-you-will-not-be-able-to-make-further-changes-to-it">A cancelled case will stay in your records, but you will not be able to make further changes to it.</p>
        <p RUO i18n="PopupWindow.Paragraph@@a-cancelled-research-case-will-stay-in-your-records-but-you-will-not-be-able-to-make-further-changes-to-it">A cancelled research case will stay in your records, but you will not be able to make further changes to it.</p>
      </app-ruo-ivd-mapper>
    </div>
    <div i18n="PopupWindow.Notes@@to-apply-your-settings-changes-you-must-reload-the-application-if-you-click-not-now-the-changes-will-be-applied-the-next-time-you-load-the-app" *ngSwitchCase="'APP_SETTINGS_SAVED'">To apply your settings changes, you must reload the application. If you click 'Not now', the changes will be applied the next time you load the app.</div>
    <div *ngSwitchCase="'BULK_EDIT_LIST'">
      <p i18n="PopupWindow.Paragraph@@these-variants-were-reclassified-or-marked-as-do-not-include-in-report">These variants were reclassified or marked as do not include in report.</p>
      <p class="mat-body-2 cair-color-gray-500" ngPreserveWhitespaces>{{ data.createdAtDate }}, {{ data.createdAtTime }}</p>

      <div class="bulk-table-container">
        <one-table>
          <one-table-content>
            <table mat-table [dataSource]="data.dataSource" aria-label="Variant table" i18n-aria-label="PopupWindow.Table@@variant-table">
              <ng-container matColumnDef="variantName">
                <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@variant">Variant</th>
                <td mat-cell *matCellDef="let row">
                  <app-variant-title [name]="row.variantObject.name" [multipleLines]="true"></app-variant-title>
                </td>
              </ng-container>

              <ng-container matColumnDef="fromValue">
                <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@from">From</th>
                <td mat-cell *matCellDef="let row">
                  <app-tier-name [value]="row.fromValue"></app-tier-name>
                </td>
              </ng-container>

              <ng-container matColumnDef="toValue">
                <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@to">To</th>

                <td mat-cell *matCellDef="let row">
                  <ng-container *ngIf="row.toValue === 'RemovedFromReport'; else bulkEditDisplayTier" i18n="PopupWindow.Td@@removed-from-report">Removed from report</ng-container>
                  <ng-template #bulkEditDisplayTier>
                    <app-tier-name [value]="row.toValue"></app-tier-name>
                  </ng-template>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: data.displayedColumns;"></tr>
            </table>
          </one-table-content>
        </one-table>
      </div>
    </div>
    <div i18n="PopupWindow.Notes@@do-you-also-want-to-add-this-variant-biomarker-to-the-report-question" *ngSwitchCase="'ADD_TO_REPORT_DIALOG'">Do you also want to add this variant/biomarker to the report?</div>
    <div i18n="PopupWindow.Notes@@version-change-completed-some-of-the-biomarkers-in-your-pertinent-negatives-list-did-not-get-imported-please-review-your-pertinent-negatives-list" *ngSwitchCase="'ROCHE_VERSION_CHANGE'">Version change completed. Some of the biomarkers in your Pertinent Negatives list did not get imported. Please review your Pertinent Negatives list.</div>
    <div *ngSwitchCase="'THRESHOLD_PARSE_CONFIRM'" class="threshold-parse-container">
      <one-table>
        <one-table-content>
          <table mat-table [dataSource]="data.dataSource" aria-label="Disease table" i18n-aria-label="PopupWindow.Table@@disease-table">
            <ng-container matColumnDef="doid">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@disease">Disease</th>
              <td mat-cell *matCellDef="let row">
                {{row.diagnosisName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="tmbLowThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@tmb-low">TMB-Low</th>
              <td mat-cell *matCellDef="let row"><ng-template [ngTemplateOutlet]="nullCellEntry"></ng-template></td>
            </ng-container>

            <ng-container matColumnDef="tmbIntermediateThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@tmb-int">TMB-Int</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isFinite(row.tmbIntermediateThreshold); else nullCellEntry">
                  {{row.tmbIntermediateThreshold | localeNumber: 'MAX_5_DECIMAL'}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="tmbHighThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@tmb-high">TMB-High</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isFinite(row.tmbHighThreshold); else nullCellEntry">
                  {{row.tmbHighThreshold | localeNumber: 'MAX_5_DECIMAL'}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="mss">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@mss">MSS</th>
              <td mat-cell *matCellDef="let row"><ng-template [ngTemplateOutlet]="nullCellEntry"></ng-template></td>
            </ng-container>

            <ng-container matColumnDef="msiEquivocalThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@msi-equivocal">MSI-Equivocal</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isFinite(row.msiEquivocalThreshold); else nullCellEntry">
                  {{row.msiEquivocalThreshold | localeNumber: 'MAX_5_DECIMAL'}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="msiHighThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@msi-high">MSI-High</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isFinite(row.msiHighThreshold); else nullCellEntry">
                  {{row.msiHighThreshold | localeNumber: 'MAX_5_DECIMAL'}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="hrdPositiveThreshold">
              <th mat-header-cell *matHeaderCellDef i18n="PopupWindow.ColumnHeader@@hrdsig-positive">HRDsig-Positive</th>
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isFinite(row.hrdPositiveThreshold); else nullCellEntry">
                  {{row.hrdPositiveThreshold | localeNumber: 'MAX_5_DECIMAL'}}
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="data.displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: data.displayedColumns;"></tr>

            <ng-template #nullCellEntry>
              <span>-</span>
            </ng-template>
          </table>
        </one-table-content>
      </one-table>
    </div>
    <div *ngSwitchCase="'THRESHOLD_PARSE_ERROR'" class="threshold-parse-container">
      <div *ngFor="let generalError of data.generalErrors" class="general-error bold-text">
        <app-translation [translationKey]="generalError"></app-translation>
      </div>

      <div *ngFor="let rowError of data.rowErrors" class="row-error">
        <div class="bold-text"><ng-container i18n="PopupWindow.Notes@@row">Row</ng-container> {{rowError.row}}</div>
        <div>
          <span *ngFor="let col of rowError.cols; last as isLastCol">
            <ng-container i18n="PopupWindow.Notes@@column">column</ng-container> {{col.col}}:
            <span *ngFor="let type of col.types; last as isLastType">
              <app-translation [translationKey]="type"></app-translation><ng-container *ngIf="!isLastType" ngPreserveWhitespaces>, </ng-container>
            </span>
            <ng-container *ngIf="!isLastCol" ngPreserveWhitespaces>, </ng-container>
          </span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'VARIANT_TAG_FILE_ERROR'" class="scrolling-format">
      <div *ngFor="let error of data.errors">
        <div class="sub-content" ngPreserveWhitespaces>
          <ng-container *ngIf="error.field === 'gene'" [ngSwitch]="error.errorCode">
            <div *ngSwitchCase="'ABSENT'">
              <strong i18n="PopupWindow.Notes@@missing-gene">Missing gene</strong>
              (<span i18n="PopupWindow.Notes@@empty-cell-in-column-1">empty cell in column 1</span>)
            </div>
            <div *ngSwitchCase="'INVALID'">
              <strong i18n="PopupWindow.Notes@@unknown-gene">Unknown gene</strong>
              (<span i18n="PopupWindow.Notes@@invalid-value-in-column-1">invalid value in column 1</span>)
            </div>
          </ng-container>
          <ng-container *ngIf="error.field === 'variant'" [ngSwitch]="error.errorCode">
            <div *ngSwitchCase="'ABSENT'">
              <strong i18n="PopupWindow.Notes@@missing-variant">Missing variant</strong>
              (<span i18n="PopupWindow.Notes@@empty-cell-in-column-2">empty cell in column 2</span>)
            </div>
            <div *ngSwitchCase="'INVALID'">
              <strong i18n="PopupWindow.Notes@@unknown-variant">Unknown variant</strong>
              (<span i18n="PopupWindow.Notes@@invalid-p-name-or-c-name-in-column-2">invalid p-name or c-name in column 2</span>)
            </div>
          </ng-container>
          <ng-container *ngIf="error.field === 'tag'" [ngSwitch]="error.errorCode">
            <div *ngSwitchCase="'ABSENT'">
              <strong i18n="PopupWindow.Notes@@missing-tag">Missing tag</strong>
              (<span i18n="PopupWindow.Notes@@empty-cell-in-column-3">empty cell in column 3</span>)
            </div>
            <div *ngSwitchCase="'INVALID'">
              <strong i18n="PopupWindow.Notes@@unusable-tag">Unusable tag</strong>
              (<span i18n="PopupWindow.Notes@@some-tags-may-be-longer-than-24-characters-or-may-include-line-breaks-in-column-3">some tags may be longer than 24 characters or may include line breaks in column 3</span>)
            </div>
          </ng-container>
          <div>
            <span *ngFor="let record of error.records; last as isLast">
              <span i18n="PopupWindow.Notes@@row">row</span>:
              <span>{{record.row}}</span>
              <span *ngIf="!isLast">,&nbsp;</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'API_KEY_GENERATED'">
      <mat-form-field oneFormField>
        <input matInput [value]="data.apiKey" (click)="$event.target.select()" readonly>
        <button
          matSuffix
          class="api-key-copy-button"
          type="button"
          mat-raised-button
          color="primary"
          (click)="data.copyKey(); $event.stopPropagation()"
          i18n="PopupWindow.Button@@copy-key">COPY KEY
        </button>
      </mat-form-field>
      <div class="api-key-directions"
           i18n="PopupWindow.Notes(apiKeyDirections)@@copy-this-api-key-and-store-it-somewhere-safe-you-will-not-be-able-to-view-it-from-this-interface-again-as-this-key-gives-access-to-your-account-do-not-provide-this-key-to-any-non-authorized-users">
        Copy this API key and store it somewhere safe. You will not be able to view it from this interface again. As
        this key gives access to your account, do not provide this key to any non-authorized users.
      </div>
    </div>
    <div *ngSwitchCase="'START_UPGRADE'"><ng-container i18n="PopupWindow.Notes@@all-users-currently-logged-into">All users currently logged into</ng-container> <app-name></app-name> <ng-container i18n="@@will-be-logged-out-as-the-upgrade-process-starts-after-a-successful-upgrade-you-will-be-able-to-access-the-upgraded-version">will be logged out as the Upgrade process starts. After a successful upgrade, you will be able to access the upgraded version.</ng-container></div>
    <div *ngSwitchCase="'RECLASSIFY_VARIANTS'" ngPreserveWhitespaces>
      <div *ngIf="!data.isRemove; else removeFromReport">
        <p i18n="PopupWindow.Paragraph@@classification-of-the-selected-variants-will-be-changed">Classification of the selected variants will be changed.</p>
        <p><span i18n="PopupWindow.Paragraph@@no-of-variants">No. of variants:</span> <span class="bold-text">{{data.totalSelected}}</span></p>
        <p><span i18n="PopupWindow.Paragraph@@new-classification">New classification:</span> <app-tier-name class="bold-text" [value]="data.type"></app-tier-name></p>
        <p *ngIf="data.type === 'III'" i18n="PopupWindow.Paragraph@@these-variants-will-be-included-in-the-variants-of-unknown-significance-section-of-the-report">These variants will be included in the Variants of Unknown Significance section of the report.</p>
        <p *ngIf="data.type === 'IV'" i18n="PopupWindow.Paragraph@@these-variants-will-not-be-included-in-the-report">These variants will not be included in the report.</p>
        <p *ngIf="data.type === 'Unclassified'" i18n="PopupWindow.Paragraph@@these-variants-will-not-be-included-in-the-report">These variants will not be included in the report.</p>
      </div>
      <ng-template #removeFromReport>
        <p i18n="PopupWindow.Paragraph@@selected-variants-will-be-removed-from-the-report">Selected variants will be removed from the report.</p>
        <p><span i18n="PopupWindow.Paragraph@@no-of-variants">No. of variants:</span> <span class="bold-text">{{data.totalSelected}}</span></p>
        <div>
          <div i18n="PopupWindow.Notes@@when-the-bulk-edit-page-is-closed-these-variants-will-be-in-the-filtered-out-table">When the Bulk Edit page is closed, these variants will be in the "Filtered Out" table.</div>
          <div i18n="PopupWindow.Notes@@their-tier-remains-unchanged">(Their Tier remains unchanged).</div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>

<div mat-dialog-actions oneDialogActions class="dialog-actions-top-shadow" [ngClass]="{ 'large-dialog': popupCode === 'VARIANT_TAG_FILE_ERROR' }">
  <button *ngIf="negativeButton" mat-stroked-button one-secondary-button (click)="negativeResult()">
    <ng-container [ngSwitch]="negativeButton">
      <span i18n="PopupWindow.Button@@do-not-add" *ngSwitchCase="'DO_NOT_ADD'">Do not add</span>
      <app-ruo-ivd-mapper *ngSwitchCase="'KEEP_CASE'">
        <span IVD i18n="PopupWindow.Button@@keep-case">Keep case</span>
        <span RUO i18n="PopupWindow.Button@@keep-research-case">Keep research case</span>
      </app-ruo-ivd-mapper>
      <span i18n="@@not-now" *ngSwitchCase="'NOT_NOW'">Not now</span>
      <span i18n="@@discard" *ngSwitchCase="'DISCARD'">Discard</span>
      <span i18n="PopupWindow.Button@@cancel-button-text" *ngSwitchDefault>Cancel</span>
    </ng-container>
  </button>

  <button
    *ngIf="positiveButton"
    type="button"
    mat-button
    mat-raised-button
    color="primary"
    (click)="positiveResult()">
    <ng-container [ngSwitch]="positiveButton">
      <span i18n="PopupWindow.Button@@continue" *ngSwitchCase="'CONTINUE'">Continue</span>
      <span i18n="PopupWindow.Button@@accept" *ngSwitchCase="'ACCEPT'">Accept</span>
      <span i18n="PopupWindow.Button@@ok" *ngSwitchCase="'OK'">OK</span>
      <span i18n="PopupWindow.Button@@close" *ngSwitchCase="'CLOSE'">Close</span>
      <span i18n="PopupWindow.Button@@change" *ngSwitchCase="'CHANGE'">Change</span>
      <span i18n="PopupWindow.Button@@delete" *ngSwitchCase="'DELETE'">Delete</span>
      <app-ruo-ivd-mapper *ngSwitchCase="'DELETE_CASE'">
        <span IVD i18n="PopupWindow.Button@@delete-case">Delete case</span>
        <span RUO i18n="PopupWindow.Button@@delete-research-case">Delete research case</span>
      </app-ruo-ivd-mapper>
      <app-ruo-ivd-mapper *ngSwitchCase="'CANCEL_CASE'">
        <span IVD i18n="PopupWindow.Button@@cancel-case">Cancel case</span>
        <span RUO i18n="PopupWindow.Button@@cancel-research-case">Cancel research case</span>
      </app-ruo-ivd-mapper>
      <span i18n="PopupWindow.Button@@reload-app" *ngSwitchCase="'RELOAD'">Reload app</span>
      <span i18n="PopupWindow.Button@@add-to-report" *ngSwitchCase="'ADD_TO_REPORT'">Add to report</span>
      <span i18n="PopupWindow.Button@@logout-all-users-and-start-the-upgrade" *ngSwitchCase="'START_UPGRADE'">Logout all users and start the upgrade</span>
      <span i18n="PopupWindow.Button@@apply-changes" *ngSwitchCase="'APPLY_CHANGES'">Apply changes</span>
      <span i18n="PopupWindow.Button@@discard-changes" *ngSwitchCase="'DISCARD_CHANGES'">Discard changes</span>
      <span i18n="PopupWindow.Button@@reset" *ngSwitchCase="'RESET'">Reset</span>
      <span i18n="PopupWindow.Button@@revert" *ngSwitchCase="'REVERT'">Revert</span>
    </ng-container>
  </button>
</div>
