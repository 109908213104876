import { BaseEntity } from './baseEntity';

export class VariantBrief extends BaseEntity {
  revision: number;
  text: string;
  lastModifiedByUserId: string;
  lastModifiedAt: string;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
