import { Injectable } from '@angular/core';
import { AdditionalData, SubTitle } from 'app/model/entities/nav-bar.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NavBarService {
  private _subTitle = new BehaviorSubject<SubTitle>(null);
  private _backLink = new BehaviorSubject<string>(null);
  private _enableNavigation = new BehaviorSubject<boolean>(true);
  private _productNameHidden = new BehaviorSubject<boolean>(false);
  private _additionalData = new BehaviorSubject<AdditionalData>(null);

  get subTitle(): Observable<SubTitle> {
    return this._subTitle.asObservable();
  }

  get backLink(): Observable<string> {
    return this._backLink.asObservable();
  }

  get enableNavigation(): Observable<boolean> {
    return this._enableNavigation.asObservable();
  }

  get productNameHidden(): Observable<boolean> {
    return this._productNameHidden.asObservable();
  }

  get additionalData(): Observable<AdditionalData> {
    return this._additionalData.asObservable();
  }

  setNavigationEnabled(enable = true): void {
    this._enableNavigation.next(enable);
  }

  setSubTitle(subTitleId: string, subTitleValue: string): void {
    const subTitle: SubTitle = {
      id: subTitleId,
      value: subTitleValue
    };
    this._subTitle.next(subTitle);
  }

  setBackLink(backLink: string): void {
    this._backLink.next(backLink);
  }

  setAdditionalData(data: AdditionalData): void {
    this._additionalData.next(data);
  }

  clearSubTitle(): void {
    this._subTitle.next(null);
  }

  clearBackLink(): void {
    this._backLink.next('homeIcon');
  }

  hideBackLink(): void {
    this._backLink.next(null);
  }

  hideProductName(): void {
    this._productNameHidden.next(true);
  }

  showProductName(): void {
    this._productNameHidden.next(false);
  }
}
