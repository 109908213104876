import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated) {
      const loginPath = '/login';
      if (environment.platform) {
        // Need to reload the page as login is now part of Athens microsite.
        CommonService.reloadApp(loginPath);
      } else {
        this.router.navigateByUrl(loginPath);
      }
      return false;
    }
    return true;
  }
}
