import { Injectable } from '@angular/core';
import {
  BodyOutputType,
  ToasterService as ToasterServiceToAdapt,
  Toast,
  ToastType
} from 'angular2-toaster';
import { TranslationComponent } from 'app/shared/translation/translation.component';
import { CaseFileUploadStatus } from 'app/model/entities/case';

@Injectable()
export class ToasterService {
  private _uploadPendingToast: object = {};

  constructor(private toasterServiceToAdapt: ToasterServiceToAdapt) {}

  success(translationKey: string, translationData: any = {}, additionalOptions?: any): Toast {
    return this.showToast('success', translationKey, translationData, additionalOptions);
  }

  error(translationKey: string, translationData: any = {}, additionalOptions?: any): Toast {
    return this.showToast('error', translationKey, translationData, additionalOptions);
  }

  info(translationKey: string, translationData: any = {}, additionalOptions?: any): Toast {
    return this.showToast('info', translationKey, translationData, additionalOptions);
  }

  clear(toastId: string) {
    this.toasterServiceToAdapt.clear(toastId);
  }

  clearAll() {
    this.toasterServiceToAdapt.clear();
  }

  uploadPending(caseId: string, caseFileUploadStatus: CaseFileUploadStatus): Toast {
    if (this._uploadPendingToast[caseId]) {
      return;
    }
    const toast: Toast = {
      type: 'info',
      data: {
        translationKey: 'FILES_BEING_UPLOADED',
        caseId,
        caseFileUploadStatus
      },
      body: TranslationComponent,
      bodyOutputType: BodyOutputType.Component,
      timeout: 0
    };
    this._uploadPendingToast[caseId] = toast;
    return this.toasterServiceToAdapt.pop(this._uploadPendingToast[caseId]);
  }

  clearUploadPending(caseId: string) {
    if (!this._uploadPendingToast[caseId]) {
      return;
    }
    this.toasterServiceToAdapt.clear(this._uploadPendingToast[caseId].toastId);
    this._uploadPendingToast[caseId] = null;
  }

  private showToast(
    type: ToastType,
    translationKey: string,
    translationData: any,
    additionalOptions?: any
  ): Toast {
    const toast: Toast = {
      type,
      data: { translationKey, translationData },
      body: TranslationComponent,
      bodyOutputType: BodyOutputType.Component
    };

    if (additionalOptions) {
      Object.assign(toast, additionalOptions);
    }

    return this.toasterServiceToAdapt.pop(toast);
  }
}
