import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map, of } from 'rxjs';

@Component({
  selector: 'app-therapy-chip',
  templateUrl: './therapy-chip.component.html',
  styleUrls: ['./therapy-chip.component.scss']
})
export class TherapyChipComponent implements OnInit {
  @Input() names: string[];
  @Input() indication: 'this' | 'other';
  @Input() type: string;
  @Input() small = false;
  therapyClass$: Observable<string>;

  private therapyClassSubject: BehaviorSubject<string>;

  private classPrefix = 'chip-therapy-';

  private get calculateTherapyClass(): string {
    return `${this.classPrefix}${this.type}-${this.indication}`;
  }

  private get therapyClassGetter$(): Observable<string> {
    return combineLatest([this.therapyClassSubject, this.small ? of(' small-width') : of('')]).pipe(
      map(([therapyClass, smallWidth]) => therapyClass + smallWidth)
    );
  }

  ngOnInit(): void {
    this.therapyClassSubject = new BehaviorSubject(this.calculateTherapyClass);
    this.therapyClass$ = this.therapyClassGetter$;
  }
}
