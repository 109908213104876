<div
  *ngIf="isAdded"
  class="report-status-icon"
  matTooltip="Manually added to report"
  oneTooltip
  i18n-matTooltip="VariantPinIcon.Notes(reportStatusIcon).MatTooltip@@manually-added-to-report"
>
  <mat-icon svgIcon="pin" class="md-16"></mat-icon>
</div>
<div
  *ngIf="!isAdded"
  class="report-status-icon"
  matTooltip="Manually removed from the report"
  oneTooltip
  i18n-matTooltip="
    VariantPinIcon.Notes(reportStatusIcon).MatTooltip@@manually-removed-from-the-report"
>
  <mat-icon svgIcon="unpin" class="md-16"></mat-icon>
</div>
