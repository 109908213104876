<ng-container [ngSwitch]="value">
  <div class="tier-descriptions" *ngSwitchCase="TierNames.IA">
    <p i18n="TierDescription.Notes@@approved-therapy-included-in-professional-guidelines-1">
      Biomarkers that predict response or resistance AND are approved by drug agency or recommended by medical guidelines based on the region specified.
    </p>
    <p i18n="TierDescription.Notes@@approved-therapy-included-in-professional-guidelines-2">
      Biomarker that has prognostic or diagnostic clinical significance based on medical guidelines for the specified geographic region.
    </p>
  </div>
  <div class="tier-descriptions" *ngSwitchCase="TierNames.IB">
    <p i18n="TierDescription.Notes@@well-powered-studies-with-consensus-from-experts-in-the-field-1">
      Biomarkers that predict response or resistance to therapies for the tumor type based on well-powered studies (clinical trials) with consensus from experts in the field.
    </p>
    <p i18n="TierDescription.Notes@@well-powered-studies-with-consensus-from-experts-in-the-field-2">
      Biomarkers that achieved high-clinical prognostic and diagnostic significance based on well-powered studies.
    </p>
  </div>
  <div class="tier-descriptions" *ngSwitchCase="TierNames.IIC">
    <p i18n="TierDescription.Notes@@tier-description-approved-therapies-message-1">
      Biomarkers that predict response or resistance based on multiple small-published studies with emerging consensus.
    </p>
    <p i18n="TierDescription.Notes@@tier-description-approved-therapies-message-2">
      Biomarkers that predict response or resistance to therapies approved by a drug agency or recommended by medical guidelines societies for a different type of tumor.
    </p>
    <p i18n="TierDescription.Notes@@tier-description-approved-therapies-message-3">
      Biomarkers that serve as inclusion criteria for clinical trials.
    </p>
  </div>
  <div class="tier-descriptions" *ngSwitchCase="TierNames.IID">
    <p i18n="TierDescription.Notes@@limited-clinical-or-preclinical-studies-1">
      Biomarkers that show plausible therapeutic significance based on at least one case report. Biomarkers with possible clinical significance based on preclinical studies.
    </p>
  </div>
  <div class="tier-descriptions" *ngSwitchCase="TierNames.III">
    <p i18n="TierDescription.Notes@@variants-of-unknown-clinical-significance-1">
      Variants of unknown significance.
    </p>
    <p i18n="TierDescription.Notes@@variants-of-unknown-clinical-significance-2">
      Not observed at a significant allele frequency across the general population or subpopulations or not well-represented in cancer databases.
    </p>
    <p i18n="TierDescription.Notes@@variants-of-unknown-clinical-significance-3">
      No published functional evidence of cancer association.
    </p>
  </div>
  <div class="tier-descriptions" *ngSwitchCase="TierNames.IV">
    <p i18n="TierDescription.Notes@@tier-description-benign-message-1">
      Observed at significant allele frequencies in the general population or specific subpopulations AND no published evidence of cancer association.
    </p>
  </div>
</ng-container>
