import { Component } from '@angular/core';

// This component is used to hold strings for use in email templates.
// It is not intended for usage in the UI.
@Component({
  selector: 'app-email-translations',
  templateUrl: './email-translations.component.html'
})
export class EmailTranslationsComponent {
  constructor() {}
}
