import { Component, Input } from '@angular/core';

@Component({
  selector: 'save-error-message',
  templateUrl: './save-error-message.component.html',
  styleUrls: ['./save-error-message.component.scss']
})
export class SaveErrorMessageComponent {
  @Input() saveError: string;
}
