<div class="roche-vf-panel" fxLayout="column">
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" class="vf-panel-header">
    <img fxFlex="40px" alt="Roche logo" i18n-alt="RocheNetworkVfPanel.logo@@roche-logo" class="roche-logo header-item" src="/assets/images/Roche_Logo.svg" />
    <div fxLayout="column">
      <div i18n="RocheNetworkVfPanel.Notes@@roche-network-s-data-for-disease" class="cair-color-gray-400">Roche network's data for disease</div>
      <div ngPreserveWhitespaces [matTooltip]="combinedVariantName$ | async" class="mat-subheading-1">
        <span class="mat-subheading-1">
          {{ diagnosis.name }}
        </span>
        <ng-container *ngIf="variant">
          <app-ruo-ivd-mapper>
            <ng-container IVD i18n="RocheNetworkVfPanel.Notes@@cases-with">cases with</ng-container>
            <ng-container RUO i18n="RocheNetworkVfPanel.Notes@@cases-with">research cases with</ng-container>
          </app-ruo-ivd-mapper>
          <app-variant-title [name]="variant.name" *ngIf="hasArrayName$ | async"></app-variant-title>
          <span class="mat-subheading-1" *ngIf="!(hasArrayName$ | async)">{{ variant.name || variant.value }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- 1st scenario: optedIn -->
  <div *ngIf="optedIn && classification" fxLayout="column" fxFlex fxLayoutGap="16px">
    <table fxFlex aria-hidden="true">
      <tr>
        <td i18n="RocheNetworkVfPanel.Td@@classification">
          Classification
        </td>
        <td i18n="RocheNetworkVfPanel.Td@@seen-in">
          Seen in
        </td>
        <td>         
          <app-ruo-ivd-mapper>
            <ng-container RUO i18n="RocheNetworkVfPanel.Td@@number-of-analytics-reports-across-all-labs">Number of analysis reports across all labs</ng-container>
            <ng-container IVD i18n="RocheNetworkVfPanel.Td@@number-of-reports-across-all-labs">Number of reports across all labs</ng-container>
          </app-ruo-ivd-mapper>
        </td>
      </tr>
      <tr class="tier-row" *ngFor="let tier of tierList">
        <td>
          <div fxFlexLayout="column">
            <span class="bold-text"><app-tier-name [value]="tier"></app-tier-name></span>
            <div class="cair-color-gray-400" fxFlexLayout="column" *ngIf="classification[tier].rocheClassification">
              <div *ngIf="classification[tier].rocheClassification.contentRelease">
                <ng-container i18n="RocheNetworkVfPanel.Notes@@roche-version">Roche version</ng-container> {{ classification[tier].rocheClassification.contentRelease }}
              </div>
              <div *ngIf="differentClinicalRegions(classification[tier].rocheClassification.clinicalRegion).length > 1">
                ({{ differentClinicalRegions(classification[tier].rocheClassification.clinicalRegion).join(', ') }})
              </div>
            </div>
            <div class="cair-color-gray-400" *ngIf="classification[tier].reclassifiedOn">
              <ng-container i18n="RocheNetworkVfPanel.Notes@@your-lab-on">Your lab on</ng-container> {{ classification[tier].reclassifiedOn | dateFormat:localization.dateFormat }}
            </div>
          </div>
        </td>
        <td [ngSwitch]="classification[tier].labCount" ngPreserveWhitespaces>
          <ng-container *ngSwitchCase="'MORE_THAN_THREE'">&gt;3<ng-container i18n="RocheNetworkVfPanel.Td@@labs"> labs</ng-container></ng-container>
          <ng-container *ngSwitchCase="'ONE_TO_THREE'">1-3<ng-container i18n="RocheNetworkVfPanel.Td@@labs"> labs</ng-container></ng-container>
          <ng-container *ngSwitchCase="'ZERO'"></ng-container>
        </td>
        <td [ngSwitch]="classification[tier].caseCount">
          <ng-container *ngSwitchCase="'MORE_THAN_THREE'">&gt;3<ng-container i18n="RocheNetworkVfPanel.Td@@reports"> reports</ng-container></ng-container>
          <ng-container *ngSwitchCase="'ONE_TO_THREE'">1-3<ng-container i18n="RocheNetworkVfPanel.Td@@reports"> reports</ng-container></ng-container>
          <ng-container *ngSwitchCase="'ZERO'"></ng-container>
        </td>
      </tr>
    </table>
    <div class="body-1 normal-line-height" i18n="RocheNetworkVfPanel.Notes@@includes-data-from-the-roche-network-excluding-your-lab-data-across-all-assays-and-regions">Includes data from the Roche network excluding your lab data across all assays and regions</div>
    <div class="footer cair-color-gray-400 mat-small"><ng-container i18n="RocheNetworkVfPanel.Notes@@last-updated">Last updated:</ng-container> {{ updatedAt | dateFormat:localization.dateFormat }}</div>
  </div>
  <!-- 2nd scenario: not optedIn -->
  <div fxLayout="column" fxFlex="stretch" *ngIf="optedIn === false" fxLayoutGap="28px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex="35%" class="sharing-icon-container" fxLayoutGap="20px">
        <mat-icon class="cair-color-gray-400" mat-card-avatar svgIcon="data-sharing"></mat-icon>
        <div *ngIf="!(authorizedToShare$ | async)" class="cair-color-gray-400" i18n="RocheNetworkVfPanel.Notes@@opt-in-into-datasharing-and-see-data-from-the-entire-roche-network">
          Opt in into datasharing and see data from the entire Roche network
        </div>
        <a class="rss-link" routerLink="/account/data-sharing" *ngIf="authorizedToShare$ | async" i18n="RocheNetworkVfPanel.Link@@opt-in-into-datasharing-and-see-data-from-the-entire-roche-network">
          Opt in into datasharing and see data from the entire Roche network
        </a>
      </div>
    </div>
    <div *ngIf="!(authorizedToShare$ | async)" class="cair-caption-3" fxLayoutAlign="center center" i18n="RocheNetworkVfPanel.Notes(cairCaption3)@@opt-in-to-data-sharing-requires-lab-director-privileges">
      Opt-in to data sharing requires lab director privileges
    </div>
  </div>
  <!-- 3rd scenario: No data -->
  <div *ngIf="optedIn === true && !classification && variant" fxFlex="100%" fxLayoutAlign="center center" i18n="RocheNetworkVfPanel.Notes@@there-is-no-data-to-see-yet">
    There is no data to see yet
  </div>
  <!-- 4th scenario: No variant chosen -->
  <div *ngIf="optedIn === true && !classification && !variant" fxFlex="100%" fxLayoutAlign="center center" i18n="RocheNetworkVfPanel.Notes@@select-a-variant-to-see-shared-data">
    Select a variant to see shared data
  </div>
</div>
