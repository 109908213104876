import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './domain/login/login-page/login-page.component';
import { NotFoundPageComponent } from './shared/not-found-page/not-found-page.component';
import { ForbiddenPageComponent } from 'app/shared/forbidden-page/forbidden-page.component';
import { SystemUnavailablePageComponent } from 'app/shared/system-unavailable-page/system-unavailable-page.component';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';

// route ends with 'case/{caseId}/report' skips agreement check
// user can access this route without accepting agreement since the case report is generated in other browsers
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./domain/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'case',
    loadChildren: () => import('./domain/case/case.module').then((m) => m.CaseModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./domain/account/account.module').then((m) => m.AccountModule)
  },
  { path: 'login', component: LoginPageComponent },
  // Code for terms, uncomment when enable the terms
  // {path: 'terms', component: TermsPageComponent},
  { path: 'forbidden', component: ForbiddenPageComponent },
  { path: 'system-unavailable', component: SystemUnavailablePageComponent },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategy,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategy]
})
export class RoutingModule {}
