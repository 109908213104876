import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { has as _has } from 'lodash';
import { User } from 'app/model/entities/user';
import { AuthService } from './auth.service';
import { UserRoles } from 'app/model/valueObjects/userRoles';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

export const USER_ROLES = [
  UserRoles.ADMINISTRATOR,
  UserRoles.LAB_TECHNICIAN,
  UserRoles.GENETICIST,
  UserRoles.LAB_DIRECTOR,
  UserRoles.ROCHE_ADMINISTRATOR
];

/* Some more user methods (i.e. userId => userName) are on AuthService */

@Injectable()
export class UserService {
  private _userList = new BehaviorSubject<User[]>(null);
  private _currentUser = new BehaviorSubject<User>(null);
  private _loadingUserList$ = new BehaviorSubject<boolean>(null);
  private _loadingUser = new BehaviorSubject<boolean>(null);
  private _savingContentUpdateSubscription = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get loadingUserList$() {
    return this._loadingUserList$.asObservable();
  }

  get loadingUser() {
    return this._loadingUser.asObservable();
  }

  get currentUser() {
    return this._currentUser.asObservable();
  }

  get userList() {
    return this._userList.asObservable();
  }

  get savingContentUpdateSubscription() {
    return this._savingContentUpdateSubscription.asObservable();
  }

  getUsers() {
    this._loadingUserList$.next(true);
    this.http
      .get(this.authService.getURL(SessionLinkKeys.LIST_USERS))
      .pipe(
        map((data: any) => data._embedded.users),
        finalize(() => this._loadingUserList$.next(false))
      )
      .subscribe((users: User[]) => {
        this._userList.next(users);
      });
  }

  update(userData: any) {
    return this.http.put(this._currentUser.value._links.editUser.href, userData);
  }

  delete(link: string) {
    return this.http.delete(link);
  }

  loadUser(userId: string) {
    this._loadingUser.next(true);
    return this.http
      .get(this.authService.getURL(SessionLinkKeys.VIEW_USER, { userId }))
      .pipe(finalize(() => this._loadingUser.next(false)))
      .subscribe((user: User) => {
        this._currentUser.next(user);
      });
  }

  toggleMFA(link: string, newState: boolean) {
    return this.http.post(link, { mfaEnabled: newState });
  }

  resetPassword(link: string) {
    return this.http.post(link, null);
  }

  logout(link: string) {
    return this.http.post(link, null);
  }

  create(userData: any) {
    return this.http.post(this.authService.getURL(SessionLinkKeys.CREATE_USER), userData);
  }

  hasPermission(permissionToVerify: string): boolean {
    return _has(this._currentUser.value._links, permissionToVerify);
  }

  setContentUpdateSubscription(url: string, contentUpdateSubscription: boolean) {
    this._savingContentUpdateSubscription.next(true);
    return this.http
      .post(url, { contentUpdateSubscription })
      .pipe(finalize(() => this._savingContentUpdateSubscription.next(false)));
  }
}
