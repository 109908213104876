import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { isEqual as _isEqual, isEqualWith as _isEqualWith, isObject as _isObject } from 'lodash';

import { CommonService } from 'app/services/common.service';
import { CheckBeforeInput } from 'app/validators/checkBeforeInput.validator';
import { VARIANT_FILTER, VARIANT_FILTER_TYPE } from 'app/model/valueObjects/variantFilter';
import { FilterableProperties } from 'app/model/entities/secondaryAnalysisFileFormat';
import { takeUntil } from 'rxjs/operators';
import { LocaleNumberPipe } from 'app/pipes/locale-number.pipe';

export interface FilterDataFormat {
  column: string;
  operator: string;
  operand: any;
}

export interface FilterGroupFormat {
  [group: string]: FilterDataFormat[];
}

interface GlobalFiltersFormat {
  applyFiltersForTierIAndII: boolean;
}

export interface FilterOutputData {
  [bioMarkerType: string]: FilterGroupFormat | GlobalFiltersFormat | boolean;
}

export interface FilterInputData {
  type: string;
  defaultFilter?: FilterGroupFormat;
  initialData: FilterGroupFormat;
  pipelineDependentProps?: FilterableProperties;
  popFreqSource?: string;
}

export interface CtrlMap {
  somatic?: FormGroup;
  readRelated?: FormGroup;
  quality?: FormGroup;
  germline?: FormGroup;
  inFrame?: FormGroup;
  copyNumber?: FormGroup;
}

export interface ValidationAlert {
  type: string;
  title: string;
  message: string;
}

const checkboxOperandPairs = [
  ['qualityEnum', 'qualityEnumCheck'],
  ['equivocal', 'equivocalCheck'],
  ['vendorAnnotatedInFrame', 'vendorAnnotatedInFrameCheck']
];

/**
  * This component accepts the filter list in the following format:
    [
      {
        "type": "SmallVariant",
        "initialData": {
          "somatic": [
            { "column": "cosmic_globalSiteCount", "operator": ">=", "operand": 10 }
            ...
          ],
          "quality": [...],
          "germline": [...]
        },
        "defaultFilter": {...},
        "pipelineDependentProps": {},
        "popFreqSource": 'gnomAD'
      },
      {
        "type": "CopyNumberVariant",
        "initialData": {...},
        "defaultFilter": {...},
        "pipelineDependentProps": {
          "copyNumber": [ "gainCopyNumber", "lossCopyNumber" ]
        },
        "popFreqSource": null
      },
      {
        "type": "GeneFusion",
        "initialData": {...},
        "defaultFilter": {...},
        "pipelineDependentProps": {...},
        "popFreqSource": null
      }
    ]
  */
@Component({
  selector: 'app-variant-filter',
  templateUrl: './variant-filter.component.html',
  styleUrls: ['./variant-filter.component.scss']
})
export class VariantFilterComponent implements OnInit, OnDestroy, OnChanges {
  static BLANK_FILTERS: any = {}; // TODO: type safety (create VariantFilterType or something)

  @Input() filtersList: FilterInputData[];
  @Input() isDefaultFilter?: boolean;
  @Input() manualMode = false;
  @Input() editMode = true;
  @Input() allowApplyFiltersForTierIAndII = false;
  @Input() initialIgnoreFiltersForTierIAndII = false;
  @Input() displayAsPopup = false;
  @Input() canEditFilter: boolean;
  @Input() filterError?: string;
  @Output() close = new EventEmitter<boolean>();
  @Output() outputData = new EventEmitter<any>();

  filterForm = new FormGroup({
    ignoreFiltersForTierIAndII: new FormControl(false)
  });
  validations: ValidationAlert[] = [];

  filterablePropGroups: { [type: string]: FilterableProperties } = {};

  filterTypes = VARIANT_FILTER_TYPE;

  private ngUnsubscribe = new Subject<void>();
  private readonly MAX_FILTER_FIELD_VALUE = 1073741866; // Based on the discussion around practical value for filter fields, we agreed on "2^30+42".

  constructor(private commonService: CommonService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.filtersList.forEach((filter) => {
      const biomarkerType = filter.type;
      this.filterablePropGroups[biomarkerType] = Object.assign(
        {},
        VARIANT_FILTER[biomarkerType],
        filter.pipelineDependentProps
      );
      this.filterForm.controls[biomarkerType] = this.fb.group(this.buildFormGroups(biomarkerType));
      this.initializeData(biomarkerType, filter.initialData);
      this.subscribeToInputValueChanges(biomarkerType);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Set initial value from API
    if (changes.initialIgnoreFiltersForTierIAndII?.isFirstChange?.()) {
      this.filterForm
        .get('ignoreFiltersForTierIAndII')
        .setValue(changes.initialIgnoreFiltersForTierIAndII.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeFilter(): void {
    this.close.emit(true);
  }

  isFilterButtonDisable(): boolean {
    if (!this.canEditFilter) return true;

    // Check for invalid control.  Can we just check the whole form, though?
    if (Object.keys(this.filterForm.controls).some((c) => this.filterForm.get(c).invalid))
      return true;

    // If we're creating an assay and haven't yet set a default filter, accept a blank filter.
    if (!this.editMode) return false;

    // Check whether user has changed any values.
    const filtersValue = this.filters;
    return (
      this.filtersList.every((filterData) => {
        const blankFilter = VariantFilterComponent.BLANK_FILTERS[filterData.type];
        return _isEqualWith(
          { ...blankFilter, ...(filtersValue[filterData.type] as FilterGroupFormat) },
          { ...blankFilter, ...filterData.initialData },
          (valueA: any, valueB: any) => {
            if (valueA.length && valueB.length) {
              return _isEqual(new Set(valueA), new Set(valueB));
            }
          }
        );
      }) &&
      (!this.allowApplyFiltersForTierIAndII ||
        (filtersValue.globalFilters as GlobalFiltersFormat).applyFiltersForTierIAndII !==
          this.initialIgnoreFiltersForTierIAndII)
    );
  }

  emitOutput(): void {
    this.outputData.emit(this.filters);
  }

  getValidations(): ValidationAlert[] | undefined {
    if (!this.filterablePropGroups) {
      return;
    }

    this.validations = [];
    this.filtersList.forEach((filterData) => {
      const type = filterData.type;
      Object.entries(this.filterablePropGroups[type]).forEach(([propGroup, props]) => {
        props.forEach((filter) => {
          const formGrp = this.filterForm.get(type);
          if (!formGrp.get(propGroup).get(filter).valid) {
            const alert: ValidationAlert = {
              type: type,
              title: filter,
              message: formGrp.get(propGroup).get(filter).hasError('invalidValue')
                ? 'invalid'
                : 'missing'
            };
            this.validations.push(alert);
          }
        });
      });
    });

    return this.validations;
  }

  /**
   * Returns the data in the following format:
   * 'SmallVariant': {
   *    'somatic': [...],
   *    'germline: [...]
   *    ...
   * },
   * 'CopyNumberVariant: {..},
   * 'GeneFusion': {...}
   */
  get filters(): FilterOutputData {
    const newFilters: any = {};

    this.filtersList.forEach((filterData) => {
      const propertiesGroup = this.filterablePropGroups[filterData.type];
      newFilters[filterData.type] = {};

      // Iterate through the selected fields and prepare the filter values in API format.
      Object.entries(propertiesGroup).forEach(([group, props]) => {
        newFilters[filterData.type][group] = props
          .filter((filter) => {
            const checkboxCtrl = this.filterCheckboxEle(filterData.type, group, filter);
            return checkboxCtrl ? checkboxCtrl.value : true;
          })
          .map((filter) => this.getSingleFilter(filterData.type, group, filter));
      });

      /**
       * Special case handling: Append `pf_in` if `pf_globalAlleleFreqDerived` is selected. It's needed for API.
       * TODO: Move this to API level in future.
       */
      const alleFreqCheckbox = this.filterCheckboxEle(
        filterData.type,
        'germline',
        'pf_globalAlleleFreqDerived'
      );
      const isAlleFreqFieldSelected = alleFreqCheckbox && alleFreqCheckbox.value;
      if (isAlleFreqFieldSelected) {
        const pfInFilter = {
          column: 'pf_in',
          operator: 'is',
          operand: false
        };
        newFilters[filterData.type]['germline'].push(pfInFilter);
      }
    });

    if (this.allowApplyFiltersForTierIAndII) {
      newFilters.globalFilters = {
        // Invert value for apply/ignore
        applyFiltersForTierIAndII: !this.filterForm.get('ignoreFiltersForTierIAndII').value
      };
    }

    return newFilters;
  }

  initializeData(type: string, data: FilterGroupFormat): void {
    if (!data) {
      return;
    }

    Object.entries(data).forEach(([group, filters]) => {
      filters.forEach((filter) =>
        this.initializeSingleFilter(type, group, filter.column, filter.operand, true)
      );
    });
  }

  resetToDefault(): void {
    this.filtersList.forEach((filterData) => {
      // Clear the fields first before assigning default values
      Object.entries(this.filterablePropGroups[filterData.type]).forEach(([group, props]) => {
        props.forEach((filter) =>
          this.initializeSingleFilter(filterData.type, group, filter, null, false)
        );
      });

      this.initializeData(filterData.type, filterData.defaultFilter);
    });
  }

  private subscribeToInputValueChanges(type: string) {
    // If input changes, corresponding checkbox will be selected automatically
    Object.entries(this.filterablePropGroups[type]).forEach(([group, props]) => {
      props.forEach((pName) => {
        const input = this.filterInputEle(type, group, pName);
        const check = this.filterCheckboxEle(type, group, pName);
        if (check && input) {
          input.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => check.setValue(true));
        }

        if (check) {
          check.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            // This is to update the validation status of the input field
            this.filterForm.get(type).get(group).get(pName).updateValueAndValidity();
            this.getValidations();
          });
        }
      });
    });
  }

  private getSingleFilter(type: string, filterGroupName, filter): Partial<FilterDataFormat> {
    const input = this.filterInputEle(type, filterGroupName, filter);
    const check = this.filterCheckboxEle(type, filterGroupName, filter);

    const isFilterSelected = check ? check.value : true;
    if (isFilterSelected) {
      const result = {};
      result['column'] = filter;
      result['operator'] = this.getFieldOperator(filter);

      if (input) {
        const legacyPercentageFields = [
          'VAF',
          'pf_globalAlleleFreqDerived',
          'alleleFractionPercentage'
        ];
        if (legacyPercentageFields.includes(filter)) {
          result['operand'] = input.value
            ? this.commonService.parseFloatLocale(input.value) / 100
            : '';
        } else if (filter == 'pValue') {
          const localeNumber = new LocaleNumberPipe();
          result['operand'] = input.value ? localeNumber.transform(input.value, '0.000000e+0') : '';
        } else {
          result['operand'] = input.value ? this.commonService.parseFloatLocale(input.value) : '';
        }
      } else {
        result['operand'] = this.filterForm.get(type).get(filterGroupName).get(filter).value;

        // Special handling to remove parent checkbox value before saving
        checkboxOperandPairs.forEach((operandPair) => {
          const [filterName, parentCheckName] = operandPair;

          if (filter === filterName && result['operand'].hasOwnProperty(parentCheckName)) {
            delete result['operand'][parentCheckName];
          }
        });
      }
      return result;
    }
  }

  private getFieldOperator(field: string): string {
    const fieldOperatorMapping = {
      pf_globalAlleleFreqDerived: '<',
      pValue: '<=',
      lossCopyNumber: '<',
      gainCopyNumber: '>',
      lossVendorFoldChange: '<',
      gainVendorFoldChange: '>',
      qualityEnum: 'is',
      equivocal: 'is',
      vendorAnnotatedInFrame: 'is'
    };
    return fieldOperatorMapping[field] || '>=';
  }

  private formatPercentage(operand: number, decimalPlaces: number): string {
    return (operand * 100).toFixed(decimalPlaces).replace('.', this.commonService.decimalChar);
  }

  private formatApiToLocaleNumber(value: any): string {
    if (value === undefined || value === null) {
      return;
    }
    return CommonService.stringReplaceAll(value.toString(), [
      ['.', this.commonService.decimalChar]
    ]);
  }

  private initializeSingleFilter(type: string, filterGroup, filter, operand, checked): void {
    if (_isObject(operand)) {
      // Clone object to avoid operand[parentCheckName] assignment on saved filter
      operand = Object.assign({}, operand);
    }

    if (filter === 'pf_in' || filter === 'exac_in') {
      return;
    }
    const input = this.filterInputEle(type, filterGroup, filter.replace('exac_', 'pf_'));
    const check = this.filterCheckboxEle(type, filterGroup, filter.replace('exac_', 'pf_'));

    if (!input) {
      // In case of enum
      checkboxOperandPairs.forEach((operandPair) => {
        const [filterName, parentCheckName] = operandPair;

        if (filter === filterName && operand && Object.keys(operand).length > 0) {
          operand[parentCheckName] = true; // Set parent checkbox
        }
      });

      if (operand) {
        this.filterForm.get(type).get(filterGroup).get(filter).patchValue(operand);
      } else {
        this.filterForm.get(type).get(filterGroup).get(filter).reset();
      }
      return;
    }

    /**
     * Special case: Due to backward compatibility, need to format VAF and AlleleFreq fields to 2 decimal and 4 decimal respectively.
     * For the rest of the fields, it's simply a string value
     * TODO: Check if we can remove the special handling in future
     */
    this.formatVAFandAlleleFreqFields(operand, filter, input);

    check.setValue(checked);
  }

  private formatVAFandAlleleFreqFields(
    operand: number,
    filter: string,
    input: AbstractControl
  ): void {
    if ((filter === 'alleleFractionPercentage' || filter === 'VAF') && operand !== null) {
      input.setValue(this.formatPercentage(operand, 2));
    } else if (
      (filter === 'pf_globalAlleleFreqDerived' || filter === 'exac_globalAlleleFreqDerived') &&
      operand !== null
    ) {
      input.setValue(this.formatPercentage(operand, 4));
    } else {
      input.setValue(this.formatApiToLocaleNumber(operand));
    }
  }

  private checkNumericRange(field: string, min: number, max: number): ValidatorFn {
    const checkboxCtrlName = `${field}Check`,
      inputCtrlName = `${field}Input`;
    return (group: FormGroup) => {
      const check = group.controls[checkboxCtrlName];
      const input = group.controls[inputCtrlName];
      let error = null;

      if (check.value && input.value) {
        const val = this.commonService.parseFloatLocale(input.value);
        error = val < min || val > max ? { invalidValue: true } : null;
      }
      return error;
    };
  }

  private buildFormGroups(type: string): CtrlMap {
    let formGroups = {};
    switch (type) {
      case VARIANT_FILTER_TYPE.smallVariant:
        formGroups = this.getSmallVariantFormGroups();
        break;
      case VARIANT_FILTER_TYPE.geneFusion:
        formGroups = this.getFusionFormGroups();
        break;
      case VARIANT_FILTER_TYPE.nonGeneFusionRearrangement:
        formGroups = this.getNonFusionFormGroups();
        break;
      case VARIANT_FILTER_TYPE.tso500GeneFusion:
        formGroups = this.getTSO500GeneFusionFormGroups();
        break;
      case VARIANT_FILTER_TYPE.copyNumberVariant:
        formGroups = this.getCNVFormGroups();
        break;
      case VARIANT_FILTER_TYPE.rnaSpliceVariant:
        formGroups = this.getRNASpliceVariantFormGroups();
        break;
      default:
        throw Error(`Biomarker type ${type} is not supported by this filter.`);
    }

    if (!VariantFilterComponent.BLANK_FILTERS[type]) {
      const blankFilter = {};
      Object.keys(formGroups).forEach((key) => {
        blankFilter[key] = [];
      });
      VariantFilterComponent.BLANK_FILTERS[type] = blankFilter;
    }

    return formGroups;
  }

  private createStandardFilterFormGroup(
    field: string,
    validatorPattern?: RegExp,
    additionalValidators: Array<ValidatorFn> = []
  ): FormGroup {
    const checkboxFieldName = `${field}Check`;
    const inputFieldName = `${field}Input`;
    const validators = [...additionalValidators];
    if (validatorPattern) {
      validators.push(CheckBeforeInput(checkboxFieldName, inputFieldName, validatorPattern));
    }
    return this.fb.group(
      { [checkboxFieldName]: [false], [inputFieldName]: [null] },
      { validators: validators }
    );
  }

  private getSmallVariantFormGroups(): CtrlMap {
    return {
      somatic: this.fb.group({
        cosmic_globalSiteCount: this.createStandardFilterFormGroup(
          'cosmic_globalSiteCount',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('cosmic_globalSiteCount', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        cosmic_globalSomaticCount: this.createStandardFilterFormGroup(
          'cosmic_globalSomaticCount',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('cosmic_globalSomaticCount', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        tcga_globalCount: this.createStandardFilterFormGroup(
          'tcga_globalCount',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('tcga_globalCount', 0, this.MAX_FILTER_FIELD_VALUE)]
        )
      }),
      germline: this.fb.group({
        pf_globalAlleleFreqDerived: this.createStandardFilterFormGroup(
          'pf_globalAlleleFreqDerived',
          this.commonService.dynamicPatterns.PERCENT_4
        )
      }),
      quality: this.fb.group({
        VAF: this.createStandardFilterFormGroup(
          'VAF',
          this.commonService.dynamicPatterns.PERCENT_2
        ),
        RD: this.createStandardFilterFormGroup(
          'RD',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('RD', 0, this.MAX_FILTER_FIELD_VALUE)]
        )
      })
    };
  }

  private getCNVFormGroups(): CtrlMap {
    const ctrlMap = {
      somatic: this.fb.group({
        cosmic_globalSiteCount: this.createStandardFilterFormGroup(
          'cosmic_globalSiteCount',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('cosmic_globalSiteCount', 0, this.MAX_FILTER_FIELD_VALUE)]
        )
      }),
      copyNumber: this.fb.group({
        lossCopyNumber: this.createStandardFilterFormGroup(
          'lossCopyNumber',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('lossCopyNumber', 0.000001, 2)]
        ),
        gainCopyNumber: this.createStandardFilterFormGroup(
          'gainCopyNumber',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('gainCopyNumber', 2, this.MAX_FILTER_FIELD_VALUE)]
        ),
        lossVendorFoldChange: this.createStandardFilterFormGroup(
          'lossVendorFoldChange',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('lossVendorFoldChange', 0.000001, 1)]
        ),
        gainVendorFoldChange: this.createStandardFilterFormGroup(
          'gainVendorFoldChange',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('gainVendorFoldChange', 1, this.MAX_FILTER_FIELD_VALUE)]
        )
      }),
      quality: this.fb.group({
        qualityScore: this.createStandardFilterFormGroup(
          'qualityScore',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('qualityScore', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        pValue: this.createStandardFilterFormGroup('pValue', new RegExp('.+'), [
          this.checkNumericRange('pValue', 0, 1)
        ]),
        qualityEnum: this.fb.group({
          qualityEnumCheck: [false],
          HIGH_CONFIDENCE_CNV_CALL: [{ value: false, disabled: true }],
          LOW_CONFIDENCE_CNV_CALL: [{ value: false, disabled: true }]
        }),
        equivocal: this.fb.group({
          equivocalCheck: [false],
          TRUE_EQUIVOCAL: [{ value: false, disabled: true }],
          FALSE_EQUIVOCAL: [{ value: false, disabled: true }]
        })
      })
    };

    this.enableChildCheckboxes(<FormGroup>ctrlMap.quality.get('qualityEnum'), 'qualityEnumCheck', [
      'HIGH_CONFIDENCE_CNV_CALL',
      'LOW_CONFIDENCE_CNV_CALL'
    ]);
    this.enableChildCheckboxes(<FormGroup>ctrlMap.quality.get('equivocal'), 'equivocalCheck', [
      'TRUE_EQUIVOCAL',
      'FALSE_EQUIVOCAL'
    ]);

    this.removePipelineNotSupportedFields(VARIANT_FILTER_TYPE.copyNumberVariant, ctrlMap);

    return ctrlMap;
  }

  private getBaseFusionFormGroups(options: { includeReadingFrame: boolean }): CtrlMap {
    const ctrlMap = {
      somatic: this.fb.group({
        cosmic_globalSiteCount: this.createStandardFilterFormGroup(
          'cosmic_globalSiteCount',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('cosmic_globalSiteCount', 0, this.MAX_FILTER_FIELD_VALUE)]
        )
      }),

      quality: this.fb.group({
        qualityScore: this.createStandardFilterFormGroup(
          'qualityScore',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('qualityScore', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        pValue: this.createStandardFilterFormGroup('pValue', new RegExp('.+'), [
          this.checkNumericRange('pValue', 0, 1)
        ]),
        supportingReadPairs: this.createStandardFilterFormGroup(
          'supportingReadPairs',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('supportingReadPairs', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        alleleFractionPercentage: this.createStandardFilterFormGroup(
          'alleleFractionPercentage',
          this.commonService.dynamicPatterns.PERCENT_2
        )
      })
    };

    // Add reading frame related fields if required
    if (options.includeReadingFrame) {
      Object.assign(ctrlMap, {
        readRelated: this.fb.group({
          supportingReadsPercentage: this.createStandardFilterFormGroup(
            'supportingReadsPercentage',
            this.commonService.dynamicPatterns.PERCENT_2
          ),
          supportingReadsCount: this.createStandardFilterFormGroup(
            'supportingReadsCount',
            this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
            [this.checkNumericRange('supportingReadsCount', 0, this.MAX_FILTER_FIELD_VALUE)]
          )
        }),
        inFrame: this.fb.group({
          vendorAnnotatedInFrame: this.fb.group({
            vendorAnnotatedInFrameCheck: [false],
            IN_FRAME_TRUE: [{ value: false, disabled: true }],
            IN_FRAME_FALSE: [{ value: false, disabled: true }],
            IN_FRAME_UNKNOWN: [{ value: false, disabled: true }]
          })
        })
      });

      this.enableChildCheckboxes(
        <FormGroup>ctrlMap['inFrame'].get('vendorAnnotatedInFrame'),
        'vendorAnnotatedInFrameCheck',
        ['IN_FRAME_TRUE', 'IN_FRAME_FALSE', 'IN_FRAME_UNKNOWN']
      );
    }

    return ctrlMap;
  }

  private getFusionFormGroups(): CtrlMap {
    const ctrlMap = this.getBaseFusionFormGroups({ includeReadingFrame: true });
    this.removePipelineNotSupportedFields(VARIANT_FILTER_TYPE.geneFusion, ctrlMap);
    return ctrlMap;
  }

  private getTSO500GeneFusionFormGroups(): CtrlMap {
    const ctrlMap = this.getBaseFusionFormGroups({ includeReadingFrame: false });
    this.removePipelineNotSupportedFields(VARIANT_FILTER_TYPE.tso500GeneFusion, ctrlMap);
    return ctrlMap;
  }

  private getNonFusionFormGroups(): CtrlMap {
    const ctrlMap = {
      quality: this.fb.group({
        qualityScore: this.createStandardFilterFormGroup(
          'qualityScore',
          this.commonService.dynamicPatterns.decimalPlaces(6),
          [this.checkNumericRange('qualityScore', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        pValue: this.createStandardFilterFormGroup('pValue', new RegExp('.+'), [
          this.checkNumericRange('pValue', 0, 1)
        ]),
        supportingReadPairs: this.createStandardFilterFormGroup(
          'supportingReadPairs',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('supportingReadPairs', 0, this.MAX_FILTER_FIELD_VALUE)]
        ),
        alleleFractionPercentage: this.createStandardFilterFormGroup(
          'alleleFractionPercentage',
          this.commonService.dynamicPatterns.PERCENT_2
        )
      }),
      inFrame: this.fb.group({
        vendorAnnotatedInFrame: this.fb.group({
          vendorAnnotatedInFrameCheck: [false],
          IN_FRAME_TRUE: [{ value: false, disabled: true }],
          IN_FRAME_FALSE: [{ value: false, disabled: true }],
          IN_FRAME_UNKNOWN: [{ value: false, disabled: true }]
        })
      })
    };

    this.enableChildCheckboxes(
      <FormGroup>ctrlMap.inFrame.get('vendorAnnotatedInFrame'),
      'vendorAnnotatedInFrameCheck',
      ['IN_FRAME_TRUE', 'IN_FRAME_FALSE', 'IN_FRAME_UNKNOWN']
    );
    this.removePipelineNotSupportedFields(VARIANT_FILTER_TYPE.nonGeneFusionRearrangement, ctrlMap);
    return ctrlMap;
  }

  private getRNASpliceVariantFormGroups(): CtrlMap {
    const ctrlMap = {
      quality: this.fb.group({
        supportingReadPairs: this.createStandardFilterFormGroup(
          'supportingReadPairs',
          this.commonService.dynamicPatterns.INTEGER_NON_NEGATIVE_LOCALE,
          [this.checkNumericRange('supportingReadPairs', 0, this.MAX_FILTER_FIELD_VALUE)]
        )
      })
    };
    this.removePipelineNotSupportedFields(VARIANT_FILTER_TYPE.rnaSpliceVariant, ctrlMap);
    return ctrlMap;
  }

  private removePipelineNotSupportedFields(type: string, ctrlMap): void {
    const groups = this.filterablePropGroups[type];
    // Remove the fields which are not supported by the secondary pipeline
    Object.entries(ctrlMap).forEach(([groupName, fbGroup]: [string, FormGroup]) => {
      if (!groups[groupName]) {
        delete ctrlMap[groupName];
      } else {
        Object.keys(fbGroup.controls).forEach((ctrlKey) => {
          if (!groups[groupName].includes(ctrlKey)) {
            fbGroup.removeControl(ctrlKey);
          }
        });
      }
    });
  }

  private filterInputEle(type: string, formGroupName: string, field: string): AbstractControl {
    /**
     * "Ideally" it should not throw exception.
     * But, as the pipeline file formats are dynamic, we have seen in the past that some file formats were throwing the error.
     * Hence, having this try/catch block will help us identify quickly which field is missing or out of the list by logging their value.
     * TODO: Remove once all the formats are integrated.
     */
    try {
      return this.filterForm
        .get(type)
        .get(formGroupName)
        .get(field)
        .get(field + 'Input');
    } catch (error) {
      throw Error(`Missing input control for ${type} > ${formGroupName} > ${field}: ${error}`);
    }
  }

  private filterCheckboxEle(type: string, formGroupName: string, field: string): AbstractControl {
    /**
     * "Ideally" it should not throw exception.
     * But, as the pipeline file formats are dynamic, we have seen in the past that some file formats were throwing the error.
     * Hence, having this try/catch block will help us identify quickly which field is missing or out of the list by logging their value.
     * TODO: Remove once all the formats are integrated.
     */
    try {
      const formGrp = this.filterForm.get(type).get(formGroupName);
      if (!formGrp || !formGrp.get(field)) {
        return;
      }

      return formGrp.get(field).get(field + 'Check');
    } catch (error) {
      throw Error(`Missing checkbox control for ${type} > ${formGroupName} > ${field}: ${error}`);
    }
  }

  getFilterByType(biomarkerType: string): FilterInputData {
    return this.filtersList.find((f) => f.type === biomarkerType);
  }

  // Enable/Disable child checkboxes depending on parent checkbox value
  private enableChildCheckboxes(
    formGrp: FormGroup,
    parentName: string,
    childNames: string[]
  ): void {
    formGrp
      .get(parentName)
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isSelected) => {
        const action = isSelected ? 'enable' : 'disable';
        childNames.forEach((ctrlName) => formGrp.get(ctrlName)[action]());
      });
  }
}
