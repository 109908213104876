import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { of as observableOf, BehaviorSubject } from 'rxjs';
import { finalize, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sortBy as _sortBy } from 'lodash';

import { AuthService } from 'app/services/auth.service';
import { ListItem } from 'app/model/entities/baseEntity';

@Injectable()
export class CountriesService {
  private _statesByCountry = {};
  private _countries = [];
  private _loadingCountries = new BehaviorSubject<boolean>(false);
  private _states = new BehaviorSubject<ListItem[]>([]);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get loadingCountries() {
    return this._loadingCountries.asObservable();
  }

  get states() {
    return this._states.asObservable();
  }

  loadCountries() {
    if (this._countries && this._countries.length > 0) {
      return observableOf(this._countries);
    }
    this._loadingCountries.next(true);
    return this.http.get<any>(this.authService.getURL(SessionLinkKeys.LIST_COUNTRIES)).pipe(
      map((json: any) => _sortBy(json, 'value')),
      tap((json: []) => (this._countries = json)),
      finalize(() => this._loadingCountries.next(false))
    );
  }

  loadStates(countryId) {
    if (this._statesByCountry[countryId]) {
      return observableOf(this._statesByCountry[countryId]);
    }
    return this.http
      .get<any>(this.authService.getURL(SessionLinkKeys.LIST_STATES_FOR_COUNTRY, { countryId }))
      .pipe(
        map((json: any) => _sortBy(json, 'value')),
        tap((json: []) => (this._statesByCountry[countryId] = json))
      );
  }
}
