import { BaseEntity } from './baseEntity';
export class ClinvarAnnotation extends BaseEntity {
  variationIds: number[];
  reviewRating: number;
  clinSig: string[];
  originSimple: string[];
  numSubmissions: number;
  phenotypes: string[];

  constructor(jsonData: any) {
    super(jsonData);
  }
}
