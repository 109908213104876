import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ClinicalRegion, DEFAULT_CLINICAL_REGIONS } from '../model/entities/clinical-region';

@Injectable()
export class ClinicalRegionsService {
  // This emits an array if we know the list, and null if we have no info about the list.
  private _clinicalRegions = new BehaviorSubject<ClinicalRegion[] | null>(null);
  private _clinicalRegionsLoading = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get clinicalRegions() {
    return this._clinicalRegions.asObservable();
  }

  get clinicalRegionsLoading() {
    return this._clinicalRegionsLoading.asObservable();
  }

  loadClinicalRegions(version: string) {
    if (!version) {
      this._clinicalRegions.next(null); // tell subscribers we don't know what regions are valid
    } else {
      this._clinicalRegionsLoading.next(true);
      return this.http
        .get<any>(this.authService.getURL('rocheContent', { version }))
        .pipe(finalize(() => this._clinicalRegionsLoading.next(false)))
        .subscribe((json) => {
          this._clinicalRegions.next(
            json.clinicalRegions.length === 0
              ? DEFAULT_CLINICAL_REGIONS.clinicalRegions
              : (json.clinicalRegions as ClinicalRegion[])
          );
        });
    }
  }
}
