import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFilterInput]'
})
export class FilterInputDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const labelEl = this.el.nativeElement.querySelector('label');
    this.el.nativeElement.setAttribute('label', labelEl.innerText);
  }
}
