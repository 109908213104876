import { Directive, Host, HostListener } from '@angular/core';
import { GenericOwcMenuComponent } from 'app/shared/generic-owc-menu/generic-owc-menu.component';

/**
 * Closes an OWC menu item automatically after click; using parent - GenericOwcMenuComponent's `menuVisible` property.
 * Ref:
 *  https://overflow.roche.com/t/owc-menu-queries/13498
 *  https://github.com/angular/angular/issues/8277#issuecomment-323678013
 */
@Directive({
  selector: '[appAutoCloseMenu]'
})
export class AutoCloseMenuDirective {
  parentGenericMenu: GenericOwcMenuComponent;
  constructor(@Host() genericMenu: GenericOwcMenuComponent) {
    this.parentGenericMenu = genericMenu;
  }

  @HostListener('click')
  onClick(): void {
    this.parentGenericMenu.menuVisible = false;
  }
}
