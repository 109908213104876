import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format-pipe';
import { LocaleNumberPipe } from './locale-number.pipe';
import { TransformToThirtyBasePairsPipe } from './transform-thirty-base-pairs/transform-thirty-base-pairs.pipe';
import { UserFullnamePipe } from './user-fullname-pipe';
import { RuoIvdMapperPipe } from './ruo-ivd-mapper.pipe';
import { TruncateTextPipe } from './truncate-text/truncate-text.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    LocaleNumberPipe,
    UserFullnamePipe,
    TransformToThirtyBasePairsPipe,
    TruncateTextPipe,
    RuoIvdMapperPipe
  ],
  exports: [
    DateFormatPipe,
    LocaleNumberPipe,
    UserFullnamePipe,
    TransformToThirtyBasePairsPipe,
    TruncateTextPipe,
    RuoIvdMapperPipe
  ]
})
export class PipeModule {}
