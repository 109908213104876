import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-combination-tag',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div class="combination-tag">{{ count }}</div> `,
  styleUrls: ['./combination-tag.component.scss']
})
export class CombinationTagComponent {
  @Input() count: number;
}
