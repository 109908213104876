import { Input, Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Toast } from 'angular2-toaster';
import { DecimalPlace, ThousandSeparator } from 'app/model/entities/appSettingsValues';
import { GenericActions } from 'app/model/valueObjects/genericActions';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-translation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  toast: Toast;
  data: any = {};
  @Input() translationKey: string;
  DecimalPlace = DecimalPlace;
  ThousandSeparator = ThousandSeparator;
  GenericActions = GenericActions;
  showRuo$: Observable<boolean> = this.authService.currentUserCanRuo;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.toast) {
      this.translationKey = this.toast.data.translationKey;
      this.data = this.toast.data.translationData;
    }
  }
}
