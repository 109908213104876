import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs/operators';
import { ListItem } from '../model/entities/baseEntity';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class BiomarkersService {
  private _biomarkersByVersionThenTranscriptome = {};
  private _biomarkers = new BehaviorSubject<ListItem[]>([]);
  private _loadingBiomarkers = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get biomarkers() {
    return this._biomarkers.asObservable();
  }
  get loadingBiomarkers() {
    return this._loadingBiomarkers.asObservable();
  }

  loadBiomarkers(contentVersion: string, transcriptome: string) {
    if (!contentVersion || !transcriptome) {
      this._biomarkers.next([]);
    } else if (
      this._biomarkersByVersionThenTranscriptome[contentVersion] &&
      this._biomarkersByVersionThenTranscriptome[contentVersion][transcriptome]
    ) {
      this._biomarkers.next(
        this._biomarkersByVersionThenTranscriptome[contentVersion][transcriptome]
      );
    } else {
      this._loadingBiomarkers.next(true);
      return this.http
        .get<any>(
          this.authService.getURL(SessionLinkKeys.LIST_BIOMARKERS, {
            contentVersion,
            transcriptome: transcriptome.toLowerCase()
          })
        )
        .pipe(finalize(() => this._loadingBiomarkers.next(false)))
        .subscribe((json) => {
          if (!this._biomarkersByVersionThenTranscriptome[contentVersion]) {
            this._biomarkersByVersionThenTranscriptome[contentVersion] = {};
          }
          this._biomarkersByVersionThenTranscriptome[contentVersion][transcriptome] = json;
          this._biomarkers.next(
            this._biomarkersByVersionThenTranscriptome[contentVersion][transcriptome]
          );
        });
    }
  }
}
