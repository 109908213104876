<div [style.width.px]="barWidth*barNum" [style.height.px]="height" class="style-{{style}} chart-numbers-{{barNum}}">
  <ng-container *ngFor="let i of getBars()">
    <div id="bar-{{i}}"
      [ngClass]="getClass(i)"
      [style.grid-row-start]="getStart(i)"
      [style.grid-row-end] = "101">
    </div>
    <owc-tooltip anchor="bar-{{i}}">
      {{getDate(i)}} : {{numbers[i]|localeNumber: '0,0'}}
    </owc-tooltip>
  </ng-container>
</div>
