// Ref: https://stackoverflow.com/questions/47593692/how-to-translate-mat-paginator-in-angular-4/60127007#60127007
import { MatPaginatorIntl } from '@angular/material/paginator';

const matRangeLabelIntl = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 ${$localize`:@@paginator.rangeOfLabel:of`} ${length}`; // This needs to be replicated in translation.component.html as well, so that it's included in generated XLF.
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1}-${endIndex} ${$localize`:@@paginator.rangeOfLabel:of`} ${length}`; // This needs to be replicated in translation.component.html as well, so that it's included in generated XLF.
};

export function i18nMatPaginator() {
  const paginatorIntl = new MatPaginatorIntl();

  // These needs to be replicated in translation.component.html as well, so that it's included in generated XLF.
  paginatorIntl.itemsPerPageLabel = $localize`:@@paginator.displayPerPage:Items per page`;
  paginatorIntl.nextPageLabel = $localize`:@@paginator.nextPage:Next page`;
  paginatorIntl.previousPageLabel = $localize`:@@paginator.prevPage:Prev page`;
  paginatorIntl.getRangeLabel = matRangeLabelIntl;

  return paginatorIntl;
}
