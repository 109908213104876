import { Injectable } from '@angular/core';
import {
  ApprovalBodiesWithDiagnoses,
  Therapy,
  AssociatedTherapyDisplay
} from 'app/model/entities/therapy';
import { CaseService } from './case.service';
import {
  isEqual as _isEqual,
  uniq as _uniq,
  cloneDeep as _cloneDeep,
  uniqWith as _uniqWith
} from 'lodash';
import { VariantTherapiesData } from 'app/model/entities/variantTherapiesData';

@Injectable()
export class TherapyProcessorService {
  private associatedTherapies: Array<AssociatedTherapyDisplay> = []; // For Tier I-B/II-C/II-D

  constructor(private caseService: CaseService) {}

  private groupEntriesById(
    entriesWithSameID: Therapy[],
    therapyDisplay: AssociatedTherapyDisplay
  ): void {
    // Find out duplicate approval bodies across all entries with same ID.
    const approvalBodies = new Set<string>(),
      duplicateBodies = new Set<string>();
    entriesWithSameID.forEach((entry) => {
      entry.approvals.forEach((approvalBody) => {
        if (!approvalBodies.has(approvalBody)) {
          approvalBodies.add(approvalBody);
        } else {
          duplicateBodies.add(approvalBody);
        }
      });
    });

    // Assign single approval body for multiple diagnoses, for ex: FDA for disease1, disease2; NCCN for disease1, disease2.
    duplicateBodies.forEach((bodyName) =>
      this.assignApprovalBodyWithMultipleDiagnosis(entriesWithSameID, bodyName, therapyDisplay)
    );

    // Group approval bodies for same diseases. For ex: "FDA for disease1, disease2", "NCCN for disease1, disease2" => "FDA, NCCN for disease1, disease2"
    TherapyProcessorService.groupSameDiagnosesEntries(therapyDisplay);

    // Assign multiple approval bodies for single diagnosis, for ex: ESMO, NCCN for disease1
    entriesWithSameID.forEach((entry) =>
      this.assignApprovalBodiesWithSingleDiagnosis(entry, therapyDisplay)
    );

    // Once grouped, push to the final list.
    this.associatedTherapies.push(therapyDisplay);
  }

  /**
   * Groups same diagnosis entries into single one.
   *  For ex: "FDA for disease1, disease2", "NCCN for disease1, disease2" => "FDA, NCCN for disease1, disease2"
   */
  static groupSameDiagnosesEntries(therapyDisplay: AssociatedTherapyDisplay): void {
    const duplicateEntryMap = {};

    therapyDisplay.approvalBodiesWithDiagnoses.forEach((entry, index) => {
      const approvalBodies = [...entry.approvals];

      // Find entry with same diseases and combine the approval bodies
      therapyDisplay.approvalBodiesWithDiagnoses
        .filter((item, i) => {
          const isDuplicate = _isEqual(entry.diagnosisNames, item.diagnosisNames) && index !== i;
          if (isDuplicate) {
            duplicateEntryMap[item.approvals.join(',')] = true;
          }
          return isDuplicate;
        })
        .forEach((item) => approvalBodies.push(...item.approvals));

      entry.approvals = _uniq(approvalBodies);
    });

    therapyDisplay.approvalBodiesWithDiagnoses = therapyDisplay.approvalBodiesWithDiagnoses.filter(
      (entry) => !duplicateEntryMap[entry.approvals.join(',')]
    );
  }

  private assignApprovalBodiesWithSingleDiagnosis(
    entry: Therapy,
    therapyDisplay: AssociatedTherapyDisplay
  ): void {
    if (entry.approvals.length === 0) {
      return;
    }
    const { diagnosisId, approvals } = entry;
    therapyDisplay.approvalBodiesWithDiagnoses.push({
      diagnosisIds: [diagnosisId],
      diagnosisNames: [this.caseService.getDiagnosisName(diagnosisId)],
      approvals
    });
  }

  private assignApprovalBodyWithMultipleDiagnosis(
    entriesWithSameId: Therapy[],
    bodyName: string,
    therapyDisplay: AssociatedTherapyDisplay
  ): void {
    const approvalBodyWithMultipleDiagnosis: ApprovalBodiesWithDiagnoses = {
      approvals: [],
      diagnosisIds: [],
      diagnosisNames: []
    };

    entriesWithSameId.forEach((entry) => {
      const index = entry.approvals.indexOf(bodyName);
      if (index !== -1) {
        approvalBodyWithMultipleDiagnosis.approvals = [bodyName];
        if (!approvalBodyWithMultipleDiagnosis.diagnosisIds.includes(entry.diagnosisId)) {
          approvalBodyWithMultipleDiagnosis.diagnosisIds.push(entry.diagnosisId);
          approvalBodyWithMultipleDiagnosis.diagnosisNames.push(
            this.caseService.getDiagnosisName(entry.diagnosisId)
          );
        }
        entry.approvals.splice(index, 1);
      }
    });

    approvalBodyWithMultipleDiagnosis.diagnosisNames.sort((a, b) => a.localeCompare(b));

    // Assign approval body for multiple diagnosis
    therapyDisplay.approvalBodiesWithDiagnoses.push(approvalBodyWithMultipleDiagnosis);
  }

  private prepareSingleEntryTherapyDisplay(
    therapy: Therapy,
    therapyDisplay: AssociatedTherapyDisplay
  ): void {
    const { diagnosisId, approvals } = therapy;
    therapyDisplay.approvalBodiesWithDiagnoses.push({
      diagnosisIds: [diagnosisId],
      diagnosisNames: [this.caseService.getDiagnosisName(diagnosisId)],
      approvals
    });
    this.associatedTherapies.push(therapyDisplay);
  }

  private createDisplayTherapy(therapy: Therapy): AssociatedTherapyDisplay {
    const therapyDisplay = new AssociatedTherapyDisplay();
    therapyDisplay.id = therapy.id;
    therapyDisplay.names = therapy.names;
    therapyDisplay.type = therapy.type;
    return therapyDisplay;
  }

  buildDisplayForAssociatedTherapies(
    otherIndicationTherapies: Therapy[]
  ): Array<AssociatedTherapyDisplay> {
    // Reset the array before processing.
    this.associatedTherapies = [];

    otherIndicationTherapies.forEach((therapy) => {
      // If already processed then skip.
      if (
        this.associatedTherapies.find((associatedTherapy) => associatedTherapy.id === therapy.id)
      ) {
        return;
      }

      // Create a display object.
      const therapyDisplay = this.createDisplayTherapy(therapy);

      // Find all the entries for same ID
      const entriesWithSameID = otherIndicationTherapies.filter(
        (otherIndicationTherapy) => otherIndicationTherapy.id === therapy.id
      );

      // If any of the entry is included in report, then include the same in report.
      therapyDisplay.includeInReport = entriesWithSameID.some((entry) => entry.includeInReport);

      // Multiple entries, then group them
      if (entriesWithSameID.length > 1) {
        this.groupEntriesById(entriesWithSameID, therapyDisplay);
      } else {
        // Single entry, then simple assign display properties.
        this.prepareSingleEntryTherapyDisplay(therapy, therapyDisplay);
      }
    });

    return this.associatedTherapies;
  }

  // getFilteredSummaryRows function filters out the duplicated therapies
  static getFilteredSummaryRows(summaryRows: VariantTherapiesData[]): VariantTherapiesData[] {
    return summaryRows.map((summaryRow: VariantTherapiesData) => {
      const cloneSummaryRow = _cloneDeep(summaryRow);

      const filteredApprovedTherapiesInReportForCurrentDiagnosis = _uniqWith(
        cloneSummaryRow.approvedTherapiesInReportForCurrentDiagnosis,
        (currVal, othVal) => currVal.id === othVal.id
      );

      const filteredApprovedTherapiesInReportForOtherIndications = _uniqWith(
        cloneSummaryRow.approvedTherapiesInReportForOtherIndications,
        (currVal, othVal) => currVal.id === othVal.id
      );

      return {
        ...cloneSummaryRow,
        approvedTherapiesInReportForCurrentDiagnosis:
          filteredApprovedTherapiesInReportForCurrentDiagnosis,
        approvedTherapiesInReportForOtherIndications:
          filteredApprovedTherapiesInReportForOtherIndications
      };
    });
  }
}
