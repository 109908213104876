import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

import { PopupWindowComponent } from './popup-window.component';
import { DialogService } from 'app/services/dialog.service';
import { Dialog } from 'app/model/entities/dialog';

@Component({
  selector: 'app-dialog',
  template: ''
})
export class DialogComponent implements OnInit {
  options;
  dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog, private dialogService: DialogService) {}

  ngOnInit() {
    this.dialogService.dialog.subscribe((json) => {
      const dialog = new Dialog(json);
      if (dialog.type && !this.dialogRef) {
        return this.openDialog(dialog);
      }
    });

    this.dialogService.customDialog.pipe(filter((config) => !!config)).subscribe((config) => {
      const dialog = new Dialog(config);
      this.openCustomDialog(dialog);
    });
  }

  openDialog(dialog: Dialog) {
    const size = this.adjustSize(dialog);
    this.dialogRef = this.dialog.open(PopupWindowComponent, size);
    this.dialogRef.componentInstance.popupCode = dialog.type;
    this.dialogRef.componentInstance.data = dialog.data;
    this.dialogRef.componentInstance.negativeButton = dialog.negativeButton;
    this.dialogRef.componentInstance.positiveButton = dialog.positiveButton;
    this.dialogRef.componentInstance.positiveButtonPrimary = dialog.positiveButtonPrimary;
    this.dialogRef.afterClosed().subscribe((closeResult) => {
      this.dialogService.setDialogResult(closeResult);
      this.dialogRef = null;
    });
  }

  openCustomDialog(dialog: Dialog) {
    const size = this.adjustSize(dialog);
    this.dialogRef = this.dialog.open(dialog.componentToRender, size);
    this.dialogRef.componentInstance.type = dialog.type;
    this.dialogRef.componentInstance.data = dialog.data;
    this.dialogRef.afterClosed().subscribe((results) => {
      this.dialogService.setCustomDialogResult(results);
      this.dialogRef = null;
    });
  }

  adjustSize(dialog: Dialog) {
    const size = {
      width: dialog.width || '450px',
      height: dialog.height || '300px' // Must specify height for Safari compatibility
    };

    if (dialog.maxHeight || dialog.minHeight) {
      size.height = null;
      size['maxHeight'] = dialog.maxHeight;
      size['minHeight'] = dialog.minHeight;
    }

    if (dialog.maxWidth || dialog.minWidth) {
      size.width = null;
      size['maxWidth'] = dialog.maxWidth;
      size['minWidth'] = dialog.minWidth;
    }
    return size;
  }
}
