import { Component, Input } from '@angular/core';
import { LastUpdated } from 'app/model/valueObjects/lastUpdatedEnum';

@Component({
  selector: 'app-last-updated',
  templateUrl: './last-updated.component.html'
})
export class LastUpdatedComponent {
  @Input() value: LastUpdated;
  lastUpdated = LastUpdated;
}
