import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html'
})
/**
 * Component for date input and it's validations.
 * @description:
 * Here is the algorithm applied here:
 *    1) Get the user Input
 *    2) Validate the input format agaist configured format for the locale. Here is the list of valid formats for let's say "MM/DD/YYYY" format:
 *              - MM/DD/YYYY
 *              - M/D/YYYY
 *              - MM-DD-YYYY
 *              - M-D-YYYY
 *              - MMDDYYYY
 *    3) If format is valid, then check if date is valid or not. For Ex: 02/31/2017 is invalid.
 *    4) If date is valid then see if "futureDateValidation" flag is ON. If yes, validate if date is not future date.
 *          For ex: Date of birth can't be future date.
 *    5) If date is valid then see if "pastDateValidation" flag is ON. If yes, validate if date is not past date.
 *    6) If date passed all previous validations, then "onBlur" reformat the date to expected one.
 *       For ex:
 *            01-01-2017 =>  01/01/2017
 *            1-1-2017 =>  01/01/2017
 *            01012017 =>  01/01/2017
 * @param:
 *    - format: Format to validate against: 1. MM/DD/YYYY  2. DD/MM/YYYY  3. YYYY-MM-DD
 *    - placeholder: Text to be displayed as placeholder
 *    - i18nPlaceholder: i18n configurations in "meaning|description@@ID" format
 *    - dateControl: Angular reactive formcontrol for this input
 *    - futureDateValidation: Boolean flag indicating if "future date" validation should be tunred on.
 *    - pastDateValidation: Boolean flag indicating if "past date" validation should be tunred on.
 * @example:
 *  <date-input format="LL/dd/yyyy"
             placeholder="Date of birth"
             i18nPlaceholder="Date of birth for patient|Placeholder indicating this field is related to patient date of birth@@date-of-birth"
             [dateControl]="dateOfBirthControl"
             [futureDateValidation]="true"
             [pastDateValidation]="false"></date-input>
 */
export class DateInputComponent implements OnInit {
  @Input() format: string; // Date format to validate against. This will vary based on locale.
  @Input() placeholder: string; // Placeholder to be displayed on the control
  @Input() i18nPlaceholder: string; // i18n configuration for placeholder string
  @Input() dateControl: FormControl; // Angular reactive formcontrol for this input
  @Input() futureDateValidation: boolean; // Flag to validate if date is future date.
  @Input() pastDateValidation: boolean; // Flag to validate if date is past date.
  formatDisplay: string;

  private separators = ['/', '-'];
  // Pattern keys should correspond to luxon format. Eg. LL/dd/yyyy
  private patterns = {
    dd: '(0?[1-9]|[12]\\d|30|31)',
    LL: '(0?[1-9]|1[0-2])',
    yyyy: '(\\d{4})',
    NUMBERS_ONLY: '([0-9]{8})'
  };

  ngOnInit(): void {
    this.formatDisplay = this.format.toUpperCase().replace('LL', 'MM'); // Use MM for month display
    if (this.dateControl.validator) {
      this.dateControl.setValidators([this.validateDate.bind(this), this.dateControl.validator]);
    } else {
      this.dateControl.setValidators([this.validateDate.bind(this)]);
    }
  }

  onBlur(event): void {
    if (event.target.value && this.dateControl.valid) {
      const parsedDate = DateTime.fromFormat(event.target.value, this.format);
      this.dateControl.setValue(parsedDate.toFormat(this.format));
    }
  }

  private isValidFormat(value: string): boolean {
    // Identify the separator from user input (For ex: "-") and configured format (For ex: "/").
    const valueSeparator = this.separators.find((s) => value.includes(s)),
      formatSeparator = this.separators.find((s) => this.format.includes(s));
    let formatComponents = [];
    // Split the format into the components like "dd", "LL" and "yyyy"
    if (formatSeparator) {
      formatComponents = this.format.split(formatSeparator);
    }

    /**
     * If user has entered date with separator like dd-LL-yyyy then build the RegEx with user entered separator like '-'.
     * If not, then build the RegEx using separator from the format like '/'.
     */
    const regEx = this.buildRegExp(formatComponents, valueSeparator || formatSeparator);

    // In case of input like "dd-LL-yyyy"
    if (valueSeparator) {
      return new RegExp(regEx).test(value);
    } else if (new RegExp(this.patterns.NUMBERS_ONLY).test(value)) {
      // In case of input like ddLLyyyy, check for numeric values
      const formattedValue = DateTime.fromFormat(value, this.format).toFormat(this.format);
      return new RegExp(regEx).test(formattedValue);
    }
  }

  private buildRegExp(formatComponents, separator): string {
    let regEx = '';
    if (Array.isArray(formatComponents)) {
      /* Here c could be "dd", "LL" or "yyyy".
       * Build the RegExp based on the order of components like first "LL" and then "dd" and then "yyyy"
       */
      regEx = formatComponents.map((c) => this.patterns[c]).join(separator);
    }
    // Wrap it with the boundry tag
    return '\\b' + regEx + '\\b';
  }

  private validateDate(ctrl: FormControl): ValidationErrors | null {
    if (!ctrl.value) {
      return null;
    }
    if (!this.isValidFormat(ctrl.value)) {
      return {
        pattern: true
      };
    }
    return this.isValidDate(ctrl.value);
  }

  private isFutureDate(validDate: DateTime): boolean {
    return validDate > DateTime.now();
  }

  private isPastDate(validDate: DateTime): boolean {
    return validDate < DateTime.now();
  }

  private isValidDate(value: string): ValidationErrors | null {
    if (!value) {
      return null;
    }
    const parsedDate = DateTime.fromFormat(value, this.format);

    if (!parsedDate.isValid) {
      return {
        invalidDate: true
      };
    }

    if (this.futureDateValidation && this.isFutureDate(parsedDate)) {
      return { futureDateValidation: true };
    }

    if (this.pastDateValidation && this.isPastDate(parsedDate)) {
      return { pastDateValidation: true };
    }

    return null;
  }
}
