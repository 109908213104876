import { Component, Input } from '@angular/core';

export enum ErrorBarType {
  info,
  warning,
  error // Default
}

// TODO: OAK migration: Evaluate the need for this component once https://code.roche.com/onedesign/angular-kit/-/issues/275 is resolved.
@Component({
  selector: 'app-error-bar',
  templateUrl: './error-bar.component.html',
  styleUrls: ['./error-bar.component.scss']
})
export class ErrorBarComponent {
  @Input() type: ErrorBarType = ErrorBarType.error;
  errorBarType = ErrorBarType;
}
