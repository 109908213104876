export class AnnotationDisease {
  name: string;
  diseaseCount: number;
  mutationCount: number;

  constructor(name: string, diseaseCount: number, mutationCount: number) {
    this.name = name;
    this.diseaseCount = diseaseCount;
    this.mutationCount = mutationCount;
  }

  get percentage() {
    return this.diseaseCount ? (this.mutationCount / this.diseaseCount) * 100 : 0;
  }

  get size(): string {
    let size = 'small';
    if (this.diseaseCount >= 1000) {
      size = 'medium';
    }
    if (this.diseaseCount >= 10000) {
      size = 'large';
    }
    return size;
  }
}
