import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';

import { AuthService } from './services/auth.service';

// Reference: https://angular.io/guide/router#custom-preloading-strategy
@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  constructor(private authService: AuthService) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.authService.isAuthenticated ? load() : observableOf(null);
  }
}
