import { Component, Input, ChangeDetectionStrategy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_NAME, SECONDARY_APP_NAME } from 'app/services/common.service';

@Component({
  selector: 'app-name',
  template: '<span [outerHTML]="appName"></span>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppNameComponent {
  @Input() therapyMatcher = false;
  HTML_APP_NAME: string;
  HTML_SECONDARY_APP_NAME: string;

  constructor(private sanitizer: DomSanitizer) {
    this.HTML_APP_NAME = this.replaceRegSymbol(APP_NAME);
    this.HTML_SECONDARY_APP_NAME = this.replaceRegSymbol(SECONDARY_APP_NAME);
  }

  replaceRegSymbol(appName: string): string {
    return this.sanitizer.sanitize(
      SecurityContext.HTML,
      appName.replace('®', '<span class="registered-symbol">&reg;</span>')
    );
  }

  get appName(): string {
    return this.therapyMatcher ? this.HTML_SECONDARY_APP_NAME : this.HTML_APP_NAME;
  }
}
