<app-autocomplete-input
  placeholder="Country"
  i18n-placeholder="CountryInput.AutocompleteInput.Placeholder@@country"
  [autocompleteControl]="countryControl"
  [options]="countries">
</app-autocomplete-input>
<app-autocomplete-input
  *ngIf="showState"
  placeholder="State"
  i18n-placeholder="CountryInput.AutocompleteInput.Placeholder@@state"
  [autocompleteControl]="stateControl"
  [options]="states">
</app-autocomplete-input>
