import { forkJoin as observableForkJoin, Observable, BehaviorSubject, of, from } from 'rxjs';
import { tap, map, mergeMap, finalize, retry, catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpProgressEvent } from '@angular/common/http';
import {
  AbortMultipartUploadCommandOutput,
  CompleteMultipartUploadCommandOutput,
  S3,
  Tag
} from '@aws-sdk/client-s3';
import { Progress, Upload } from '@aws-sdk/lib-storage';
import { get as _get } from 'lodash';

import { FileToUpload } from 'app/model/entities/fileToUpload';
import { CaseFileUploadStatus, CaseUploadItem } from 'app/model/entities/case';
import { ParallelHasher } from 'ts-md5';

interface CasesUploading {
  [caseId: string]: CaseFileUploadStatus;
}
interface EndpointConfig {
  protocol: string;
  hostname: string;
  path: string;
}

interface CredentialsType {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

interface S3ConstructorArg {
  region: string;
  credentials: CredentialsType;
  endpoint?: EndpointConfig;
  forcePathStyle?: boolean;
}

interface S3ClientConfig {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  region: string;
  bucketName: string;
  endpoint?: string;
  tags?: Tag[];
}

@Injectable()
export class FileUploadService {
  private _uploadPendingCases = new BehaviorSubject<CasesUploading>({});

  constructor(private http: HttpClient) {}

  // Determines if there is currently any upload that is pending
  get uploadPending(): Observable<boolean> {
    return this._uploadPendingCases
      .asObservable()
      .pipe(
        map((casesUploading) =>
          Object.keys(casesUploading).some((caseId) => casesUploading[caseId].uploadPending)
        )
      );
  }

  get uploadPendingCases(): Observable<CasesUploading> {
    return this._uploadPendingCases.asObservable();
  }

  // Returns an observable which resolves to a string of the Base 64 encoded MD5 hash
  static generateMD5(file: File): Observable<string> {
    const workerFileName: string = '/assets/workers/md5_worker.js';
    const md5Worker: ParallelHasher = new ParallelHasher(workerFileName);
    return from(md5Worker.hash(file)).pipe(
      take(1),
      map((hex) => Buffer.from(hex.toString(), 'hex').toString('base64')),
      finalize(() => md5Worker.terminate())
    );
  }

  uploadFile(url: string, files: Array<FileToUpload>, caseId: string, editMode: boolean) {
    const caseFileUploadStatus: CaseFileUploadStatus = {
      editMode,
      uploadPending: true,
      uploads: files.map((file) => ({
        file,
        percentDone: new BehaviorSubject<number>(0)
      }))
    };

    this._uploadPendingCases.next(
      Object.assign({}, this._uploadPendingCases.value, {
        [caseId]: caseFileUploadStatus
      })
    );
    this.setMD5(files)
      .pipe(
        mergeMap(() => this.getFileUploadUrl(url, files)),
        mergeMap((response: any) => {
          return observableForkJoin([
            this.reportUploadStart(response['_links'].beginUpload.href),
            response['s3ClientConfig']
              ? this.startUploadUsingAwsSdk(files, response['s3ClientConfig'], caseId)
              : this.startUpload(files, caseId)
          ]);
        }),
        mergeMap((responses: any) => {
          const [beginUploadResponse] = responses;
          return this.changeStatus(beginUploadResponse['_links'].signalUpload.href, files);
        }),
        finalize(() => {
          const caseUploadStatus = this._uploadPendingCases.value[caseId];
          caseUploadStatus.uploadPending = false;
          this._uploadPendingCases.next(
            Object.assign({}, this._uploadPendingCases.value, {
              [caseId]: caseUploadStatus
            })
          );
        })
      )
      .subscribe({
        next: () => console.log('File upload finished.'),
        error: (err) => console.error(`Unexpected error during file upload: `, err)
      });
  }

  private setMD5(files: Array<FileToUpload>) {
    // Wait until MD5 for all the file is calculated
    return observableForkJoin(files.map((f) => FileUploadService.generateMD5(f.file))).pipe(
      tap((results) => {
        files.forEach((file, i) => (file.md5Hash = results[i].toString()));
      })
    );
  }

  private getFileUploadUrl(url: string, files: Array<FileToUpload>) {
    const payload = {
      type: 'S3_CREDENTIALS',
      biomarkerFiles: []
    };
    // Once MD5 for all the file is calculated, then prepare the payload.
    files.forEach((fileToUpload) => {
      if (['vcf', 'bam'].includes(fileToUpload.key)) {
        payload[fileToUpload.key] = {
          fileName: fileToUpload.name,
          md5: fileToUpload.md5Hash
        };
      } else {
        payload.biomarkerFiles.push({
          biomarkerFileReaderId: fileToUpload.options.biomarkerFileReaderId,
          fileIndex: fileToUpload.options.fileIndex,
          fileName: fileToUpload.name,
          md5: fileToUpload.md5Hash
        });
      }
    });
    // Make an API call to get pre-signed URL
    return this.http.post<any>(url, payload).pipe(
      retry(1),
      tap((response: any) => {
        // Extract S3 upload url for each file
        for (const file of files) {
          // Assign options if not present
          if (!file.options) {
            file.options = {};
          }

          if (['vcf', 'bam'].includes(file.key)) {
            // vcf/bam at top level
            file.uploadUrl = response[file.key].uri; // Needed to support older upload workflow
            file.options.s3 = response[file.key].s3;
          } else {
            const biomarkerFile = response.biomarkerFiles.find(
              (v) =>
                v.biomarkerFileReaderId === file.options.biomarkerFileReaderId &&
                (Number.isInteger(v.fileIndex) ? v.fileIndex === file.options.fileIndex : true)
            );

            file.uploadUrl = biomarkerFile.uri; // Needed to support older upload workflow
            file.options.s3 = biomarkerFile.s3;

            /**
             * Currently, "region" and "bucketName" are attached to the file level even though they're same for all the files.
             * This is due to some technical challenges on BE side. It should get fixed w/ https://rds-csi.atlassian.net/browse/ISTN-5069
             * */
            if (
              response['s3ClientConfig'] &&
              (!response['s3ClientConfig'].region || !response['s3ClientConfig'].bucketName)
            ) {
              response['s3ClientConfig'].region = biomarkerFile.s3.region;
              response['s3ClientConfig'].bucketName = biomarkerFile.s3.bucketName;
            }
          }
        }
      })
    );
  }

  private startUpload(files: Array<FileToUpload>, caseId: string) {
    const fileRequests = files.map((fileToUpload) => {
      return this.http
        .put(fileToUpload.uploadUrl, fileToUpload.file, {
          headers: new HttpHeaders().set('Content-MD5', fileToUpload.md5Hash),
          responseType: 'text',
          reportProgress: true,
          observe: 'events'
        })
        .pipe(
          retry(1),
          tap((response: any) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.updateUploadProgress(caseId, fileToUpload.key, response);
            }
            fileToUpload.status = 'SUCCESS';
          }),
          catchError((err) => {
            fileToUpload.status = 'ERROR';
            console.error(err);
            return of(null);
          })
        );
    });

    return observableForkJoin(fileRequests);
  }

  private startUploadUsingAwsSdk(
    files: Array<FileToUpload>,
    config: S3ClientConfig,
    caseId: string
  ): Observable<any> {
    const s3Client = this.createS3Client(config);
    const fileUploadRequests: Observable<
      CompleteMultipartUploadCommandOutput | AbortMultipartUploadCommandOutput
    >[] = files.map((fileToUpload) => {
      const uploadReq = new Upload({
        client: s3Client,
        params: {
          Bucket: config.bucketName,
          Key: fileToUpload.options.s3.key,
          Body: fileToUpload.file
        },
        tags: config.tags, // optional tags
        queueSize: 4, // optional concurrency configuration
        partSize: 1024 * 1024 * 10, // (10MB) - optional size of each part, in bytes. e.g. 1024 * 1024 * 10
        leavePartsOnError: false // optional manually handle dropped parts
      });

      uploadReq.on('httpUploadProgress', (progress) =>
        this.updateUploadProgress(caseId, fileToUpload.key, progress)
      );

      return from(uploadReq.done()).pipe(
        tap(() => (fileToUpload.status = 'SUCCESS')),
        catchError(() => {
          fileToUpload.status = 'ERROR';
          uploadReq.abort();
          return of(null);
        })
      );
    });
    return observableForkJoin(fileUploadRequests);
  }

  createS3Client(config: S3ClientConfig): S3 {
    let configArgs: S3ConstructorArg = {
      region: config.region,
      credentials: {
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        sessionToken: config.sessionToken
      }
    };
    if (config.hasOwnProperty('endpoint') && config.endpoint != '') {
      const url = new URL(config.endpoint);
      const endpointDetails = {
        protocol: url.protocol,
        hostname: url.host,
        path: url.pathname
      };
      configArgs = {
        ...configArgs,
        endpoint: endpointDetails,
        forcePathStyle: true
      };
    }
    return new S3(configArgs);
  }

  private reportUploadStart(url) {
    return this.http.post(url, {}).pipe(retry(1));
  }

  private updateUploadProgress(
    caseId: string,
    fileKey: string,
    progress: Progress | HttpProgressEvent
  ): void {
    const percentDone = Math.round((100 * progress.loaded) / progress.total);
    const caseUploadItem = this._uploadPendingCases.value[caseId].uploads.find(
      (u) => u.file.key === fileKey
    );
    caseUploadItem.percentDone.next(percentDone);
  }

  private changeStatus(url, files: Array<FileToUpload>): Observable<any> {
    const payload = {
      biomarkerFiles: []
    };
    files.forEach((file) => {
      if (['vcf', 'bam'].includes(file.key)) {
        payload[file.key] = {
          uploadStatus: file.status
        };
      } else {
        payload.biomarkerFiles.push({
          biomarkerFileReaderId: file.options.biomarkerFileReaderId,
          fileIndex: file.options.fileIndex,
          uploadStatus: file.status
        });
      }
    });

    return this.http.post(url, payload).pipe(retry(1));
  }

  getCaseUploadItem(
    uploadPendingCases: CasesUploading,
    caseId: string,
    fileKey: string
  ): CaseUploadItem {
    const caseUploadItemArray = _get(uploadPendingCases, [caseId, 'uploads'], []);
    return caseUploadItemArray.find((c) => c.file.key === fileKey);
  }

  // Get current upload progress for a file
  getUploadProgress(caseId: string, fileKey: string): Observable<number> {
    return this._uploadPendingCases.asObservable().pipe(
      mergeMap((uploadPendingCases: CasesUploading) => {
        const caseUploadItem = this.getCaseUploadItem(uploadPendingCases, caseId, fileKey);

        return caseUploadItem
          ? <Observable<number>>caseUploadItem.percentDone.asObservable()
          : of(null);
      })
    );
  }

  // Check if a file upload is pending
  checkUploadProgress(caseId: string, fileKey: string): Observable<boolean> {
    return this._uploadPendingCases.asObservable().pipe(
      map((uploadPendingCases: CasesUploading) => {
        const caseUploadPending = _get(uploadPendingCases, [caseId, 'uploadPending']);
        const fileUploadProgressExists = !!this.getCaseUploadItem(
          uploadPendingCases,
          caseId,
          fileKey
        );

        return caseUploadPending && fileUploadProgressExists;
      })
    );
  }
}
