import { of, forkJoin, defer, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { delay, catchError, repeatWhen, tap, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { get as _get } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { LastUpdated } from 'app/model/valueObjects/lastUpdatedEnum';
import { CaseData } from 'app/model/entities/CaseList';
import { CommonService, HEADER_ETAG, HEADER_IF_NONE_MATCH } from './common.service';
import { AuthService } from './auth.service';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

interface AuditMap {
  [caseId: string]: number;
}

interface VariantMap {
  [caseId: string]: {
    variants: string[];
    variantCount: number;
  };
}

export enum ListName {
  inProgress = 'inProgress',
  toApprove = 'toApprove',
  closed = 'closed'
}

const ListURLMap = {
  [ListName.inProgress]: SessionLinkKeys.LIST_CASES_IN_PROGRESS,
  [ListName.toApprove]: SessionLinkKeys.LIST_CASES_IN_ANALYSIS,
  [ListName.closed]: SessionLinkKeys.LIST_CLOSED_CASES
};

@Injectable()
export class CaseListService {
  private _cases = {
    [ListName.inProgress]: {
      list: new BehaviorSubject<CaseData[]>([]),
      etag: <string>null,
      cachedList: <CaseData[]>[],
      updateRequired: false,
      loadError: false,
      lastUpdateTime: <Date>null,
      lastUpdated: new BehaviorSubject<LastUpdated>(LastUpdated.unknown)
    },
    [ListName.toApprove]: {
      list: new BehaviorSubject<CaseData[]>([]),
      etag: <string>null,
      cachedList: <CaseData[]>[],
      updateRequired: false,
      loadError: false,
      lastUpdateTime: <Date>null,
      lastUpdated: new BehaviorSubject<LastUpdated>(LastUpdated.unknown)
    },
    [ListName.closed]: {
      list: new BehaviorSubject<CaseData[]>([]),
      etag: <string>null,
      cachedList: <CaseData[]>[],
      updateRequired: false,
      loadError: false,
      lastUpdateTime: <Date>null,
      lastUpdated: new BehaviorSubject<LastUpdated>(LastUpdated.unknown),
      totalNumberOfCases: new BehaviorSubject<number>(0),
      page: {
        size: 100,
        nextURL: new BehaviorSubject<string>(null)
      }
    }
  };
  private _auditSummary = {
    etag: <string>null,
    cachedMap: <AuditMap>{}
  };
  private _variantSummary = {
    etag: <string>null,
    cachedMap: <VariantMap>{}
  };
  private readonly _pollInterval: number = 10000;
  private _pollSubscription: Subscription;
  private _canRefreshClosed = new BehaviorSubject<boolean>(false);
  private _loadingInitial = new BehaviorSubject<boolean>(false);
  private _closedListParams = new HttpParams()
    .append('page', '0')
    .append('size', this._cases.closed.page.size.toString());

  constructor(private http: HttpClient, private authService: AuthService) {}

  get totalClosedCases$(): Observable<number> {
    return this._cases[ListName.closed].totalNumberOfCases.asObservable();
  }

  get casesInProgress(): Observable<CaseData[]> {
    return this._cases[ListName.inProgress].list.asObservable();
  }

  get casesToApprove(): Observable<CaseData[]> {
    return this._cases[ListName.toApprove].list.asObservable();
  }

  get casesClosed(): Observable<CaseData[]> {
    return this._cases[ListName.closed].list.asObservable();
  }

  get lastUpdatedInProgress(): Observable<LastUpdated> {
    return this._cases[ListName.inProgress].lastUpdated.asObservable();
  }

  get lastUpdatedToApprove(): Observable<LastUpdated> {
    return this._cases[ListName.toApprove].lastUpdated.asObservable();
  }

  get lastUpdatedClosed(): Observable<LastUpdated> {
    return this._cases[ListName.closed].lastUpdated.asObservable();
  }

  get canLoadMoreClosed(): Observable<boolean> {
    return this._cases[ListName.closed].page.nextURL
      .asObservable()
      .pipe(map((nextURL) => !!nextURL));
  }

  get canRefreshClosed(): Observable<boolean> {
    return this._canRefreshClosed.asObservable();
  }

  get loadingInitial(): Observable<boolean> {
    return this._loadingInitial.asObservable();
  }

  private resetPollingFlags() {
    Object.values(ListName).forEach((listName) => {
      this._cases[listName].updateRequired = false;
      this._cases[listName].loadError = false;
    });
  }

  refreshCases(): Observable<Array<CaseData[]>> {
    return defer(() => {
      this.resetPollingFlags();

      return forkJoin([
        this.loadList(ListName.inProgress),
        this.loadList(ListName.toApprove),
        this.loadList(ListName.closed)
      ]);
    });
  }

  private getListOptions(listName: ListName): any {
    return Object.assign(
      this.getBaseOptions(this._cases[listName].etag),
      listName === ListName.closed ? { params: this._closedListParams } : {}
    );
  }

  private getBaseOptions(etag: string = null) {
    return Object.assign(
      { observe: 'response' as 'body' }, // Return an HttpResponse so that we can parse HTTP headers
      etag ? { headers: new HttpHeaders().set(HEADER_IF_NONE_MATCH, etag) } : {}
    );
  }

  private getEtag(response: HttpResponse<any>): string {
    return response.headers.get(HEADER_ETAG);
  }

  private getURL(listName: ListName): string {
    return this.authService.getURL(ListURLMap[listName]);
  }

  private getListResponseKey(listName: ListName): string {
    return listName === ListName.closed ? 'caseSummaries' : 'caseUploadSummaries';
  }

  private updateClosedNextURL(response: HttpResponse<any>) {
    this._cases[ListName.closed].page.nextURL.next(_get(response, 'body._links.next.href'));
  }

  /**
   * For now we are using `updateTotalNumberOfCases` function for `ListName.closed` only but in
   * future we will use it for `ListName.inProgress` and `ListName.toApprove` during
   * pagination implementation.
   */
  updateTotalNumberOfCases(listName: ListName, response: HttpResponse<any>): void {
    if (listName === ListName.closed && _get(response, 'body.page.totalElements')) {
      this._cases[ListName.closed].totalNumberOfCases.next(
        _get(response, 'body.page.totalElements')
      );
    }
  }

  // Load case list data
  private loadList(listName: ListName): Observable<CaseData[]> {
    return this.http.get<any>(this.getURL(listName), this.getListOptions(listName)).pipe(
      tap<HttpResponse<any>>((response) => {
        this._cases[listName].etag = this.getEtag(response);
        this._cases[listName].updateRequired = true;

        this.updateTotalNumberOfCases(listName, response);

        if (listName === ListName.closed) {
          this.updateClosedNextURL(response);
        }
      }),
      map((response: any) => response.body._embedded[this.getListResponseKey(listName)]),
      tap((caseList: CaseData[]) => {
        this._cases[listName].cachedList = caseList;
      }),
      catchError((err) => {
        if (err.status !== 304) {
          this._cases[listName].loadError = true;
        }

        return this.handleErrorResponse<CaseData[]>(
          err.status,
          this._cases[listName].cachedList,
          []
        );
      })
    );
  }

  loadMoreClosed(): void {
    if (!this._canRefreshClosed.value) this._canRefreshClosed.next(true);
    const url = this._cases[ListName.closed].page.nextURL.value;

    this.http
      .get<any>(url, this.getBaseOptions())
      .pipe(
        tap((response: any) => this.updateClosedNextURL(response)),
        map((response: any) => response.body._embedded[this.getListResponseKey(ListName.closed)]),
        mergeMap((closedListRaw: CaseData[]) => this.processCaseData([closedListRaw])),
        withLatestFrom(this.casesClosed)
      )
      .subscribe(([closedListMoreArray, closedCasesList]) => {
        this._cases[ListName.closed].cachedList = closedCasesList.concat(closedListMoreArray[0]);
        this.updateListData(ListName.closed, this._cases[ListName.closed].cachedList);
      });
  }

  refreshClosed() {
    this._cases[ListName.closed].etag = null;
    this._cases[ListName.closed].page.nextURL.next(null);
    this._cases[ListName.closed].cachedList = [];

    this.loadList(ListName.closed)
      .pipe(mergeMap((closedListRaw: CaseData[]) => this.processCaseData([closedListRaw])))
      .subscribe((closedListArray: Array<CaseData[]>) => {
        this._canRefreshClosed.next(false);
        this.updateListData(ListName.closed, closedListArray[0]);
      });
  }

  private handleErrorResponse<T>(
    errorStatus: number,
    cachedResult: T,
    defaultResult: T
  ): Observable<T> {
    if (errorStatus === 304) {
      return of(cachedResult);
    } else {
      return of(defaultResult);
    }
  }

  private updateAllCaseLists() {
    Object.values(ListName).forEach((listName) => {
      this._cases[listName].updateRequired = true;
    });
  }

  private loadAuditSummary(caseIds: string[]): Observable<AuditMap> {
    return this.http
      .post<HttpResponse<AuditMap>>(
        this.authService.getURL(SessionLinkKeys.LIST_AUDIT_CASE_SUMMARY),
        { caseIds },
        this.getBaseOptions(this._auditSummary.etag)
      )
      .pipe(
        tap((response: any) => {
          this._auditSummary.etag = this.getEtag(response);
          this.updateAllCaseLists();
        }),
        map((response: any) => response.body),
        tap(
          (auditMap: AuditMap) =>
            (this._auditSummary.cachedMap = Object.assign(
              {},
              this._auditSummary.cachedMap,
              auditMap
            ))
        ),
        catchError((err: AuditMap) =>
          this.handleErrorResponse<AuditMap>(err.status, this._auditSummary.cachedMap, {})
        )
      );
  }

  private loadVariantSummary(caseIds: string[]): Observable<VariantMap> {
    return this.http
      .post<HttpResponse<any>>(
        this.authService.getURL(SessionLinkKeys.LIST_INTERPRETATION_CASE_SUMMARY),
        { caseIds },
        this.getBaseOptions(this._variantSummary.etag)
      )
      .pipe(
        tap((response: any) => {
          this._variantSummary.etag = this.getEtag(response);
          this.updateAllCaseLists();
        }),
        map((response: any) =>
          response.body.caseSummaries.reduce((acc, caseSummary) => {
            // Process the list into a mapping object
            acc[caseSummary.caseId] = {
              variants: caseSummary.variants,
              variantCount: caseSummary.variantCount
            };
            return acc;
          }, {})
        ),
        tap(
          (variantMap) =>
            (this._variantSummary.cachedMap = Object.assign(
              {},
              this._variantSummary.cachedMap,
              variantMap
            ))
        ),
        catchError((err) =>
          this.handleErrorResponse<VariantMap>(err.status, this._variantSummary.cachedMap, {})
        )
      );
  }

  private setLastUpdatedText() {
    const timeNow = new Date();

    Object.values(ListName).forEach((listName) => {
      if (this._cases[listName].loadError) {
        this._cases[listName].lastUpdated.next(
          CommonService.calculateElapsedTime(this._cases[listName].lastUpdateTime)
        );
      } else {
        this._cases[listName].lastUpdateTime = timeNow;
        this._cases[listName].lastUpdated.next(CommonService.calculateElapsedTime(timeNow));
      }
    });
  }

  private resetData() {
    Object.values(ListName).forEach((listName) => {
      this._cases[listName].list.next([]);
      this._cases[listName].etag = null;
    });

    this._canRefreshClosed.next(false);
  }

  // Loads audit and variant summaries and returns augmented case data
  processCaseData(caseDataResponses: Array<CaseData[]>): Observable<Array<CaseData[]>> {
    let allCaseIds = [];

    for (const caseDataArray of caseDataResponses) {
      allCaseIds = allCaseIds.concat(caseDataArray.map((caseData) => caseData.id));
    }

    return forkJoin([this.loadAuditSummary(allCaseIds), this.loadVariantSummary(allCaseIds)]).pipe(
      map((summaryResponses: any) => {
        const [auditMap, variantMap] = summaryResponses;

        return caseDataResponses.map((caseDataArray) => {
          return caseDataArray.map((caseData) =>
            Object.assign({}, caseData, {
              eventCount: _get(auditMap, caseData.id, 0),
              variants: _get(variantMap, [caseData.id, 'variants'], []),
              variantCount: _get(variantMap, [caseData.id, 'variantCount'], 0)
            })
          );
        });
      })
    );
  }

  private updateListData(listName: ListName, caseDataArray: CaseData[]) {
    this._cases[listName].list.next(caseDataArray);
  }

  startPolling() {
    this._loadingInitial.next(true);

    this._pollSubscription = this.refreshCases()
      .pipe(
        mergeMap((responses: Array<CaseData[]>) => this.processCaseData(responses)),
        repeatWhen((completed) => completed.pipe(delay(this._pollInterval)))
      )
      .subscribe((responses) => {
        if (this._loadingInitial.value) this._loadingInitial.next(false);
        const [inProgressList, toApproveList, closedList] = responses;

        if (this._cases[ListName.inProgress].updateRequired) {
          this.updateListData(ListName.inProgress, inProgressList);
        }

        if (this._cases[ListName.toApprove].updateRequired) {
          this.updateListData(ListName.toApprove, toApproveList);
        }

        if (this._cases[ListName.closed].updateRequired && !this._canRefreshClosed.value) {
          this.updateListData(ListName.closed, closedList);
        }

        this.setLastUpdatedText();
      });
  }

  stopPolling() {
    if (this._pollSubscription) {
      this._pollSubscription.unsubscribe();
      this.resetData();
    }
  }

  deleteCase(url: string): Observable<CaseData> {
    return this.http.delete<CaseData>(url);
  }

  cancelCase(url: string): Observable<CaseData> {
    return this.http.put<CaseData>(url, null);
  }
}
