<div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between start" fxFlex>
  <div fxFlex>
    <div fxLayout="column" class="full-width" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="info">
        <img alt="" *ngIf="logoSrc" [src]="logoSrc" fxFlex="40px"/>
        <div fxLayout="column" [ngSwitch]="activeVariantCard">
          <ng-container i18n="VariantFrequencyPanel.Notes@@your-lab-s-data-for">Your lab's data for</ng-container>
          <div ngPreserveWhitespaces *ngSwitchCase="variantCard">
            <div [matTooltip]="categoryOrVariant?.value || variantCard?.category?.name">
              <span class="mat-subheading-1">{{ variantCard.diagnosis.name }}</span>
              <ng-container *ngIf="categoryOrVariant || variantCard.category">
                <app-ruo-ivd-mapper>
                  <span IVD i18n="VariantFrequencyPanel.Notes@@cases-with">cases with</span>
                  <span RUO i18n="VariantFrequencyPanel.Notes@@research-cases-with">research cases with</span>
                </app-ruo-ivd-mapper>
                <span class="mat-subheading-1">{{ categoryOrVariant?.value || variantCard.category.name }}</span>
              </ng-container>
            </div>
            <div class="link" *ngIf="alternativeVariantCard" (click)="changeActiveCard(alternativeVariantCard)">
              <app-ruo-ivd-mapper>
                <span IVD i18n="VariantFrequencyPanel.Notes@@most-cases-with">Most cases with </span>
                <span RUO i18n="VariantFrequencyPanel.Notes@@most-cases-with">Most research cases with </span>
              </app-ruo-ivd-mapper>
              {{alternativeVariantCard.category.name}} <span i18n="VariantFrequencyPanel.Notes@@variant-frequency-panel-in">in</span> {{alternativeVariantCard.diagnosis.name}} ({{alternativeVariantCard.diagnosis.numCases}} / {{casesApproved}})
            </div>
          </div>
          <div ngPreserveWhitespaces *ngSwitchCase="alternativeVariantCard">
            <div>
              {{ alternativeVariantCard.diagnosis.name }}
              <ng-container *ngIf="categoryOrVariant || alternativeVariantCard.category">
                <span i18n="VariantFrequencyPanel.Notes@@cases-with">cases with</span>
                {{ categoryOrVariant?.value || alternativeVariantCard.category.name }}
              </ng-container>
            </div>
            <div class="link" (click)="changeActiveCard(variantCard)">
              <ng-container i18n><span class="mat-subheading-1">Revert</span> to </ng-container>{{ variantCard.diagnosis.name }}
            </div>
          </div>
        </div>
      </div>
      <table class="full-width" aria-hidden="true">
        <tr>
          <td></td>
          <td class="mat-caption cair-color-gray-500" i18n="VariantFrequencyPanel.ColumnHeader@@tier">Tier</td>
          <td class="mat-caption cair-color-gray-500">
            <app-ruo-ivd-mapper>
              <ng-container IVD i18n="VariantFrequencyPanel.ColumnHeader@@cases">Cases</ng-container>
              <ng-container RUO i18n="VariantFrequencyPanel.ColumnHeader@@research-cases">Research cases</ng-container>
            </app-ruo-ivd-mapper>
          </td>
          <td class="mat-caption cair-color-gray-500" i18n="VariantFrequencyPanel.ColumnHeader@@percentage-sign">%</td>
        </tr>
        <ng-container ngPreserveWhitespaces>
          <tr class="category-row" ngPreserveWhitespaces>
            <td [matTooltip]="activeVariantCard.diagnosis.name">
              <span class="name mat-subheading-1">{{activeVariantCard.diagnosis.name}}</span>
            </td>
            <td></td>
            <td>{{ activeVariantCard.diagnosis.numCases }} / {{casesApproved}}</td>
            <td>{{ diagnosisStats | percent }}</td>
            <td>
              <app-doughnut-percentages size="36" [percentages]="[diagnosisStats]"></app-doughnut-percentages>
            </td>
          </tr>
          <tr class="category-row" ngPreserveWhitespaces *ngIf="activeVariantCard.category">
            <td>
              <span class="name mat-subheading-1" [matTooltip]="activeVariantCard.category.name">{{activeVariantCard.category.name}}</span>
            </td>
            <td></td>
            <td>{{activeVariantCard.category.numCases}} / {{ activeVariantCard.diagnosis.numCases }}</td>
            <td>{{ geneStats | percent }}</td>
            <td>
              <app-doughnut-percentages size="36" [percentages]="[diagnosisStats, geneStats]"></app-doughnut-percentages>
            </td>
          </tr>
          <tr class="variant-row"
            [ngClass]="{ 'selected': variant.aboveTheFold !== null, 'above-fold': variant.aboveTheFold === false }"
            *ngFor="let variant of activeVariantCard?.variants" ngPreserveWhitespaces>
            <td [matTooltip]="combinedVariantName(variant.name)">
              <app-variant-title [name]=variant.name></app-variant-title>
            </td>
            <td>
              <span class="bold-text"><app-tier-name *ngIf="variant.tier" [value]="variant.tier"></app-tier-name></span>
              <mat-icon class="md-14" *ngIf="variant.reclassified">repeat<div class="red-spot reclassified-dot-position"></div></mat-icon>
            </td>
            <td>{{variant.numCases}} / {{activeVariantCard.category.numCases}}</td>
            <td>{{variantStats(variant) | percent }}</td>
            <td>
              <app-doughnut-percentages size="36" [percentages]="[diagnosisStats, geneStats, variantStats(variant)]"></app-doughnut-percentages>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="footer cair-color-gray-400 mat-small">
    <span i18n="VariantFrequencyPanel.Notes@@unclassified-variants-will-not-appear-in-analytics">Unclassified variants will not appear in analytics</span>
  </div>
</div>
