<div class="page-content-no-header page-container page-content-section page-content-with-fixed-footer body-1" fxLayout="column" fxLayoutGap="20px">
  <div class="table-name">
    CAIR (CLINICAL ANNOTATION, INTERPRETATION AND REPORTING) ALPHA VERSION SOFTWARE END USER LICENSE AGREEMENT
  </div>

  <div>
    This End User License Agreement (“Agreement”) is entered into by and between Roche Sequencing Solutions, Inc.
    (“RSS”) and the entity that licenses the Licensed Software (as defined below) under this Agreement (“Licensee”), and
    is effective as of the earliest date that any employee or agent of Licensee either installs, activates, or uses the
    Software (“Effective Date”). “Party” means RSS or Licensee; “Parties” means both of them.
  </div>

  <div>
    <p>1. Definitions
    <p>When capitalized and used herein, the following terms shall have the following meanings.
    <p>1.1 “Affiliate” means: (i) any organization which directly or indirectly controls RSS; (ii) any organization, which
    is directly or indirectly controlled by RSS; or (iii) any organization, which is controlled, directly or indirectly,
    by the ultimate parent company of RSS. Control as used in the foregoing sentence is defined as owning more than
    fifty percent (50%) of the voting stock of a company or having otherwise the power to govern the financial and the
    operating policies or to appoint the management of an organization. The term “Affiliate” shall not include Chugai
    Pharmaceutical Co. Ltd., 1-1, Nihonbashi-Muromachi 2-chome, Chuo-ku Tokyo, 103-8324, Japan (“Chugai”) or Foundation
    Medicine, Inc., 150 Second Street, Cambridge, MA 02141, USA (“FMI”) and their respective subsidiaries, unless RSS
    opts for such inclusion of Chugai or FMI and their respective subsidiaries by giving written notice to Licensee.</p>
    <p>1.2 “Authorized Users” means individual employees or contractors of Licensee.</p>
    <p>1.3 “Documentation” means the documentation for the Licensed Software, as updated from time to time by RSS in its
      sole discretion.</p>
    <p>1.4 “Evaluation Period” means for a particular alpha version of Licensed Software a period of no greater than four
      (4) weeks from the date Licensee is granted access to such particular version.</p>
    <p>1.5 “Field of Use” means research use only, excluding any diagnostic or clinical use.</p>
    <p>1.6 “Intellectual Property Rights” means all intellectual property rights worldwide, whether arising under statutory
    or common law or otherwise, including without limitation patent rights, trade secret rights, copyrights, and any
      analogous rights.</p>
    <p>1.7 “Licensed Software” means alpha versions of RSS’s CAIR (Clinical Annotation, Interpretation and Reporting)
      software product currently under development that have not been commercially released.</p>
    <p>1.8 “Open Source Software” is defined in Section 2.3 below.</p>
    <p>1.9 “Protected Health Information” means individually identifiable health information as defined in 45 C.F.R. §
      164.501.</p>
    <p>1.10 “Term” is defined in Section 8.1 below.</p>
    <p>1.11 “Territory” means the country or countries in which Licensee is authorized to use the Licensed Software, as
      specified by RSS.</p>
  </div>

  <div>
    <p>2. License
    <p>2.1 Limited License. Subject to the terms and conditions of this Agreement, RSS hereby grants to Licensee a
    non-exclusive, personal, royalty-free, non-transferable, revocable license, without the right to sublicense, solely
    within the Territory and Field of Use, for no more than ten (10) Authorized Users to access and use each particular
    alpha version of the Licensed Software during the applicable Evaluation Period solely for Licensee’s internal
    evaluation of the Licensed Software in accordance with the Documentation. Except for the licenses expressly granted
    by RSS in this Section 2.1, no covenants, licenses, authorizations or other rights are granted to Licensee under
    this Agreement by implication, estoppel, statute, operation of law, or otherwise, under any Intellectual Property
    Rights of RSS or any of its Affiliates. All right, title and interest, including all Intellectual Property Rights,
    in the Licensed Software and the Documentation are and shall remain the sole and exclusive property of RSS and its
    Affiliates and their respective licensors. Licensee is solely responsible for the acts and omissions of any
    Authorized Users and any act or omission by any Authorized Users is deemed Licensee’s act or omission for purposes
    of this Agreement.
    <p>2.2 Additional License Conditions. Licensee must not, and must not permit, authorize, or assist any third party to:</p>
    <p>2.2.1 use the Licensed Software or any data obtained from the use thereof for any diagnostic, clinical, or
    therapeutic purposes;</p>
    <p>2.2.2 reproduce the Documentation except to the extent necessary for Licensee to exercise the license rights granted
    in Section 2.1;</p>
    <p>2.2.3 rent, lease, lend, sell, offer to sell, disclose, use for timesharing or service bureau purposes or otherwise
    use or allow others to use the Licensed Software or Documentation for the benefit of any third party;</p>
    <p>2.2.4 decompile, disassemble, translate, reverse engineer, create derivative works of or modify the Licensed
    Software or attempt to derive the source code, architecture, design, function or logic of all or any portion of the
    Licensed Software;</p>
    <p>2.2.5 possess or use the Licensed Software, or allow the transfer, transmission, export or re-export of the Licensed
    Software or Documentation or any portion thereof in violation of any export control laws or regulations administered
    by the U.S. Commerce Department, U.S. Treasury Department's Office of Foreign Assets Control or any other government
    agency in any jurisdiction;</p>
    <p>2.2.6 remove or modify any markings, notices, labels, logos, trademarks, trade names, or serial numbers contained on
    or in conjunction with the Licensed Software;</p>
    <p>2.2.7 share any user authentication or other credentials (for example passwords or other credentials associated with
    Authorized Users) associated with the Licensed Software with any other party; or</p>
    <p>2.2.8 use the Licensed Software otherwise than in compliance with all applicable laws and regulations.</p>
    <p>2.3 Open Source Software. Certain items of software provided or integrated with the Licensed Software may be subject
    to “open source” or “free software” licenses (“Open Source Software”). Open Source Software is licensed under the
    terms of the end user license that accompanies such Open Source Software and is not subject to the terms and
    conditions of this Agreement, other than this Section 2.3 and Sections 5.2 and 7. Nothing in this Agreement limits
    Licensee’s rights under, or grants Licensee rights that supersede, the terms and conditions of any applicable end
    user license for Open Source Software. If required by any license for particular Open Source Software, RSS makes
    such Open Source Software available by written request to RSS.</p>
  </div>

  <div>
    <p>3. No Protected Health Information. All data uploaded, transmitted, processed, or stored using the Licensed Software
    or provided by Licensee to RSS or its Affiliates in connection with this Agreement (“Licensee Data”), must be in
    de-identified, anonymous form in accordance with applicable laws. RSS and its Affiliates neither desire nor intend
    to receive or process Protected Health Information or other identifiable patient or health information as defined
    under applicable laws. For illustrative purposes only and without limitation, Licensee agrees not to upload,
    transmit, process, store, or otherwise provide to RSS or its Affiliates the names, dates of birth, addresses, social
    security numbers, government issued identification numbers or any other information that may directly or indirectly
    identify any individual from whom any genomic information (whether consisting of whole genome sequences or portions
    thereof) was derived. Licensee must store, process, and transmit all Licensee Data and Protected Health Information
    only using compute resources controlled by Licensee (whether cloud compute resources or otherwise and whether
    maintained or hosted by Licensee or a third party) and that implement the highest industry standards applicable to
    Protected Health Information. Licensee acknowledges that RSS and its Affiliates will have no liability whatsoever
    for any access or disclosure of Licensee Data or Protected Health Information by or to third parties. Further,
    Licensee represents and warrants to RSS that it will comply with all applicable laws in its use and disclosure of
      Protected Health Information.</p>
  </div>

  <div>
    <p>4. Confidentiality; No Publication. Licensee must maintain the strict confidentiality of any information provided to
    Licensee by RSS or its Affiliates or known to Licensee as a consequence of this Agreement (whether in writing,
    orally or in any form, and whether or not labeled “Confidential”) (“Confidential Information”), and must not
    disclose any portion of Confidential Information to any third party. RSS Confidential Information includes the
    Licensed Software. Licensee must use RSS Confidential Information solely as necessary to exercise the limited rights
    granted to Licensee under Section 2.1 of this Agreement. The foregoing obligations will continue for a period of
    five (5) years from the disclosure of any such particular Confidential Information, except with respect to
    Confidential Information that constitutes trade secrets, in which case the foregoing obligations will continue for
    so long as such Confidential Information continues to constitute trade secrets. Confidential Information will not
    include information which: (a) was known to Licensee prior to Licensee’s receipt of the Confidential Information
    from RSS, as demonstrated by written records of Licensee; (b) at the time of disclosure by RSS to Licensee was
    generally available to the public; (c) is made available to Licensee for use or disclosure by Licensee from any
    third party not subject to and not in breach of any direct or indirect obligation of confidentiality to RSS or any
    of its Affiliates; (d) was developed independently by Licensee without access to or use of RSS’s or any of its
    Affiliates’ Confidential Information, as demonstrated by written records of Licensee. For greater clarity, Licensee
    will not make any publication or other dissemination of the Licensed Software (including any information relating to
      Licensee’s evaluation or use of the Licensed Software).</p>
  </div>

  <div>
    <p>5. Licensee Representations and Warranties; RSS Disclaimers</p>
    <p>5.1 Licensee Representations and Warranties. Licensee represents and warrants to RSS that Licensee: (a) will comply
    with all applicable laws and regulations in relation to its use of the Licensed Software; (b) will comply with all
    applicable laws and regulations in its use and disclosure of Protected Health Information, and will not store
    (whether on a persistent or transitory basis), process or transmit Protected Health Information within or using any
    compute resources of RSS or its Affiliates (whether cloud compute resources or otherwise and whether maintained or
    hosted by RSS or its Affiliates or a third party), nor otherwise provide or make available any such information to
      RSS or its Affiliates; and (c) Licensee will use the Licensed Software for research use only.</p>
    <p>5.2 RSS Disclaimers. THE LICENSED SOFTWARE IS EXPERIMENTAL IN NATURE, IS NOT FULLY TESTED, MAY CONTAIN BUGS OR OTHER
    ERRORS, AND MAY LACK REGULATORY APPROVALS. LICENSEE USES THE LICENSED SOFTWARE SOLELY AT ITS OWN RISK. RSS PROVIDES
    THE LICENSED SOFTWARE AND DOCUMENTATION “AS-IS”, WITHOUT ANY WARRANTIES, REPRESENTATIONS, GUARANTEES, OR CONDITIONS
    OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR FREEDOM FROM INFRINGEMENT OF THIRD PARTY INTELLECTUAL
      PROPERTY RIGHTS.</p>
  </div>

  <div>
    <p>6. Updates; No Support</p>
    <p>6.1 Updates. RSS may from time to time, in its sole discretion, develop updates, upgrades, or future versions of the
    Licensed Software. Licensee acknowledges and agrees that RSS has no obligation to provide to Licensee or
    commercially release any updates, upgrades, or future versions of the Licensed Software, and that RSS will only do
      so in its sole discretion.</p>
    <p>6.2 No Support. RSS and its Affiliates are not obligated to provide any support to Licensee under this Agreement.</p>
  </div>

  <div>
    <p>7. Limitation of Liability</p>
    <p>7.1 IN NO EVENT WILL RSS OR ITS AFFILIATES BE LIABLE TO LICENSEE RELATING TO OR ARISING OUT OF THIS AGREEMENT,
    WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, FOR ANY USE BY OR ON BEHALF OF LICENSEE OF THE
    LICENSED SOFTWARE OR FOR ANY LOSS, CLAIM, DAMAGE OR LIABILITY, OF WHATSOEVER KIND OR NATURE, INCLUDING BUT NOT
    LIMITED TO LOST PROFITS, LOST OPPORTUNITY, LOSS OF USE, LOSS OF BUSINESS, ECONOMIC LOSS, LOSS OF DATA, OR ANY
    DAMAGES (INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL
    DAMAGES), RELATING TO OR ARISING OUT OF THIS AGREEMENT OR THE USE OF THE LICENSED SOFTWARE, EVEN IF A PARTY HAS BEEN
    ADVISED OF THE POSSIBILITY OR PROBABILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS AND EXCLUSIONS APPLY REGARDLESS
      OF WHETHER ANY REMEDY IN THIS AGREEMENT FAILS OF ITS ESSENTIAL PURPOSE.</p>
  </div>

  <div>
    <p>8. Term and Termination</p>
    <p>8.1 Term. Unless terminated earlier in accordance with the terms and conditions herein, this Agreement will commence
    on the Effective Date and will continue for a period of one (1) year from the Effective Date, and thereafter may be
    renewed for an additional term on mutual agreement of RSS and Licensee (collectively the “Term”). RSS may terminate
    this Agreement: (a) for convenience on thirty (30) days’ prior written notice to Licensee; or (ii) for any breach by
      Licensee on written notice to Licensee.</p>
    <p>8.2 Effect of Termination. Upon any termination of this Agreement for any reason: (a) Licensee must immediately
    cease use of the Licensed Software and Documentation; (b) any and all payment obligations will immediately become
    due; (c) all licenses granted in this Agreement will automatically terminate; and (d) all rights and obligations of
    the Parties under this Agreement that, by their nature, do not terminate with the termination of this Agreement
    shall survive, including the following Sections: 3 (No Protected Health Information), 5 (Licensee Representations
    and Warranties; RSS Disclaimers), 7 (Limitation of Liability), 9 (Miscellaneous) and this Section 8.2 (Effect of
      Termination).</p>
  </div>

  <div>
    <p>9. Miscellaneous</p>
    <p>9.1 Entire Agreement; Modification. This Agreement, together with any document incorporated herein by reference,
    constitutes the entire agreement between the Parties hereto relating to the subject matter hereof and supersedes all
    prior or contemporaneous negotiations, representations, agreements and understandings of the Parties relating
    thereto, whether written or oral. No subsequent alteration, amendment, change or addition to this Agreement, shall
      be binding upon the Parties unless in writing and executed by the respective authorized signatories of the Parties.</p>
    <p>9.2 Assignment. Licensee may not voluntarily or involuntarily (whether by operation of law, merger, consolidation,
    change of control or otherwise) assign, delegate, or transfer this Agreement or any rights or obligations under this
    Agreement, in whole or in part, without the prior written consent of RSS, whether by contract or by merger (whether
    or not Licensee is the surviving entity), consolidation, dissolution, or otherwise. This Agreement shall be binding
    upon and inure to the benefit of the Parties and their permitted successors and permitted assigns. Any assignment or
    transfer or attempted assignment or transfer in violation of this Section 9.2 is null and void. RSS may assign,
      delegate, and transfer this Agreement, in whole or in part, to any third party without the consent of Licensee.</p>
    <p>9.3 Force Majeure. RSS shall not be liable to Licensee for any failure to perform or any delay in performance under
    this Agreement if such failure or delay arises from any act beyond RSS’s control, such as natural disasters or other
    acts of God, wars, fires, riots, strikes, lockouts, labor disputes, accidents, malicious damage caused by a third
    party to technology deployed or used by RSS or the breakdown, failure or malfunction of any telecommunications,
      computer or other electrical, mechanical or technological service or system.</p>
    <p>9.4 Waiver. Except as specifically provided for herein, the waiver from time to time by RSS of any of its rights or
    its failure to exercise any remedy shall not operate or be construed as a continuing waiver of same or of any other
      of RSS’s rights or remedies provided in this Agreement.</p>
    <p>9.5 For U.S. Government End Users. With respect to use of the Licensed Software by or on behalf of the federal
    government, the technical data and software rights related to the Licensed Software provided by RSS include only
    those rights and licenses provided pursuant to this Agreement and are provided in accordance with FAR 12.211
    (Technical Data) and FAR 12.212 (Software) and, for Department of Defense transactions, DFAR 252.227-7015 (Technical
      Data – Commercial Items) and DFAR 227.7202 (Commercial Computer Software or Computer Software Documentation).</p>
    <p>9.6 Injunctive Relief. Licensee acknowledges that its breach of Section 2 would cause RSS irreparable injury for
    which it would not have an adequate remedy at law. In the event of such a breach, RSS shall be entitled to seek
    injunctive relief in addition to any other remedies it may have at law or in equity, without posting of bond or
      other security and without having to prove the inadequacy of available remedies at law.</p>
    <p>9.7 Governing Law; Venue. This Agreement shall be governed by and construed under the substantive laws of the State
    of California, without regard to its conflicts of laws provisions. The Parties agree to personal jurisdiction and
    venue in the state and federal courts of the State of California, in any suit or proceeding arising out of the
    subject matter of this Agreement. To the fullest extent permissible under applicable laws, Licensee hereby
    irrevocably waives any objection of forum non conveniens or any similar objection to the foregoing venue. The
    Parties waive any right to a jury trial and represent to each other that this waiver is made knowingly and
      voluntarily after consultation with and upon advice of counsel.</p>
    <p>9.8 Severability. If any term, covenant, or condition of this Agreement or the application thereof to any Party or
    circumstance shall, to any extent, be held to be invalid or unenforceable in any jurisdiction, then (a) such term,
    covenant, or condition shall be deemed invalid or unenforceable only in such jurisdiction and only to the extent
    necessary to comply with applicable laws; (b) in any other jurisdiction, such term, covenant or condition shall be
    valid and be enforced to the fullest extent permitted by law; (c) the remainder of this Agreement shall not be
    affected thereby and each term, covenant, or condition of this Agreement shall be valid and be enforced to the
    fullest extent permitted by law; and (d) the Parties agree to renegotiate any such term, covenant, or application
    thereof that is invalid or unenforceable in good faith in order to provide a reasonably acceptable alternative to
    such term, covenant, or condition or the application thereof in order that the transaction contemplated hereby be
      consummated as originally contemplated by the Parties to the greatest extent possible.</p>
    <p>9.9 Independent Contractors. Licensee and RSS are independent companies interacting with each other at arm’s length.
    This Agreement shall not create an agency, partnership, joint venture, or employer/employee relationship between the
    Parties. Nothing hereunder shall be deemed to authorize either Party to act for, represent or bind the other except
      as expressly provided in this Agreement.</p>
    <p id="last">9.10 Interpretation. All headings and captions are inserted for convenience of reference only and shall not affect
    the meaning or interpretation of any provision hereof. The words “including,” “include” and “includes” shall each be
      deemed to be followed by the term “without limitation.”</p>
  </div>
</div>
<div class="page-fixed-footer">
  <div class="page-container page-footer-container" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="primary" [disabled]="!enableButton" (click)="acceptTerms()" i18n="TermsPage.Button@@i-agree">I agree</button>
  </div>
</div>
