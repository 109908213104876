import { BaseEntity } from './baseEntity';
import { ClassificationType } from '../valueObjects/classificationType';
import { AMP_TIERS } from '../valueObjects/ampTier';

export class Properties extends BaseEntity {
  tier: string;
  report: boolean;
  dependentsInReport?: boolean;
  mutantsNotInReport?: boolean;

  constructor(jsonData: any) {
    super(jsonData);
  }

  get isSignificant() {
    return AMP_TIERS[this.tier] === ClassificationType.Significant;
  }

  get isUnclassified() {
    return AMP_TIERS[this.tier] === ClassificationType.Unclassified;
  }

  get isFilteredOut() {
    return AMP_TIERS[this.tier] === ClassificationType.FilteredOut;
  }
}
