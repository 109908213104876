<span *ngFor="let nameElement of name; last as isLastElement" class="word-wrap">
  <span [class.bold-text]="boldFirstName" *ngIf="displayExpandedBioMarker(nameElement.first); else regularFirst">
    <ng-container *ngIf="nameElement.first === 'MSS'" i18n="VariantTitle.Notes@@microsatellite-stable-mss">Microsatellite Stable (MSS)</ng-container>
    <ng-container *ngIf="nameElement.first === 'MSI'"><ng-container i18n="VariantTitle.Notes@@microsatellite-instability-msi">Microsatellite Instability (MSI)</ng-container> -</ng-container>
    <ng-container *ngIf="nameElement.first === 'TMB'"><ng-container i18n="VariantTitle.Notes@@tumor-mutation-burden-tmb">Tumor Mutation Burden (TMB)</ng-container> -</ng-container>
    <ng-container *ngIf="nameElement.first === 'HRDsig'"><ng-container i18n="VariantTitle.Notes@@homologous-recombination-deficiency-signature-hrdsig">Homologous Recombination Deficiency Signature (HRDsig)</ng-container> -</ng-container>
    <ng-container *ngIf="nameElement.first === 'Genomic LOH'"><ng-container i18n="VariantTitle.Notes@@loss-of-heterozygosity-loh">Loss of Heterozygosity (LOH)</ng-container> -</ng-container>
  </span>
  <ng-template #regularFirst>
    <span [class.bold-text]="boldFirstName">{{nameElement.first}}</span>
  </ng-template>
  <span *ngIf="nameElement.last" [class.expand-bio]="displayExpandedBioMarker(nameElement.first)">
    <span *ngIf="nameElement.first" ngPreserveWhitespaces> </span>
    <app-variant-last-name [last]="nameElement.last" [truncateLength]="truncateLength"></app-variant-last-name>
  </span>
  <span *ngIf="showAlias && nameElement.alias" ngPreserveWhitespaces [attr.title]="nameElement.alias"> ({{nameElement.alias}})</span>
  <span *ngIf="!isLastElement">
    <span *ngIf="multipleLines" ngPreserveWhitespaces> + </span>
    <span *ngIf="!multipleLines" ngPreserveWhitespaces>, </span>
  </span>
  <br *ngIf="multipleLines"/>
</span>
