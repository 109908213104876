import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { TierNames } from 'app/model/valueObjects/ampTier';

@Component({
  selector: 'app-tier-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './tier-name.component.html'
})
export class TierNameComponent {
  @Input() value: string;
  tierNames = TierNames;
}
