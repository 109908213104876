import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BREAKPOINT_UNCERTAINTY_VALUE,
  DIRECTIONALITY_ENUM_VALUE,
  EQUIVOCAL_ENUM_VALUE,
  IN_FRAME_ENUM_VALUE,
  QUALITY_ENUM_VALUE,
  READING_FRAME_ENUM_VALUE,
  VARIANT_CLASS_VALUE,
  VARIANT_DISPLAY_FIELD_TYPE
} from 'app/model/valueObjects/variantDisplayFieldType';

@Component({
  selector: 'app-variant-display-field-value',
  templateUrl: './variant-display-field-value.component.html',
  styleUrls: ['./variant-display-field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantDisplayFieldValueComponent {
  VariantDisplayFieldType = VARIANT_DISPLAY_FIELD_TYPE;
  QualityEnumValues = QUALITY_ENUM_VALUE;
  EquivocalEnumValues = EQUIVOCAL_ENUM_VALUE;
  DirectionalityEnumValues = DIRECTIONALITY_ENUM_VALUE;
  InFrameEnumValues = IN_FRAME_ENUM_VALUE;
  BreakpointUncertaintyValues = BREAKPOINT_UNCERTAINTY_VALUE;
  VariantClassValues = VARIANT_CLASS_VALUE;
  ReadingFrameEnumValues = READING_FRAME_ENUM_VALUE;
  @Input() type: string;
  @Input() value: any;
}
