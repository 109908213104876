import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Localization } from 'app/model/valueObjects/localization';
import { DateTime } from 'luxon';

/**
 * Examples on how to use bar-numbers
 * <app-bar-numbers [numbers]="[0, 0, 30, 40, 50, 10]" [style]="0" [maxNumber]="50" [dates]="['2018-06-09T01:21:15.954Z',
 * '2018-07-09T01:21:15.954Z', '2018-08-09T01:21:15.954Z', '2018-09-09T01:21:15.954Z', '2018-10-09T01:21:15.954Z',
 * '2018-11-09T01:21:15.954Z']"></app-bar-numbers>
 * <app-bar-numbers [numbers]="[10, 0, 30, 40]" [style]="1" [maxNumber]="50" [dates]="['2018-08-09T01:21:15.954Z',
 * '2018-09-09T01:21:15.954Z','2018-10-09T01:21:15.954Z', '2018-11-09T01:21:15.954Z']"></app-bar-numbers>
 * <app-bar-numbers [numbers]="[10]" [style]="1" [barIndex]="3" [maxNumber]="50" [dates]="['2018-08-09T01:21:15.954Z']"></app-bar-numbers>
 */

@Component({
  selector: 'app-bar-numbers',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './bar-numbers.component.html',
  styleUrls: ['./bar-numbers.component.scss']
})
export class BarNumbersComponent {
  @Input() barWidth? = 10;
  @Input() height? = 48;
  @Input() style: 0 | 1 = 0;
  @Input() barIndex: 0 | 1 | 2 | 3 = 0; // indicate color for single bar by index
  @Input() numbers: number[];
  @Input() maxNumber: number;
  @Input() dates: string[];

  barNum: number;

  constructor(public localization: Localization) {}

  getBars(): number[] {
    const maxBars = this.style === 0 ? 12 : 4;
    this.barNum = Math.min(this.numbers.length, maxBars);
    return Array.from(Array(this.barNum).keys());
  }

  getStart(i: number): number {
    if (!this.maxNumber) {
      return 100;
    }
    const num = this.numbers[i] || 0;
    return Math.floor((1 - num / this.maxNumber) * 100) || 1;
  }

  getClass(i: number): string {
    if (this.style === 0) {
      if (i < this.barNum - 1) {
        return `bar${i}`;
      }
      return `bar${i} active`;
    } else {
      if (this.barNum === 1) {
        return `bar${this.barIndex}`;
      }
      return `bar${i}`;
    }
  }

  getDate(i: number): string {
    let dateFormat: string;
    if (this.style === 0) {
      dateFormat = this.localization.dateFormatMonth;
    } else {
      dateFormat = this.localization.dateFormat;
    }
    return DateTime.fromISO(this.dates[i]).toFormat(dateFormat);
  }
}
