/**
 * Enum for last updated time.
 * Each entry in enum represents the respective minutes representation.
 * Any thing more than 60 min. falls under "more than an hour ago" category, hence set to 61
 */
export enum LastUpdated {
  up_to_date = 0,
  one_minute_ago = 1,
  five_minutes_ago = 5,
  ten_minutes_ago = 10,
  fifteen_minutes_ago = 15,
  twenty_minutes_ago = 20,
  thirty_minutes_ago = 30,
  forty_five_minutes_ago = 45,
  an_hour_ago = 60,
  more_than_an_hour_ago = 61,
  unknown
}
