export enum BrowserStorageType {
  LOCAL = 'LocalStorage',
  SESSION = 'SessionStorage',
  COOKIE = 'Cookie'
}

/**
 * A proxy for a store + key combination, so that calling code can worry about CRUD and not keys.
 * Instead of code needing to keep track of session-vs-local, key names, etc, we keep that in
 * one place: the point of instantiation.
 */
export interface BrowserStoredValue<T> {
  /**
   * Read the value from the store.
   * @return null if not found
   */
  read(): T | null;

  /**
   * Remove the value from the store.
   */
  remove(): void;

  /**
   * Serialize and write the value to the store.
   * Note that this DOES NOT guarantee the literal value will be stored, only that you'll get
   * the same value out that you put in.  Storage implementations may encode and do other things
   * to the value at rest.
   * @param value Anything serializable.
   */
  write(value: T): void;
}

/**
 * Common interface between impl and mocks.
 */
export interface BrowserStorageProxyProvider {
  proxy<T>(type: BrowserStorageType, namespace: string, key: string): BrowserStoredValue<T>;
}
