export class BaseEntity {
  _links?: Links;

  constructor(jsonData) {
    for (const prop in jsonData) {
      this[prop] = jsonData[prop];
    }
  }
}

interface Links {
  [key: string]: {
    href: string;
  };
}

export interface ListItem {
  id: number | string;
  value: string;
}
