export interface ClinicalRegion {
  clinicalRegionCode: string;
  clinicalRegionName: string;
}

export const DEFAULT_CLINICAL_REGIONS = {
  version: 'default',
  clinicalRegions: [
    {
      clinicalRegionCode: 'AU',
      clinicalRegionName: 'Australia'
    },
    {
      clinicalRegionCode: 'CA',
      clinicalRegionName: 'Canada'
    },
    {
      clinicalRegionCode: 'EU',
      clinicalRegionName: 'European Union'
    },
    {
      clinicalRegionCode: 'CH',
      clinicalRegionName: 'Switzerland'
    },
    {
      clinicalRegionCode: 'TW',
      clinicalRegionName: 'Taiwan'
    },
    {
      clinicalRegionCode: 'UK',
      clinicalRegionName: 'United Kingdom'
    },
    {
      clinicalRegionCode: 'US',
      clinicalRegionName: 'United States of America'
    }
  ]
};
