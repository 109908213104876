import { BaseEntity } from './baseEntity';

export class PopFreqAnnotation extends BaseEntity {
  externalLinks: {
    [externalId: string]: string;
  };
  source: 'ExAC' | 'gnomAD';
  overallPopFreq: number;
  overallPopAC: number;
  overallPopAN: number;
  maxPop: string;
  maxPopFreq: number;
  maxPopAC: number;
  maxPopAN: number;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
