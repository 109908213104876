import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * Examples on how to use square-percentage
 * <app-square-percentage [percentage]="35"></app-square-percentage>
 */

@Component({
  selector: 'app-square-percentage',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './square-percentage.component.html',
  styleUrls: ['./square-percentage.component.scss']
})
export class SquarePercentageComponent {
  @Input() size? = 60;
  @Input() percentage: number;

  nums = Array.from(Array(100).keys());
}
