<div ngPreserveWhitespaces>
  <ng-container *ngFor="let breakPoint of breakPoints; last as isLast">
    {{ breakPoint.fusionPartnerName }}:<ng-container
      *ngIf="!breakPoint.chromosome; else chromosomePresent"
      i18n="FusionPartnerName.Notes@@no-chromosome-entered"
      >[no chromosome entered]</ng-container
    >:<ng-container
      *ngIf="!breakPoint.position; else positionPresent"
      i18n="FusionPartnerName.Notes@@no-position-entered"
      >[no position entered]</ng-container
    >
    <ng-template #chromosomePresent>{{ breakPoint.chromosome }}</ng-template>
    <ng-template #positionPresent>{{ breakPoint.position }}</ng-template>
    <ng-container *ngIf="!isLast"> - </ng-container>
  </ng-container>
</div>
