import { BaseEntity } from './baseEntity';
import { VARIANT_DISPLAY_FIELD_TYPE } from 'app/model/valueObjects/variantDisplayFieldType';

export class VariantDisplayField extends BaseEntity {
  type: string;
  value?: any;
  detailDisplay: boolean;

  constructor(jsonData: any) {
    super(jsonData);
  }
}

export function getDisplayFieldValue(
  displayFields: VariantDisplayField[] | undefined,
  type: VARIANT_DISPLAY_FIELD_TYPE
): any {
  if (!displayFields) {
    return null;
  }

  const matchingField = displayFields.find((displayField) => displayField.type === type);

  return matchingField ? matchingField.value : null;
}
