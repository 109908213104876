import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureFlag } from '../model/entities/featureFlag';
import { AuthService } from './auth.service';
import { ToasterService } from './toaster.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class FeatureFlagService {
  private _loadingFeatureList = new BehaviorSubject<boolean>(null);
  private _featureList = new BehaviorSubject<FeatureFlag[]>(null);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {}

  get featureList() {
    return this._featureList.asObservable();
  }

  get loadingFeatureList() {
    return this._loadingFeatureList.asObservable();
  }

  public getFeatures() {
    this._loadingFeatureList.next(true);
    this.http
      .get<FeatureFlag[]>(this.authService.getURL(SessionLinkKeys.VIEW_FEATURES))
      .pipe(finalize(() => this._loadingFeatureList.next(false)))
      .subscribe({
        next: (features: FeatureFlag[]) => {
          this._featureList.next(features);
        },
        error: (error) => {
          this._featureList.next([]);
          console.error(error);
          this.toasterService.info('Failure fetching features');
        }
      });
  }

  public toggleFeature(featureId: string): Observable<void> {
    return this.http.put<void>(
      this.authService.getURL('approveFeature', { flagManagerKey: featureId }),
      null
    );
  }
}
