import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { AuthUser } from 'app/model/entities/authUser';
import { AuthService } from 'app/services/auth.service';
import { NavBarService } from 'app/services/nav-bar.service';
import { CaseService } from 'app/services/case.service';
import { VariantService } from 'app/services/variant.service';
import { LoaderService } from 'app/services/loader.service';
import { AdditionalData, SubTitle } from 'app/model/entities/nav-bar.model';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  @Input() currentUser$: Observable<AuthUser>;
  private ngUnsubscribe = new Subject<void>();

  enableNavigationNavbar = true;
  backLink$: Observable<string>;
  subTitle$: Observable<SubTitle>;
  additionalData$: Observable<AdditionalData>;
  isAuthorized$: Observable<boolean>;
  isSearchCasePermissionGet$: Observable<boolean>;
  isOperationalAnalyticsPermissionGet$: Observable<boolean>;

  constructor(
    public location: Location,
    private authService: AuthService,
    public navBarService: NavBarService,
    private caseService: CaseService,
    private variantService: VariantService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.subTitle$ = this.navBarService.subTitle;
    this.backLink$ = this.navBarService.backLink;
    this.isAuthorized$ = this.hasAuthorization$();
    this.additionalData$ = this.navBarService.additionalData;
    this.isSearchCasePermissionGet$ = this.isSearchCasePermission$;
    this.isOperationalAnalyticsPermissionGet$ = this.isOperationalAnalyticsPermission$;
    this.showLoadingMask();
  }

  hasAuthorization$(): Observable<boolean> {
    // Code for terms, replace existing code with commented code when enable terms
    // return this.currentUser && this.displayNavBar && this.authService.getTermsStatus();

    return combineLatest([this.currentUser$, this.navBarService.enableNavigation]).pipe(
      takeUntil(this.ngUnsubscribe),
      map((values) => Boolean(values[0] && values[1])) // Check if both are truthy
    );
  }

  private showLoadingMask(): void {
    const { updatingVirtualGenePanel, loadingCaseAndPatient } = this.caseService;
    this.loaderService.showLoadingMask(
      [updatingVirtualGenePanel, loadingCaseAndPatient],
      this.ngUnsubscribe
    );
  }

  updateVirtualGenePanel(event: any, caseId: string): void {
    const vgpId = event.value;

    this.caseService
      .updateVirtualGenePanel(vgpId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.variantService.clearVariantListETag();
          this.caseService.loadCase(caseId);
        }
      });
  }

  get isSearchCasePermission$(): Observable<boolean> {
    return this.hasAuthorization$().pipe(
      map(
        (isAuthorized) =>
          isAuthorized && this.authService.hasPermission(SessionLinkKeys.SEARCH_CASE)
      )
    );
  }

  get isOperationalAnalyticsPermission$(): Observable<boolean> {
    return this.hasAuthorization$().pipe(
      map(
        (isAuthorized) =>
          isAuthorized && this.authService.hasPermission(SessionLinkKeys.OPERATIONAL_ANALYTICS)
      )
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
