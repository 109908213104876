import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';

// Code for terms, will be used for alpha 3, do not remove

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent implements OnInit {
  enableButton = false;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.getTermsStatus()) {
      this.proceedNavigation();
    }
    this.commonService.setTitle('License agreement');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    // scrollbar reaches bottom
    if (window.scrollY + window.innerHeight > document.body.offsetHeight) {
      this.enableButton = true;
    }
  }

  acceptTerms(): void {
    this.authService.acceptTerms();
    this.proceedNavigation();
  }

  private proceedNavigation(): void {
    const redirectURL = this.authService.popRedirectURL();
    if (redirectURL) {
      this.router.navigateByUrl(redirectURL);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
