import { Injectable } from '@angular/core';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import {
  Criteria,
  Analytics,
  VariantAnalytics,
  CategoryOrVariant
} from 'app/model/entities/analytics';
import { mergeMap, finalize, map, filter } from 'rxjs/operators';
import { CommonService } from './common.service';
import { CaseService } from './case.service';
import { get as _get } from 'lodash';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class AnalyticsService {
  private _categoryOrVariantList: BehaviorSubject<CategoryOrVariant[]> = new BehaviorSubject<
    CategoryOrVariant[]
  >([]);
  private _analytics: BehaviorSubject<Analytics> = new BehaviorSubject<Analytics>(null);
  private _variantAnalytics: BehaviorSubject<VariantAnalytics> =
    new BehaviorSubject<VariantAnalytics>(null);

  private _loadingAnalytics: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingVariantAnalytics: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingCategoryOrVariantList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _analyticsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _variantAnalyticsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _categoryOrVariantListError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  get variantAnalytics(): Observable<VariantAnalytics> {
    return this._variantAnalytics.asObservable();
  }

  get categoryOrVariantList(): Observable<CategoryOrVariant[]> {
    return this._categoryOrVariantList.asObservable();
  }

  get analytics(): Observable<Analytics> {
    return this._analytics.asObservable();
  }

  get loadingVariantAnalytics(): Observable<boolean> {
    return this._loadingVariantAnalytics.asObservable();
  }

  get loadingAnalytics(): Observable<boolean> {
    return this._loadingAnalytics.asObservable();
  }

  get loadingCategoryOrVariantList(): Observable<boolean> {
    return this._loadingCategoryOrVariantList.asObservable();
  }

  get categoryOrVariantListError(): Observable<boolean> {
    return this._categoryOrVariantListError.asObservable();
  }

  get variantAnalyticsError(): Observable<boolean> {
    return this._variantAnalyticsError.asObservable();
  }

  get analyticsError(): Observable<boolean> {
    return this._analyticsError.asObservable();
  }

  loadCategoryOrVariantList() {
    const link = this.authService.getURL(SessionLinkKeys.LIST_CATEGORIES_AND_VARIANTS);

    if (!link) {
      return;
    }

    this._loadingCategoryOrVariantList.next(true);
    this._categoryOrVariantListError.next(false);

    this.http
      .get<any>(link)
      .pipe(finalize(() => this._loadingCategoryOrVariantList.next(false)))
      .subscribe({
        next: (json) => this._categoryOrVariantList.next(json),
        error: () => this._categoryOrVariantListError.next(true)
      });
  }

  loadVariantAnalytics(criteria: Criteria = {}) {
    const link = this.authService.getURL(SessionLinkKeys.VIEW_VARIANT_ANALYTICS);

    if (!link) {
      return;
    }
    const updatedCriteria = this.convertCriteria(criteria);
    this._loadingVariantAnalytics.next(true);
    this._variantAnalyticsError.next(false);

    this.caseService.loadDiagnosis().subscribe();

    const httpReq = this.http.post<VariantAnalytics>(link, updatedCriteria);
    httpReq
      .pipe(
        mergeMap((json: VariantAnalytics) => this.caseService.renameDiagnosis(json, 'cards')),
        finalize(() => this._loadingVariantAnalytics.next(false))
      )
      .subscribe({
        next: (json) => this._variantAnalytics.next(json),
        error: () => this._variantAnalyticsError.next(true)
      });
  }

  loadAnalytics(criteria: Criteria = {}) {
    const link = this.authService.getURL(SessionLinkKeys.OPERATIONAL_ANALYTICS);

    if (!link) {
      return;
    }
    const updatedCriteria = this.convertCriteria(criteria);

    this._loadingAnalytics.next(true);
    this._analyticsError.next(false);
    this.http
      .post<any>(link, updatedCriteria)
      .pipe(finalize(() => this._loadingAnalytics.next(false)))
      .subscribe({
        next: (json: Analytics) => this._analytics.next(json),
        error: () => this._analyticsError.next(true)
      });
  }

  /**
   *  Convert criteria before sending to backend:
   *  1) Convert date fields to the format accepted by backend
   *  2) Remove fields with default value (id === null) so that backend handles those fields with default values
   *  3) Add timeZone;
   */
  private convertCriteria(criteria: Criteria): Criteria {
    const updatedCriteria = {};
    Object.keys(criteria).forEach((key) => {
      if (key === 'startDate' || key === 'endDate') {
        updatedCriteria[key] = this.commonService.formatDateISO(criteria[key]);
      } else if (_get(criteria[key], 'id', false) !== null) {
        updatedCriteria[key] = criteria[key];
      } else if (key === 'diagnosis' && _get(criteria[key], 'externalId', false) !== null) {
        updatedCriteria[key] = criteria[key];
      }
    });
    updatedCriteria['timeZone'] = this.authService.localeData.timeZone;

    return updatedCriteria;
  }
}
