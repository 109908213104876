import { BaseEntity } from './baseEntity';

export class CivicAnnotation extends BaseEntity {
  civicUrl: any;
  variantSummary: string;
  numEvidenceMatched: number;
  numEvidenceInAll: number;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
