import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { tap, finalize, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isEqual as _isEqual, has as _has, get as _get } from 'lodash';

import { AuthService } from 'app/services/auth.service';
import { TrialFilter } from '../model/entities/trialFilter';
import { ClinicalTrial } from '../model/entities/clinicalTrial';

@Injectable()
export class TrialService {
  private _currentFilter: BehaviorSubject<TrialFilter> = new BehaviorSubject<TrialFilter>(null);
  private _trialList: BehaviorSubject<ClinicalTrial[]> = new BehaviorSubject<ClinicalTrial[]>([]);
  private _lastUpdateTimestamp: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _showClinicalTrials: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _trialExclusions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _trialStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _trialUnrecognized: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private _loadingClinicalTrials: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadingShowClinicalTrials: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _loadingTrialExclusions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _updatingShowClinicalTrials: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _updatingTrialExclusions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _trialListError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadShowClinicalTrialsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _updateShowClinicalTrialsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _loadTrialExclusionsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _updateTrialExclusionsError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _revisionData: any;
  private _filterLinks: any;
  private _metaDataLinks: any;
  private _matchedGeneTerms: BehaviorSubject<String[]> = new BehaviorSubject<String[]>([]);
  private _matchedVariantTerms: BehaviorSubject<String[]> = new BehaviorSubject<String[]>([]);
  private _unmatchedVariantTerms: BehaviorSubject<String[]> = new BehaviorSubject<String[]>([]);
  private _isTrialResultExpanded = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient, private authService: AuthService) {}

  get trialExclusions() {
    return this._trialExclusions.asObservable();
  }

  get showClinicalTrials() {
    return this._showClinicalTrials.asObservable();
  }

  get currentFilter() {
    return this._currentFilter.asObservable();
  }

  get trialList() {
    return this._trialList.asObservable();
  }

  get lastUpdateTimestamp() {
    return this._lastUpdateTimestamp.asObservable();
  }

  get trialStatus() {
    return this._trialStatus.asObservable();
  }

  get trialUnrecognized() {
    return this._trialUnrecognized.asObservable();
  }

  get genesBiomarkers() {
    return this._matchedGeneTerms.asObservable();
  }

  get variantsBiomarkers() {
    return this._matchedVariantTerms.asObservable();
  }

  get unmatchedBiomarkers() {
    return this._unmatchedVariantTerms.asObservable();
  }

  get loadingClinicalTrials() {
    return this._loadingClinicalTrials.asObservable();
  }

  get loadingShowClinicalTrials() {
    return this._loadingShowClinicalTrials.asObservable();
  }

  get loadingTrialExclusions() {
    return this._loadingTrialExclusions.asObservable();
  }

  get updatingShowClinicalTrials() {
    return this._updatingShowClinicalTrials.asObservable();
  }

  get updatingTrialExclusions() {
    return this._updatingTrialExclusions.asObservable();
  }

  get trialListError() {
    return this._trialListError.asObservable();
  }

  get loadShowClinicalTrialsError() {
    return this._loadShowClinicalTrialsError.asObservable();
  }

  get loadTrialExclusionsError() {
    return this._loadTrialExclusionsError.asObservable();
  }

  get updateShowClinicalTrialsError() {
    return this._updateShowClinicalTrialsError.asObservable();
  }

  get updateTrialExclusionsError() {
    return this._updateTrialExclusionsError.asObservable();
  }

  get isTrialResultExpanded$(): Observable<boolean> {
    return this._isTrialResultExpanded.asObservable();
  }

  public static generateDefaultTrialFilter(caseData): TrialFilter {
    const filter = {
      phases: ['Phase 2', 'Phase 3', 'Phase 4'],
      location: {},
      biomarkers: [],
      excludedBiomarkers: [],
      diagnosis: caseData && caseData.diagnosisName
    };

    if (caseData && caseData.orderer) {
      filter['location'] = TrialService.setDefaultLocation(caseData.orderer);
    }

    // Only set sex filter for 'MALE' and 'FEMALE'. Treat sex 'UNKNOWN' and 'OTHER' as no sex filter.
    if (caseData && caseData.patient && ['MALE', 'FEMALE'].includes(caseData.patient.sex)) {
      filter['sex'] = caseData.patient.sex;
    }

    if (
      caseData &&
      caseData.patient &&
      (caseData.patient.pediatric || caseData.patient.pediatric === false)
    ) {
      filter['pediatric'] = caseData.patient.pediatric;
    }
    return filter;
  }

  public static setDefaultLocation(orderer) {
    if (!orderer || !orderer.country) {
      return {};
    }
    if (orderer.postalCode) {
      return {
        zipcode: orderer.postalCode,
        zipcodeMileRadius: 100,
        country: orderer.country.name
      };
    }
    if (orderer.country.name === 'United States' && orderer.stateOrProvince) {
      return {
        country: orderer.country.name,
        regionCode: orderer.stateOrProvince.name
      };
    }
    return { country: orderer.country.name };
  }

  public hasPermissionForFilter(permission: string): boolean {
    return _has(this._filterLinks, permission);
  }

  public hasPermissionForMetadata(permission: string): boolean {
    return _has(this._metaDataLinks, permission);
  }

  updateFilterAndLoadTrialList(
    caseId,
    filter: TrialFilter,
    variants?: string[],
    defaultFilter?: TrialFilter
  ) {
    this._loadingClinicalTrials.next(true);
    this._trialListError.next(false);
    this._isTrialResultExpanded.next(false);
    return this.updateFilter(caseId, filter, variants, defaultFilter)
      .pipe(finalize(() => this._loadingClinicalTrials.next(false)))
      .subscribe({
        next: (json) => {
          if (!json) {
            return;
          }
          this._revisionData = json['relations'] || {};
          this._currentFilter.next(json['filter']);
          this._trialList.next(json['trials'].map((trial) => new ClinicalTrial(trial)));
          this._lastUpdateTimestamp.next(json['lastModifiedAt']);
          this._trialStatus.next(json['status']);
          this._isTrialResultExpanded.next(json['geneExpand']);
          const unrecognizedMessages = json['unrecognized']
            ? this.concatenateUnrecognized(json['unrecognized'])
            : [];
          this._trialUnrecognized.next(unrecognizedMessages);
          this._matchedGeneTerms.next(json['matchedGeneTerms'] || []);
          this._matchedVariantTerms.next(json['matchedVariantTerms'] || []);
          this._unmatchedVariantTerms.next(json['unmatchedVariantTerms'] || []);
          this.loadTrialExclusions(caseId);
        },
        error: () => {
          this._trialListError.next(true);
          this._trialList.next([]);
        }
      });
  }

  get revisionData() {
    return this._revisionData;
  }

  resetRevisionData() {
    this._revisionData = {};
  }

  loadShowTrialIndicator(caseId: string) {
    this._loadShowClinicalTrialsError.next(false);

    if (!this.authService.hasTrialsAccess()) {
      this._showClinicalTrials.next(false);
      return;
    }

    this._loadingShowClinicalTrials.next(true);

    return this._http
      .get(this.authService.getURL('viewTrialMetadata', { caseId }))
      .pipe(finalize(() => this._loadingShowClinicalTrials.next(false)))
      .subscribe({
        next: (json) => {
          this._metaDataLinks = json['_links'];
          this._showClinicalTrials.next(json && json['show']);
        },
        error: () => {
          this._loadShowClinicalTrialsError.next(true);
        }
      });
  }

  loadTrialExclusions(caseId: string) {
    this._loadingTrialExclusions.next(true);
    this._loadTrialExclusionsError.next(false);

    return this._http
      .get(this.authService.getURL('viewTrialMetadata', { caseId }))
      .pipe(finalize(() => this._loadingTrialExclusions.next(false)))
      .subscribe({
        next: (json) => this._trialExclusions.next((json && json['exclusions']) || []),
        error: () => this._loadTrialExclusionsError.next(true)
      });
  }

  updateShowTrialIndicator(caseId: string, show: any) {
    this._updatingShowClinicalTrials.next(true);
    this._updateShowClinicalTrialsError.next(false);
    return this._http
      .put(this.authService.getURL('createOrEditTrialMetadata', { caseId }), show)
      .pipe(finalize(() => this._updatingShowClinicalTrials.next(false)))
      .subscribe({
        next: (json) => this._showClinicalTrials.next(json && json['show']),
        error: () => {
          this._showClinicalTrials.next(this._showClinicalTrials.value);
          this._updateShowClinicalTrialsError.next(true);
        }
      });
  }

  updateTrialExclusions(caseId: string, exclusions: any) {
    this._updatingTrialExclusions.next(true);
    this._updateTrialExclusionsError.next(false);
    return this._http
      .put(this.authService.getURL('createOrEditTrialMetadata', { caseId }), exclusions)
      .pipe(finalize(() => this._updatingTrialExclusions.next(false)))
      .subscribe({
        next: (json) => this._trialExclusions.next((json && json['exclusions']) || []),
        error: () => {
          this._trialExclusions.next(this._trialExclusions.value);
          this._updateTrialExclusionsError.next(true);
        }
      });
  }

  resetShowClinicalTrials() {
    this._showClinicalTrials.next(false);
  }

  /**
   * @param caseId
   * @param filter: new filter (null if it is an update caused by variantList changes)
   * @param variants: new variants names (null if it is an update caused by filter changes)
   * @returns {Observable<R>|Observable<Object>}
   */
  private updateFilter(
    caseId: string,
    filter: TrialFilter,
    variants: string[],
    defaultFilter: TrialFilter
  ) {
    if (variants) {
      return this.updateTrialFilterByVariants(caseId, variants, defaultFilter);
    }
    return this.updateTrialFilterByFilter(filter);
  }

  private updateTrialFilterByFilter(filter: TrialFilter) {
    if (this.hasPermissionForFilter('createOrEditTrialFilter')) {
      return this._http.put(this._filterLinks.createOrEditTrialFilter.href, filter);
    } else {
      return observableOf(null);
    }
  }

  private updateTrialFilterByVariants(
    caseId: string,
    variants: string[],
    defaultFilter: TrialFilter
  ) {
    let isFilterChanged = false;
    return this.loadTrialFilterAndResults(caseId).pipe(
      mergeMap((response: any) => {
        let newFilter = null;
        const json = <TrialFilter>response.filter;

        if (!json) {
          defaultFilter.biomarkers = variants;
          newFilter = defaultFilter;
          isFilterChanged = true;
        } else {
          const variantsInFilter = json.biomarkers.slice().sort((a, b) => a.localeCompare(b));
          let variantsIncluded = variants.slice().sort((a, b) => a.localeCompare(b));
          if (json.excludedBiomarkers && json.excludedBiomarkers.length > 0) {
            variantsIncluded = variantsIncluded.filter(
              (variant) => !json.excludedBiomarkers.includes(variant)
            );
          }
          if (!_isEqual(variantsInFilter, variantsIncluded)) {
            json.biomarkers = variantsIncluded;
            isFilterChanged = true;
          }
          // refresh sex and pediatric in filter if user changes them in case data
          if (json.sex !== defaultFilter.sex) {
            if (!defaultFilter.sex) {
              delete json.sex;
            } else {
              json.sex = defaultFilter.sex;
            }
            isFilterChanged = true;
          }

          if (json.pediatric !== defaultFilter.pediatric) {
            if (!defaultFilter.pediatric && defaultFilter.pediatric !== false) {
              delete json.pediatric;
            } else {
              json.pediatric = defaultFilter.pediatric;
            }
            isFilterChanged = true;
          }
          newFilter = json;
        }
        return isFilterChanged ? this.updateTrialFilterByFilter(newFilter) : observableOf(response);
      })
    );
  }

  private loadTrialFilterAndResults(caseId) {
    return this._http
      .get<any>(this.authService.getURL('viewTrialFilter', { caseId }))
      .pipe(tap((response: any) => (this._filterLinks = response._links)));
  }

  private concatenateUnrecognized(unrecognizedList: string[]) {
    return unrecognizedList.map(
      (unrecognized) =>
        `"${Object.keys(unrecognized)
          .map((key) => unrecognized[key])
          .filter((value) => value !== null)
          .join(', ')}"`
    );
  }
}
