import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AuthService } from 'app/services/auth.service';
import { CommonService } from 'app/services/common.service';
import { Okta } from 'app/services/okta.service';

/**
 * This is to get around typescript complaining about "Cannot find name 'Optanon'"".
 * `Optanon` is OneTrust library class and we are accessing it to use ToggleInfoDisplay() function to manually display cookie pop-up screen.
 */
declare const Optanon: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  oktaWidget;
  private ngUnsubscribe = new Subject<void>();
  public showEvalMessage: boolean;
  public showRuoMessage: boolean;
  public showBetaMessage: boolean; // For now this is the same as Eval

  constructor(
    private okta: Okta,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.showEvalMessage = this.authService.showEvalMessage;
    this.showRuoMessage = this.authService.showRuoMessage;
    this.showBetaMessage = this.authService.showBetaMessage;
  }

  ngOnInit(): void {
    this.commonService.setTitle('Log on');
    this.authService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currentUser) => {
      if (currentUser) {
        this.router.navigateByUrl('/');
      } else {
        this.showWidget(this.activatedRoute.snapshot.queryParams.recoveryToken);
      }
    });
  }

  onToggleCookieInfoDisplay(): void {
    Optanon.ToggleInfoDisplay();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.oktaWidget) {
      this.oktaWidget.remove();
    }
  }

  showWidget(recoveryToken?: string): void {
    this.oktaWidget = this.okta.createWidget(recoveryToken);

    this.oktaWidget.renderEl({ el: '#okta-login-container' }, (response) => {
      if (response.status === 'SUCCESS') {
        response.session.setCookieAndRedirect(this.authService.ssoUrl);
      }
    });
  }
}
