import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-variant-pin-icon',
  templateUrl: './variant-pin-icon.component.html',
  styleUrls: ['./variant-pin-icon.component.scss']
})
export class VariantPinIconComponent {
  @Input() isAdded: boolean;
}
