import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable()
export class DialogService {
  private _dialog: BehaviorSubject<any> = new BehaviorSubject<any>('');
  private _customDialog: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _dialogResult: Subject<boolean>;
  private _customDialogResult: Subject<any>;

  constructor(private dialogRef: MatDialog) {}

  get dialog(): Observable<unknown> {
    return this._dialog.asObservable();
  }

  get customDialog(): Observable<unknown> {
    return this._customDialog.asObservable();
  }

  open(dialog): Observable<boolean> {
    this._dialog.next(dialog);
    this._dialogResult = new Subject();
    return this._dialogResult.asObservable();
  }

  openCustomDialog(dialog): Observable<any> {
    this._customDialog.next(dialog);
    this._customDialogResult = new Subject();
    return this._customDialogResult.asObservable();
  }

  setDialogResult(result: boolean): void {
    this._dialogResult.next(result);
    this._dialogResult.complete();
  }

  setCustomDialogResult(results): void {
    this._customDialogResult.next(results);
    this._customDialogResult.complete();
  }

  closeAllDialogs(): void {
    this.dialogRef.closeAll();
  }
}
