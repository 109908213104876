<div [style.width.px]="size" [style.height.px]="size">
  <svg class="doughnut-percentages-svg" [attr.width]="size" [attr.height]="size">
    <circle *ngIf="percentages[0]!==undefined" class="base" [attr.r]="r1" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c1"></circle>
    <circle *ngIf="percentages[0]!==undefined" class="bar1" [attr.r]="r1" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c1" [attr.stroke-dashoffset]="(1-percentages[0])*c1"></circle>
    <circle *ngIf="percentages[1]!==undefined" class="base" [attr.r]="r2" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c2"></circle>
    <circle *ngIf="percentages[1]!==undefined" class="bar2" [attr.r]="r2" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c2" [attr.stroke-dashoffset]="(1-percentages[1])*c2"></circle>
    <circle *ngIf="percentages[2]!==undefined" class="base" [attr.r]="r3" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c3"></circle>
    <circle *ngIf="percentages[2]!==undefined" class="bar3" [attr.r]="r3" [attr.cx]="x" [attr.cy]="y" fill="transparent" [attr.stroke-dasharray]="c3" [attr.stroke-dashoffset]="(1-percentages[2])*c3"></circle>
  </svg>
</div>
