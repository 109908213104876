import {
  of as observableOf,
  BehaviorSubject,
  Observable,
  forkJoin as observableForkJoin
} from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pick as _pick } from 'lodash';

import { AuthService } from 'app/services/auth.service';
import { AppSettingsValues } from 'app/model/entities/appSettingsValues';
import { ReportSettingsValues } from 'app/model/entities/reportSettingsValues';
import { ReportSettings } from 'app/model/entities/reportSettings';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class AppSettingsService {
  private _settingsValues: AppSettingsValues;
  private _settingsValuesLoading = new BehaviorSubject<boolean>(false);
  private _reportSettingsValuesLoading = new BehaviorSubject<boolean>(false);
  private _reportSettingsValues: ReportSettingsValues;
  private _reportSettingsLoading = new BehaviorSubject<boolean>(false);
  private _reportSettingsError = new BehaviorSubject<boolean>(false);
  private _reportSettings = new BehaviorSubject<ReportSettings>(null);
  private _saving = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get settingsValuesLoading(): Observable<boolean> {
    return this._settingsValuesLoading.asObservable();
  }

  get reportSettingsValuesLoading(): Observable<boolean> {
    return this._reportSettingsValuesLoading.asObservable();
  }

  get reportSettingsLoading(): Observable<boolean> {
    return this._reportSettingsLoading.asObservable();
  }

  get reportSettings(): Observable<ReportSettings> {
    return this._reportSettings.asObservable();
  }

  get saving(): Observable<boolean> {
    return this._saving.asObservable();
  }

  loadSettingsValues(): Observable<AppSettingsValues> {
    if (this._settingsValues) {
      return observableOf(this._settingsValues);
    } else {
      this._settingsValuesLoading.next(true);

      return this.http
        .get<AppSettingsValues>(this.authService.getURL(SessionLinkKeys.LIST_APP_SETTINGS_VALUES))
        .pipe(
          tap((json: AppSettingsValues) => (this._settingsValues = json)),
          finalize(() => this._settingsValuesLoading.next(false))
        );
    }
  }

  loadReportSettingsValues(): Observable<ReportSettingsValues> {
    if (this._reportSettingsValues) {
      return observableOf(this._reportSettingsValues);
    } else {
      this._reportSettingsValuesLoading.next(true);

      return this.http
        .get<ReportSettingsValues>(
          this.authService.getURL(SessionLinkKeys.LIST_REPORT_SETTINGS_VALUES)
        )
        .pipe(
          tap((json: ReportSettingsValues) => (this._reportSettingsValues = json)),
          finalize(() => this._reportSettingsValuesLoading.next(false))
        );
    }
  }

  loadReportSettings() {
    this._reportSettingsLoading.next(true);

    this.http
      .get<ReportSettings>(this.authService.getURL(SessionLinkKeys.VIEW_REPORT_SETTINGS))
      .pipe(finalize(() => this._reportSettingsLoading.next(false)))
      .subscribe({
        next: (json: any) => {
          this._reportSettings.next(json);
          this._reportSettingsError.next(false);
        },
        error: () => this._reportSettingsError.next(true)
      });
  }

  save(data: any): Observable<any> {
    this._saving.next(true);

    const observables = [];

    if (this.authService.hasPermission(SessionLinkKeys.EDIT_APP_SETTINGS)) {
      const appSettingsData = _pick(data, [
        'language',
        'dateFormat',
        'timeFormat',
        'timeZone',
        'decimalPlace',
        'thousandSeparator'
      ]);

      observables.push(
        this.http.put<any>(
          this.authService.getURL(SessionLinkKeys.EDIT_APP_SETTINGS),
          appSettingsData
        )
      );
    }

    if (this.authService.hasPermission(SessionLinkKeys.EDIT_REPORT_SETTINGS)) {
      const reportSettingsData = _pick(data, 'paperSize');

      observables.push(
        this.http.put<any>(
          this.authService.getURL(SessionLinkKeys.EDIT_REPORT_SETTINGS),
          reportSettingsData
        )
      );
    }

    if (observables.length === 0) return observableOf(null);

    return observableForkJoin(observables).pipe(finalize(() => this._saving.next(false)));
  }
}
