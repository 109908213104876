import { Component } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html'
})
export class ToasterComponent {
  toasterconfig: ToasterConfig = new ToasterConfig({
    showCloseButton: true,
    tapToDismiss: false,
    timeout: 5000,
    animation: 'fade',
    newestOnTop: true,
    typeClasses: {
      error: 'toast-error',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning'
    },
    closeHtml: `<button style='padding: 0; cursor: pointer; background: transparent; border: 0; -webkit-appearance: none; color: white; position: absolute; right: 10px; top: 10px' type='button'>x</button>`
  });
}
