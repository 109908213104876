import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-timezone-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timezone-display.component.html'
})
export class TimezoneDisplayComponent {
  @Input() timeZone: string;
}
