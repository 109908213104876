<div
  [ngClass]="{
    error: type === errorBarType.error,
    warning: type === errorBarType.warning,
    info: type === errorBarType.info
  }"
  fxLayout="row"
  fxLayoutAlign="start"
>
  <span class="icon-container" fxLayout="column" fxLayoutAlign="start center">
    <mat-icon *ngIf="type === errorBarType.info; else cautionIcon">info</mat-icon>
    <ng-template #cautionIcon>
      <mat-icon>alarm</mat-icon>
    </ng-template>
  </span>
  <div fxFlex class="text-container">
    <ng-content></ng-content>
  </div>
</div>
