import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { SharedModule } from './shared/shared.module';
import { RoutingModule } from './routing.module';
import { CoreModule } from './core.module';
import { DomainModule } from './domain/domain.module';

import { AppComponent } from './app.component';

import { AuthService } from './services/auth.service';
import { appInitializerFactory } from './providers/app-initializer.provider';
import { Localization } from 'app/model/valueObjects/localization';
import { localizationFactory } from './providers/localization.provider';
import { ApiInterceptor } from './api-interceptor';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { i18nMatPaginator } from './providers/paginator-i18n.provider';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    DomainModule,
    RoutingModule // Routing Module has to be at the last (Ref: https://angular.io/guide/router#module-import-order-matters)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: Localization,
      useFactory: localizationFactory,
      deps: [AuthService]
    },
    // APP_BASE_HREF - https://github.com/angular/angular-cli/issues/9835#issuecomment-369684361
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    Title,
    {
      provide: MatPaginatorIntl,
      useValue: i18nMatPaginator()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
