<mat-form-field oneFormField class="full-width">
  <mat-icon matPrefix>date</mat-icon>
  <mat-label>{{placeholder}}</mat-label>
  <input matInput [formControl]="dateControl" (blur)="onBlur($event)" maxlength="10" spellcheck="false" appNoAutoComplete>
  <mat-hint class="secondary-text">{{formatDisplay}}</mat-hint>
  <app-error-icon *ngIf="dateControl.invalid && dateControl.touched" matSuffix></app-error-icon>
  <mat-error *ngIf="dateControl.hasError('pattern')">
    <ng-container i18n="Enter the number in specified format only|Error message for date format validation@@error-date-format">Enter numbers in the form</ng-container> {{formatDisplay}}
  </mat-error>
  <mat-error *ngIf="dateControl.hasError('invalidDate')"
            i18n="Date is invalid|Error message which will appear if user enter's invalid date, for ex: 02/31/2017@@error-invalid-date">Invalid date</mat-error>
  <mat-error *ngIf="dateControl.hasError('futureDateValidation')"
            i18n="Date should be today or less|Error message indicating future date is not allowed@@error-future-date">Must be in the past</mat-error>
  <mat-error *ngIf="dateControl.hasError('pastDateValidation')"
            i18n="Date should be today or greater|Error message indicating past date is not allowed@@error-past-date">Must be in the future</mat-error>
  <mat-error *ngIf="dateControl.hasError('required')"
            i18n="Validation for required field|Message to be displayed as a validation@@required-field-validation">This field is required</mat-error>
</mat-form-field>
