import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() userName: string;
  @Input() avatarSize: number; // diameter in pixels

  get avatarInitials(): string {
    if (!this.userName) return '';

    const words = this.userName.split(/[\s]+/);
    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }
    return `${words[0][0]}${words[1][0]}`.toUpperCase();
  }

  get avatarSizePixels(): string {
    return this.avatarSize + 'px';
  }

  get fontSizePixels(): string {
    return this.avatarSize / 2 + 'px';
  }
}
