import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-variant-last-name',
  templateUrl: './variant-last-name.html'
})
export class VariantLastNameComponent {
  @Input() last: string;
  @Input() truncateLength = 41;

  constructor() {}
}
