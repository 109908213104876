<div>
  <button class="settings-menu-button" [matMenuTriggerFor]="menu" mat-flat-button one-topbar-profile-button>
    <mat-icon>user</mat-icon>
    <span>{{userFullName}}</span>
  </button>
  <mat-menu #menu="matMenu" oneMenu xPosition="before">
    <div class="menu">
      <div class="profile-menu-topbar word-wrap">
        <app-avatar [avatarSize]="40" [userName]="userFullName" class="avatar-pic-container"></app-avatar>
        <div class="info-profile-menu-topbar">
          <div class="name">{{userFullName}}</div>
          <span class="mat-caption">{{email}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <!-- TODO: Change routerLink below to "/account" once account info page is ready -->
      <button mat-menu-item routerLink="/account/assay" i18n="SettingsMenu.Button@@your-account">Your account</button>
      <button *ngIf="hasViewPatientPermission$ | async" mat-menu-item (click)="togglePHI()">
        <ng-container *ngIf="(isPHIhidden$ | async); else hidePHIText" i18n="SettingsMenu.Button@@show-phi">Show PHI</ng-container>
        <ng-template #hidePHIText i18n="SettingsMenu.Button@@hide-phi">Hide PHI</ng-template>
      </button>
      <button mat-menu-item (click)="openHelpLink()" i18n="Help.Button@@help">Help</button>
      <button mat-menu-item routerLink="/about" i18n="SettingsMenu.Button@@about">About</button>
      <button mat-menu-item routerLink="/logoff" i18n="SettingsMenu.Button@@log-off">Log off</button>
    </div>
  </mat-menu>
</div>
