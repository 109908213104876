import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {
  transform(value: string, maxLength = 41): string {
    if (!value) {
      return;
    }

    const charLength = value.length;
    const thirtyBasePairsCharacterCount = maxLength;

    if (charLength > thirtyBasePairsCharacterCount) {
      const transformedValue = value.slice(0, thirtyBasePairsCharacterCount);

      return `${transformedValue}...`;
    }

    return value;
  }
}
