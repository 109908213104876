import { get as _get } from 'lodash';

export class Logo {
  logo: string;
  logoMetadata: string;
}

export function createLogoSrc(logo: Logo): string {
  const logoMetadata = _get(logo, 'logoMetadata');
  const logoData = _get(logo, 'logo');
  return logoMetadata && logoData ? `${logoMetadata},${logoData}` : null;
}
