import { Observable, Subject, combineLatest, fromEvent } from 'rxjs';

import { map, takeUntil } from 'rxjs/operators';
import { isEmpty as _isEmpty, times as _times } from 'lodash';
import { CommonService } from 'app/services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ElementRef,
  ViewChild,
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy
} from '@angular/core';

export const LOGO_MIN_WIDTH = 34;
export const LOGO_MIN_HEIGHT = 34;
export const LOGO_MAX_WIDTH = 300;
export const LOGO_MAX_HEIGHT = 180;

export const MAX_IMAGE_FILESIZE = 1000000;

interface LogoErrors {
  big?: boolean;
  small?: boolean;
  filesize?: boolean;
}

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assayForm: FormGroup;
  @Input() editable = false;
  @Input() data;
  @Input() canUpdateLogo?: boolean;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  fieldLengths = CommonService.fieldLengths;
  logoErrors: LogoErrors;
  firstLogoError: string;
  logoSrc: string;
  ngUnsubscribe: Subject<void> = new Subject();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    combineLatest([
      this.assayForm.get('logo').valueChanges,
      this.assayForm.get('logoMetadata').valueChanges
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([logo, logoMetadata]) => {
        if (this.editable && !logo) {
          this.fileInput.nativeElement.value = '';
          this.firstLogoError = this.getFirstLogoError();
        }

        this.logoSrc = this.makeLogoSrc();
      });
  }

  ngOnChanges(): void {
    if (!this.editable) {
      if (this.assayForm) {
        this.assayForm.reset();
      } else {
        this.assayForm = this.fb.group({
          logo: null,
          logoMetadata: null,
          addressLine1: null,
          addressLine2: null,
          addressLine3: null,
          rocheLogo: null
        });
      }
      if (this.data && this.data.reportTemplate) {
        this.assayForm.patchValue(this.data.reportTemplate);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onFileChange(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => {
        const base64imageWithMetadata = <string>reader.result;
        this.checkImage(base64imageWithMetadata, file.size).subscribe((errors) => {
          if (!errors) {
            const [imageMetadata, base64image] = base64imageWithMetadata.split(',');
            this.assayForm.patchValue({
              logo: base64image,
              logoMetadata: imageMetadata
            });
          } else {
            this.assayForm.get('logo').setErrors(errors);
            this.assayForm.get('logo').setValue('invalid');
            this.firstLogoError = this.getFirstLogoError();
          }
        });
      });
    }
  }

  getFirstLogoError(): string {
    const logoCtrl = this.assayForm.get('logo');
    return logoCtrl.errors && Object.keys(logoCtrl.errors)[0];
  }

  checkImage(base64Image, size): Observable<LogoErrors | null> {
    const img = new Image();
    img.src = base64Image;
    return fromEvent(img, 'load').pipe(
      map(() => {
        const errors: LogoErrors = {};

        if (img.width > LOGO_MAX_WIDTH || img.height > LOGO_MAX_HEIGHT) {
          errors.big = true;
        } else if (img.width < LOGO_MIN_WIDTH || img.height < LOGO_MIN_HEIGHT) {
          errors.small = true;
        }
        if (size > MAX_IMAGE_FILESIZE) {
          errors.filesize = true;
        }

        return _isEmpty(errors) ? null : errors;
      })
    );
  }

  makeLogoSrc(): string {
    return `${this.assayForm.get('logoMetadata').value},${this.assayForm.get('logo').value}`;
  }

  clearFile(): void {
    this.assayForm.get('logo').setValue(null);
    this.assayForm.get('logoMetadata').setValue(null);
  }

  avoidSubmit($event): void {
    $event.preventDefault();
  }
}
