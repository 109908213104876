import { AnnotationSource } from './annotationSource';
import { AssayAnnotationSource } from './assayAnnotationSource';

export class AdminAnnotationSource extends AnnotationSource {
  datasourceId: string;
  createdAt: string;
  releasedAt: string;
  location?: string;

  static fromAssay(source: AssayAnnotationSource): AdminAnnotationSource {
    const as = new AdminAnnotationSource(source);
    as.datasourceId = source.datasourceId;
    as.releasedAt = source.releasedAt;
    return as;
  }
}
