// Utilities for handling base64 and other binary/unicode-to-text encoding and decoding

// developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export function base64encode(raw: string): string {
  return btoa(
    encodeURIComponent(raw).replace(/%([0-9A-F]{2})/g, (unused, hex) =>
      String.fromCharCode(Number.parseInt(hex, 16))
    )
  );
}

export function base64decode(encoded: string): string {
  return decodeURIComponent(
    atob(encoded) // base 64
      .split('') // to char[]
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)) // url-encoded
      .join('')
  );
}

export function storableFrom(obj: any): string {
  return base64encode(JSON.stringify(obj));
}

export function fromStorable<T>(dehydrated: string): T {
  return JSON.parse(base64decode(dehydrated));
}
