<span class="error-message hint-message" [ngSwitch]="saveError">
  <span
    *ngSwitchCase="'SAVE_ERR_409'"
    i18n="SaveErrorMessage.Notes@@click-refresh-to-see-latest-content-and-continue-editing"
    >Click refresh to see latest content and continue editing.</span
  >
  <span *ngSwitchCase="'SAVE_ERR_NOT_304'" i18n="SaveErrorMessage.Notes@@unable-to-save"
    >Unable to save.</span
  >
  <span
    *ngSwitchCase="'CAN_NOT_CLOSE'"
    i18n="SaveErrorMessage.Notes@@can-t-close-populated-summary"
    >Can't close populated summary</span
  >
</span>
