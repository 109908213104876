import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Mate } from 'app/model/entities/mate';

@Component({
  selector: 'app-breakpoint-popup',
  templateUrl: './breakpoint-popup.component.html',
  styleUrls: ['./breakpoint-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakpointPopupComponent {
  @Input() data: Mate[];
  displayedColumns = [
    'breakPoints',
    'supportingReadsCount',
    'supportingReadsFraction',
    'qualityScore'
  ];

  constructor(private dialogRef: MatDialogRef<BreakpointPopupComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
