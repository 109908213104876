import { ListItem } from './baseEntity';

export interface AppSettingsValues {
  language: ListItem[];
  dateFormat: string[];
  timeFormat: string[];
  timeZone: string[];
  decimalPlace: DecimalPlace[];
  thousandSeparator: ThousandSeparator[];
}

/**
 * @enum {DecimalPlace, ThousandSeparator} needs to be sync with the BE values
 */

export enum DecimalPlace {
  PERIOD = 'period',
  COMMA = 'comma'
}

export enum ThousandSeparator {
  COMMA = 'comma',
  SPACE = 'space',
  PERIOD = 'period',
  APOSTROPHE = 'apostrophe'
}
