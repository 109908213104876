import { Component, OnInit, OnDestroy } from '@angular/core';

import { CommonService } from 'app/services/common.service';
import { NavBarService } from 'app/services/nav-bar.service';

@Component({
  selector: 'app-system-unavailable-page',
  templateUrl: './system-unavailable-page.component.html',
  styleUrls: ['./system-unavailable-page.component.scss']
})
export class SystemUnavailablePageComponent implements OnInit, OnDestroy {
  constructor(private commonService: CommonService, private navBarService: NavBarService) {}

  ngOnInit(): void {
    this.commonService.setTitle('System unavailable');
    this.navBarService.setNavigationEnabled(false);
  }

  ngOnDestroy(): void {
    this.navBarService.setNavigationEnabled(true);
  }
}
