<form [formGroup]="caseSummaryForm" (ngSubmit)="saveSummary()">
  <mat-form-field oneFormField class="container-case-summary">
    <mat-label>
      <app-ruo-ivd-mapper>
        <ng-container IVD i18n="CaseSummaryInput.Label@@report-summary">Report summary</ng-container>
        <ng-container RUO i18n="CaseSummaryInput.Label@@analysis-report-summary">Analysis report summary</ng-container>
      </app-ruo-ivd-mapper>
    </mat-label>
    <textarea matInput formControlName="summary" maxlength={{fieldLengths.summaryField}} rows="4"> </textarea>
    <mat-hint align="start">{{additionalHint}}</mat-hint>
    <mat-hint align="end" ngPreserveWhitespaces fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <save-error-message *ngIf="caseNoteError || (!additionalHint && caseCloseDisableError)"
        [saveError]="caseNoteError || caseCloseDisableError"></save-error-message>
      <span *ngIf="saved && isSummarySaved$ | async" class="hint-message" i18n="CaseSummaryInput.Hint@@changes-saved">changes saved</span>
      <span class="summary-counts">{{caseSummary?.length}} / {{fieldLengths.summaryField}}</span>
    </mat-hint>
  </mat-form-field>
  <div class="full-width" fxLayoutAlign="end center" fxLayoutGap="24px">
    <button mat-stroked-button type="button" class="close-button" *ngIf="!caseSummary && isSummarySaved$ | async"
      (click)="closeSummary()">
      <span i18n="Close|Button title@@close">Close</span>
    </button>
    <button type="submit" mat-raised-button one-secondary-button class="save-button" [disabled]="isCaseSaveBtnDisabled$ | async">
      <span i18n="Save|Button title@@save">Save</span>
    </button>
  </div>
</form>
