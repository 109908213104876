import { BaseEntity } from './baseEntity';
import { AnnotationDisease } from './annotationDisease';

export enum CosmicAnnotationType {
  SmallVariant,
  Fusion,
  CNV
}

export class CosmicAnnotation extends BaseEntity {
  urls?: {};
  confirmedSomatic?: boolean;
  variantText?: string;
  geneSymbol?: string;
  mutationType?: string;
  firstTissue: string;
  firstTissueCount: number;
  firstMutationCount: number;
  secondTissue: string;
  secondTissueCount: number;
  secondMutationCount: number;
  thirdTissue: string;
  thirdTissueCount: number;
  thirdMutationCount: number;
  diseases: AnnotationDisease[];

  constructor(jsonData: any) {
    super(jsonData);
    this.diseases = [];
    if (jsonData.firstTissue) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.firstTissue,
          jsonData.firstTissueCount,
          jsonData.firstMutationCount
        )
      );
    }

    if (jsonData.secondTissue) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.secondTissue,
          jsonData.secondTissueCount,
          jsonData.secondMutationCount
        )
      );
    }

    if (jsonData.thirdTissue) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.thirdTissue,
          jsonData.thirdTissueCount,
          jsonData.thirdMutationCount
        )
      );
    }
  }
}
