import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OneCardModule, OneExpandableModule } from '@one/angular-kit/layout';
import { MatCardModule } from '@angular/material/card';
import { OneFormFieldModule, OneSelectDropdownModule } from '@one/angular-kit/form';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { OneMenuModule, OneFooterModule } from '@one/angular-kit/navigation';
import { OneIconModule } from '@one/angular-kit/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { OneTableModule } from '@one/angular-kit/data-table';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { OneEditableDropdownModule } from '@one/angular-kit/form';
import { OneStaticBannerModule } from '@one/angular-kit/modal';

const modules = [
  FormsModule,
  MatCardModule,
  ReactiveFormsModule,
  OneIconModule,
  OneCardModule,
  OneFormFieldModule,
  OneTableModule,
  OneTopbarModule,
  MatToolbarModule,
  OneSelectDropdownModule,
  OneExpandableModule,
  OneEditableDropdownModule,
  MatTableModule,
  CdkTableModule,
  MatCheckboxModule,
  MatSortModule,
  MatMenuModule,
  OneMenuModule,
  MatTabsModule,
  OneFooterModule,
  OneStaticBannerModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class OneDesignModule {}
