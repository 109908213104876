import { ClassificationType } from './classificationType';

export type TierName = 'IA' | 'IB' | 'IIC' | 'IID' | 'III' | 'IV' | 'Unclassified';

export const TIER_LIST = ['IA', 'IB', 'IIC', 'IID', 'III'];

export enum TierNames {
  IA = 'IA',
  IB = 'IB',
  IIC = 'IIC',
  IID = 'IID',
  III = 'III',
  IV = 'IV',
  UNCLASSIFIED = 'Unclassified'
}

export const TIER_NUMBER = {
  IA: 1,
  IB: 1,
  IIC: 2,
  IID: 2,
  III: 3,
  IV: 4,
  Unclassified: undefined
};

export const AMP_TIERS = {
  IA: ClassificationType.Significant,
  IB: ClassificationType.Significant,
  IIC: ClassificationType.Significant,
  IID: ClassificationType.Significant,
  III: ClassificationType.Unclassified,
  IV: ClassificationType.FilteredOut,
  Unclassified: ClassificationType.Unclassified
};

export const AMPTIER_CHANGE_REASONS = {
  NO_EXISTING_OR_ROCHE_CLASSIFICATION_AVAILABLE: 'No existing or Roche classification available',
  NEW_DRUG_APPROVALS: 'New drug approvals',
  REGION_SPECIFIC_CLASSIFICATION: 'Region-specific classification',
  NEW_CLINICAL_EVIDENCE: 'New clinical evidence',
  DIFFERENT_ASSESSMENT_OF_EXISTING_EVIDENCE: 'Different assessment of existing evidence',
  FUNCTIONAL_EVIDENCE: 'Functional evidence',
  INTERNAL_EVIDENCE: 'Internal evidence',
  OTHER: 'Other'
};
