import { Directive, ElementRef } from '@angular/core';
import { random as _random } from 'lodash';

@Directive({
  selector: '[appNoAutoComplete]'
})
export class NoAutoCompleteDirective {
  constructor(private el: ElementRef) {
    // Chrome currently doesn't honor `autocomplete="off"` (Ref: https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164)
    // Hence, as a workaround, setting the value to random string `doNotAutoComplete`. (Ref: https://stackoverflow.com/a/34998771)
    // In some cases `name` attribute is also needed to be set in addition to ^: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion

    const attributeMap = {
      autocomplete: 'doNotAutoComplete',
      name: _random(0, Number.MAX_SAFE_INTEGER).toString()
    };
    Object.entries(attributeMap).forEach(([prop, value]) =>
      el.nativeElement.setAttribute(prop, value)
    );
  }
}
