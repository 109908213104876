import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from 'app/services/auth.service';
import { AppBuildValues } from 'app/model/entities/appBuildValues';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class AppBuildService {
  private _cairLiteBuildValues = new BehaviorSubject<AppBuildValues>(new AppBuildValues());
  private _treatmentAppBuildValues = new BehaviorSubject<AppBuildValues>(new AppBuildValues());

  constructor(private http: HttpClient, private authService: AuthService) {}

  get cairLiteBuildValues(): Observable<AppBuildValues> {
    return this._cairLiteBuildValues.asObservable();
  }

  get currentAppVersion(): Observable<string> {
    return this.cairLiteBuildValues.pipe(
      map((liteValues: AppBuildValues) => {
        if (liteValues.buildVersion) {
          // return "<major>.<minor>" version number
          return liteValues.buildVersion.split('.').slice(0, 2).join('.');
        }
      })
    );
  }

  get treatmentAppBuildValues(): Observable<AppBuildValues> {
    return this._treatmentAppBuildValues.asObservable();
  }

  loadCairLiteBuildValues() {
    this.http
      .get<AppBuildValues>(this.authService.getURL(SessionLinkKeys.CAIR_LITE_BUILD))
      .subscribe((json) => this._cairLiteBuildValues.next(json));
  }

  loadTreatmentAppBuildValues() {
    this.http
      .get<AppBuildValues>(this.authService.getURL(SessionLinkKeys.TREATMENT_BUILD))
      .subscribe((json) => this._treatmentAppBuildValues.next(json));
  }
}
