import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, IANAZone, Settings } from 'luxon';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  static setDefaultTimezone(timezone: string) {
    Settings.defaultZone = new IANAZone(timezone);
  }

  transform(value: string, format: string): string {
    if (!value) {
      return;
    }

    // Lower case for 'am/pm'. If 'AM/PM' support is required in the
    // future then we may need another solution.
    // https://github.com/moment/luxon/issues/224
    // https://stackoverflow.com/q/69738300
    return DateTime.fromISO(value).toFormat(format).toLowerCase();
  }
}
