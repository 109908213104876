import { Component, Input } from '@angular/core';
import { VariantName } from 'app/model/entities/annotatedVariant';

@Component({
  selector: 'app-variant-title',
  templateUrl: './variant-title.component.html',
  styles: [
    `
      .expand-bio {
        font-weight: bold;
      }
    `
  ]
})
export class VariantTitleComponent {
  @Input() name: VariantName[];
  @Input() multipleLines = false;
  @Input() showAlias = true;
  // expandBiomarker - Due to requirement in IST-732, IST-589
  // We want to show an expanded name in variant cards
  @Input() expandBiomarker = false;
  @Input() boldFirstName = true;
  @Input() truncateLength = 41;

  displayExpandedBioMarker(firstName: string): boolean {
    return (
      this.expandBiomarker && ['MSS', 'MSI', 'TMB', 'HRDsig', 'Genomic LOH'].includes(firstName)
    );
  }
}
