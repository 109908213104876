<one-footer *ngIf="authService.isAuthenticated" id="footer" class="no-print">
  <one-footer-content class="footer-content">
    <div class="page-container mat-caption">
      <div class="cookie-policy-container">
        <a href="https://www.roche.com/cookie-notice/" rel="noopener" target="_blank"
          i18n="FooterBar.CookiePolicy@@cookie-policy">Cookie Policy</a> |
        <!-- OneTrust Cookies Settings button start -->
        <a id="ot-sdk-btn" class="ot-sdk-show-settings" i18n="FooterBar.CookiePolicy@@cookie-settings">Cookie
          Settings</a>
        <!-- OneTrust Cookies Settings button end -->
      </div>
      <div *ngIf="(currentUserCanRuo$ | async)">
        <a (click)="ruoModal.open()" class="ruo-link">
          <app-translation translationKey="RUO_MESSAGE"></app-translation>
        </a>
        <app-ruo-popup></app-ruo-popup>
      </div>
    </div>
  </one-footer-content>
</one-footer>

