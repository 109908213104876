import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ruo-ivd-mapper',
  templateUrl: './ruo-ivd-mapper.component.html'
})
export class RuoIvdMapperComponent implements OnInit {
  currentUserCanRuo$: Observable<boolean>;

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.currentUserCanRuo$ = this.authService.currentUserCanRuo;
  }
}
