import { Component, OnDestroy, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-user-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-name.component.html'
})
export class UserNameComponent implements OnChanges, OnDestroy {
  @Input() userID: string;
  @Input() displayFullName = false;
  private ngUnsubscribe = new Subject<void>();
  displayName: Observable<string>;
  userNotFound: Observable<boolean>;

  constructor(private authService: AuthService) {}

  ngOnChanges(): void {
    if (this.displayFullName) {
      this.displayName = this.authService.getUserFullName(this.userID);
    } else {
      this.displayName = this.authService.getUserName(this.userID);
    }

    this.userNotFound = this.displayName.pipe(
      map((displayName) => displayName === AuthService.UNAVAILABLE)
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
