import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, defer, Subscription } from 'rxjs';
import { finalize, map, repeatWhen, delay, filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'app/services/auth.service';
import { UpgradeStatus, UpgradeStatusResponse } from 'app/model/valueObjects/upgradeStatus';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class UpgradeService {
  private _upgradeStatus = new BehaviorSubject<UpgradeStatus>(null);
  private _upgradeStatusLoading = new BehaviorSubject<boolean>(false);
  private _startUpgradeLoading = new BehaviorSubject<boolean>(false);
  private _pollSubscription: Subscription;
  private readonly _pollInterval = 10000;
  private currentClientVersion$ = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private authService: AuthService) {}

  get currentClientVersionGetter$(): Observable<string> {
    return this.currentClientVersion$.asObservable();
  }

  get parsedCurrentClientVersionString$(): Observable<string> {
    return this.currentClientVersionGetter$.pipe(
      map((currentClientVersion) => this.getParsedVersionString(currentClientVersion))
    );
  }

  get startUpgradeLoading(): Observable<boolean> {
    return this._startUpgradeLoading.asObservable();
  }

  get upgradeStatusLoading(): Observable<boolean> {
    return this._upgradeStatusLoading.asObservable();
  }

  get upgradeStatus(): Observable<UpgradeStatus> {
    return this._upgradeStatus.asObservable().pipe(
      filter((upgradeStatus) => !!upgradeStatus) // Only emit non-null values
    );
  }

  getParsedVersionString(input: string): string {
    const trimmedInput = input.trim();
    const versionRegex = /^(\d+\.\d+)/;
    const match = trimmedInput.match(versionRegex);

    return match ? match[0] : trimmedInput;
  }

  loadUpgradeStatusRequest(): Observable<UpgradeStatusResponse> {
    this._upgradeStatusLoading.next(true);

    return this.http
      .get<UpgradeStatusResponse>(this.authService.getURL(SessionLinkKeys.VIEW_UPGRADE_STATUS))
      .pipe(
        tap((upgradeResponse: UpgradeStatusResponse) => {
          this.currentClientVersion$.next(upgradeResponse.clientOnVersion);
        }),
        finalize(() => this._upgradeStatusLoading.next(false))
      );
  }

  loadUpgradeStatus(): Observable<UpgradeStatus> {
    return this.loadUpgradeStatusRequest().pipe(map((json: UpgradeStatusResponse) => json.status));
  }

  startUpgrade(): Observable<any> {
    this._startUpgradeLoading.next(true);

    return this.http
      .post<any>(this.authService.getURL(SessionLinkKeys.START_UPGRADE), null)
      .pipe(finalize(() => this._startUpgradeLoading.next(false)));
  }

  startPolling() {
    this._upgradeStatus.next(null); // reset from any previously failed attempts

    this._pollSubscription = defer(() => this.loadUpgradeStatus())
      .pipe(repeatWhen((completed) => completed.pipe(delay(this._pollInterval))))
      .subscribe((upgradeStatus) => this._upgradeStatus.next(upgradeStatus));
  }

  stopPolling() {
    if (this._pollSubscription) {
      this._pollSubscription.unsubscribe();
    }
  }
}
