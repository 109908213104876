import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-copy-text-dialog',
  templateUrl: './copy-text-dialog.component.html',
  styleUrls: ['./copy-text-dialog.component.scss']
})
export class CopyTextDialogComponent {
  @Input() title: string;
  @Input() longText: string;
  @Input() show: boolean;
  @Output() visiblityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  copiedSuccessfully: boolean;

  copyToClipboard(): void {
    const copyTextInput = document.createElement('input');
    copyTextInput.setAttribute('type', 'text');
    copyTextInput.value = this.longText;
    copyTextInput.select();
    navigator.clipboard.writeText(copyTextInput.value);
    this.copiedSuccessfully = true;
  }

  visibilityChangeHandler(event: CustomEvent): void {
    this.copiedSuccessfully = false;
    this.show = event.detail;
    this.visiblityChange.emit(this.show);
  }
}
