import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { has as _has } from 'lodash';

import { AuthService } from 'app/services/auth.service';
import { Organization } from 'app/model/entities/organization';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class OrganizationService {
  private _organizations = new BehaviorSubject<Organization[]>(null);
  private _organizationListError = new BehaviorSubject<boolean>(false);
  private _loadingOrganizationList = new BehaviorSubject<boolean>(false);

  private _savingOrganization = new BehaviorSubject<boolean>(false);
  private _currentOrganization = new BehaviorSubject<Organization>(null);
  private _currentOrganizationError = new BehaviorSubject<boolean>(false);
  private _loadingCurrentOrganization = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get organizationList() {
    return this._organizations.asObservable();
  }

  get organizationListError() {
    return this._organizationListError.asObservable();
  }

  get loadingOrganizationList() {
    return this._loadingOrganizationList.asObservable();
  }

  get currentOrganization() {
    return this._currentOrganization.asObservable();
  }

  get savingOrganization() {
    return this._savingOrganization.asObservable();
  }

  get loadingCurrentOrganization() {
    return this._loadingCurrentOrganization.asObservable();
  }

  loadOrganizations() {
    this._loadingOrganizationList.next(true);

    return this.http
      .get(this.authService.getURL(SessionLinkKeys.LIST_ORGANIZATIONS))
      .pipe(finalize(() => this._loadingOrganizationList.next(false)))
      .subscribe(
        (json) => {
          this._organizations.next(json['_embedded'].organizations);
          this._organizationListError.next(false);
        },
        (err) => this._organizationListError.next(true)
      );
  }

  getCurrentOrganizationLink(key: string): string {
    return this._currentOrganization.value._links[key].href;
  }

  hasPermission(permission: string): boolean {
    return _has(this._currentOrganization.value, ['_links', permission]);
  }

  save(orgData: Organization, orgId: string) {
    this._savingOrganization.next(true);

    let request: Observable<any>;
    if (orgId) {
      orgData.adminId = this._currentOrganization.value.adminId;
      request = this.http.put(this.getCurrentOrganizationLink('editOrganization'), orgData);
    } else {
      request = this.http.post(
        this.authService.getURL(SessionLinkKeys.CREATE_ORGANIZATION),
        orgData
      );
    }

    return request.pipe(finalize(() => this._savingOrganization.next(false)));
  }

  loadOrganization(accountId: string) {
    this._loadingCurrentOrganization.next(true);
    this._currentOrganization.next(null);

    return this.http
      .get(this.authService.getURL(SessionLinkKeys.VIEW_ORGANIZATION, { accountId }))
      .pipe(finalize(() => this._loadingCurrentOrganization.next(false)))
      .subscribe(
        (data: Organization) => {
          this._currentOrganizationError.next(false);
          this._currentOrganization.next(data);
        },
        (err) => this._currentOrganizationError.next(true)
      );
  }
}
