import { Component, Input } from '@angular/core';

@Component({
  selector: 'doughnut-percentage',
  templateUrl: './doughnut-percentage.component.html',
  styleUrls: ['./doughnut-percentage.component.scss']
})
export class DoughnutsPercentageComponent {
  @Input() percentage: number;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() skin: 'light' | '' = '';
}
