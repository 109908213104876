import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-window',
  templateUrl: './popup-window.component.html',
  styleUrls: ['./popup-window.component.scss']
})
export class PopupWindowComponent {
  public popupCode: string;
  data: any;
  negativeButton: string;
  positiveButton: string;
  positiveButtonPrimary = false;
  isFinite: Function = Number.isFinite;

  constructor(private dialogRef: MatDialogRef<PopupWindowComponent>) {}

  negativeResult(): void {
    this.dialogRef.close(false);
  }

  positiveResult(): void {
    this.dialogRef.close(true);
  }
}
