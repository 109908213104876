const apiPrefix = '/api';
const apiVersion = '/v2';
const apiV3Version = '/v3';
const apiNavifyPlatformBase = 'https://api.appdevus.platform.navify.com'; // TODO: Remove this hardcoded value from FE and accept from BE
const apiBase = `${apiPrefix}${apiVersion}`;
const apiBaseV3 = `${apiPrefix}${apiV3Version}`;

export const API_GATEWAY_URL = {
  authSession: `${apiBase}/session`,
  authConfig: `${apiBase}/organization/config`,
  platformAuthSession: `${apiBaseV3}/session`,
  platformConfig: `${apiBase}/organization/platformConfig`, // TODO: Double check this on API integration.
  languageFile: `${apiBase}/organization/settings/languageFile`,
  tenantModes: `${apiBase}/organization/tenantModes`,
  platformRefresh: `${apiNavifyPlatformBase}/api/v1/auth/refresh`
};
