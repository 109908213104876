<ng-container>
  <ng-container *ngFor="let bioMarkerName of bioMarkerNames; let isLast = last">
    <app-translation [translationKey]="bioMarkerName"></app-translation>
    <ng-container *ngIf="!isLast">,</ng-container>
    <ng-container ngPreserveWhitespaces> </ng-container>
  </ng-container>
  <ng-container>{{ biomarkerFileReader?.format }}</ng-container>
  <ng-container ngPreserveWhitespaces> </ng-container>
  <ng-container i18n="BiomarkerReaderFile.Notes@@file">file</ng-container>
</ng-container>
