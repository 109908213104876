<h1 mat-dialog-title oneDialogTitle fxFlexAlign="space-between center">
  <span i18n="BreakpointPopup.Title@@break-point-details">BREAK POINT DETAILS</span>
</h1>
<div mat-dialog-content oneDialogContent class="breakpoint-list">
  <div class="header-page-title" i18n="BreakpointPopup.Notes(headerPageTitle)@@this-content-is-from-an-uploaded-file">This content is from an uploaded file</div>
  <one-table>
    <one-table-content>
      <mat-table [dataSource]="data" matSort class="mates-table">
        <ng-container matColumnDef="breakPoints">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header i18n="BreakpointPopup.HeaderCell@@mates">Mates</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <app-fusion-partner-name [breakPoints]="row.breakPoints"></app-fusion-partner-name>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="supportingReadsCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="BreakpointPopup.HeaderCell@@supporting-reads-count">Supporting reads count</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.supportingReadsCount | localeNumber: '0,0'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="supportingReadsFraction">
          <mat-header-cell mat-headear-cell *matHeaderCellDef mat-sort-header i18n="BreakpointPopup.HeaderCell@@supporting-reads-percentage">Supporting reads percentage</mat-header-cell>
          <mat-cell *matCellDef="let row">{{(row.supportingReadsFraction * 100)| localeNumber:'0.00'}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="qualityScore">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n="BreakpointPopup.HeaderCell@@quality-score">Quality score</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.qualityScore | localeNumber:'0,0.000000'}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </one-table-content>
  </one-table>
</div>
<div mat-dialog-actions oneDialogActions class="close-button-container">
  <button mat-raised-button color="primary" (click)="closeDialog()" i18n="BreakpointPopup.Button@@close">Close</button>
</div>

