import { Pipe, PipeTransform } from '@angular/core';
import { getI18nByKey } from 'app/model/valueObjects/i18nTexts';
import { AuthService } from 'app/services/auth.service';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'ruoIvdMapper'
})
export class RuoIvdMapperPipe implements PipeTransform {
  constructor(private readonly authService: AuthService) {}

  transform(ivdI18nKey: string, ruoI18nKey: string): Observable<string | undefined> {
    return this.authService.currentUserCanRuo.pipe(
      map((canRuo) => getI18nByKey(canRuo ? ruoI18nKey : ivdI18nKey))
    );
  }
}
