export enum CaseEventType {
  BULK_VAR_RECLASSIFIED = 'BULK_VAR_RECLASSIFIED',
  CASE_CREATED = 'CASE_CREATED',
  CASE_SENT_FOR_APPROVAL = 'CASE_SENT_FOR_APPROVAL',
  CASE_REJECTED = 'CASE_REJECTED',
  CASE_APPROVED = 'CASE_APPROVED',
  CASE_CANCELED = 'CASE_CANCELED',
  EDIT_CASE_CLINICAL_ORIGINAL = 'EDIT_CASE_CLINICAL_ORIGINAL',
  EDIT_CASE_CLINICAL_COPY = 'EDIT_CASE_CLINICAL_COPY',
  FILTER_EDITED = 'FILTER_EDITED',
  REPORT_SUMMARY_EDITED = 'REPORT_SUMMARY_EDITED',
  VAR_RECLASSIFIED = 'VAR_RECLASSIFIED',
  VAR_ALIAS_CHANGED = 'VAR_ALIAS_CHANGED',
  VAR_ADDED = 'VAR_ADDED',
  VAR_REMOVED = 'VAR_REMOVED',
  VAR_BRIEF_EDITED = 'VAR_BRIEF_EDITED',
  THERAPY_ADDED = 'THERAPY_ADDED',
  THERAPY_REMOVED = 'THERAPY_REMOVED',
  TRIAL_TURNED_OFF = 'TRIAL_TURNED_OFF',
  TRIAL_TURNED_ON = 'TRIAL_TURNED_ON',
  TRIAL_FILTER_EDITED = 'TRIAL_FILTER_EDITED',
  LAB_NOTE = 'LAB_NOTE',
  VARIANT_SUMMARY_TABLE_SHOWN = 'VARIANT_SUMMARY_TABLE_SHOWN',
  VARIANT_SUMMARY_TABLE_HIDDEN = 'VARIANT_SUMMARY_TABLE_HIDDEN', // i.e. show the collapsed view of cards instead.
  VGP_EDITED = 'VGP_EDITED'
}
