import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VARIANT_FILTER_TYPE } from 'app/model/valueObjects/variantFilter';
import { FilterInputData } from '../variant-filter/variant-filter.component';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent {
  type: string;
  data: {
    filterList: FilterInputData[];
    manualMode: boolean;
    editMode: boolean;
    allowApplyFiltersForTierIAndII: boolean;
    initialIgnoreFiltersForTierIAndII: boolean;
    isDefaultFilter?: boolean;
    canEditVariantFilter: boolean;
  };
  variantFilterType = VARIANT_FILTER_TYPE;
  constructor(private dialogRef: MatDialogRef<FilterPopupComponent>) {}

  closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  outputData(filters): void {
    this.closeDialog(filters);
  }
}
