import { BaseEntity } from './baseEntity';

export class Patient extends BaseEntity {
  id: string;
  name?: string;
  medicalRecordNumber?: string;
  dateOfBirth?: string;
  ethnicity?: string;
  sex?: string;
  pediatric?: boolean;

  constructor(patient, patientRevision) {
    super(patient || {});

    if (patientRevision) {
      this.id = patientRevision.id;
      this.sex = patientRevision.sex;
      this.pediatric = patientRevision.pediatric;
    }
  }
}

export class PatientRevision {
  id: string;
  revision: number;

  constructor(json) {
    this.id = json.id;
    this.revision = json.revision;
  }
}
