import { CommonService } from './common.service';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class AuditService {
  constructor(private commonService: CommonService, private authService: AuthService) {}

  downloadAuditLog(dates) {
    const urlExportAuditRecord = this.authService
      .getURL(SessionLinkKeys.EXPORT_AUDIT_RECORD)
      .replace('(startDate)', this.commonService.formatDateISO(dates.startDate))
      .replace('(endDate)', this.commonService.formatDateISO(dates.endDate));

    window.open(urlExportAuditRecord);
  }

  downloadUserMapping() {
    const urlExportUserMapping = this.authService.getURL(SessionLinkKeys.EXPORT_USER_LIST);
    window.open(urlExportUserMapping);
  }
}
