import {
  of as observableOf,
  BehaviorSubject,
  Observable,
  forkJoin as observableForkJoin,
  throwError
} from 'rxjs';
import { finalize, tap, delay, materialize, dematerialize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { get as _get } from 'lodash';
import { DateTime } from 'luxon';

import { AuthService } from 'app/services/auth.service';
import { APIMetaData, APIMetaDataReponse, APIKeyResponse } from 'app/model/entities/apiKey';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

export enum DownloadProcessStatus {
  QUEUED = 'QUEUED',
  BUSY = 'BUSY',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

interface CaseDownloadLastRequest {
  id: string;
  status: DownloadProcessStatus;
  downloadUrl: string;
  expiryTime: string | Date;
}

@Injectable()
export class ApiAccessExportService {
  private _generateAPIKeyLoading = new BehaviorSubject<boolean>(false);
  private _APIMetaLoading = new BehaviorSubject<boolean>(false);
  private _APIMetaLinks: any = {};
  private _errorDownloadApprovedCases = new BehaviorSubject<boolean>(false);
  private _errorDownloadApprovedCasesStatus = new BehaviorSubject<boolean>(false);
  private _generatingApprovedCases = new BehaviorSubject<boolean>(false);
  private _downloadReadyForAllApprovedCases = new BehaviorSubject<boolean>(false);
  private _downloadAllApprovedCasesLink: string;
  private _exportApprovedCasesLink: string;
  private _cancelApprovedCasesLink: string;
  private _caseDownloadLastRequest: CaseDownloadLastRequest = null;
  private _loadingCaseExportStatus = new BehaviorSubject<boolean>(false);

  get loadingCaseExportStatus(): Observable<boolean> {
    return this._loadingCaseExportStatus.asObservable();
  }

  constructor(private http: HttpClient, private authService: AuthService) {}

  get errorDownloadApprovedCases(): Observable<boolean> {
    return this._errorDownloadApprovedCases.asObservable();
  }

  get errorDownloadApprovedCasesStatus(): Observable<boolean> {
    return this._errorDownloadApprovedCasesStatus.asObservable();
  }

  get generatingApprovedCases(): Observable<boolean> {
    return this._generatingApprovedCases.asObservable();
  }

  get downloadReadyForApprovedCases(): Observable<boolean> {
    return this._downloadReadyForAllApprovedCases.asObservable();
  }

  get caseDownloadLastRequest(): CaseDownloadLastRequest {
    return this._caseDownloadLastRequest;
  }

  get generateAPIKeyLoading(): Observable<boolean> {
    return this._generateAPIKeyLoading.asObservable();
  }

  get APIMetaLoading(): Observable<boolean> {
    return this._APIMetaLoading.asObservable();
  }

  loadAPIMeta(): Observable<APIMetaDataReponse> {
    this._APIMetaLoading.next(true);

    return this.http
      .get<APIMetaDataReponse>(this.authService.getURL(SessionLinkKeys.VIEW_API_KEY_METADATA))
      .pipe(
        finalize(() => this._APIMetaLoading.next(false)),
        tap((json: APIMetaDataReponse) => (this._APIMetaLinks = json._links))
      );
  }

  updateAPIMeta(data: APIMetaData, loadingFlag = false): Observable<APIMetaDataReponse> {
    if (loadingFlag) this._APIMetaLoading.next(true);

    return this.http
      .put<APIMetaDataReponse>(this._APIMetaLinks.editApiKeyMetadata.href, data)
      .pipe(finalize(() => this._APIMetaLoading.next(false)));
  }

  generateAPIKey(): Observable<APIKeyResponse> {
    this._generateAPIKeyLoading.next(true);

    return this.http
      .post<APIKeyResponse>(this._APIMetaLinks.createApiKey.href, null)
      .pipe(finalize(() => this._generateAPIKeyLoading.next(false)));
  }

  getDownloadAllApprovedCasesStatus() {
    this._loadingCaseExportStatus.next(true);
    this._generatingApprovedCases.next(false);
    return this.http
      .get<any>(this.authService.getURL(SessionLinkKeys.EXPORT_CASES), {})
      .pipe(finalize(() => this._loadingCaseExportStatus.next(false)))
      .subscribe({
        next: (response) => {
          this._exportApprovedCasesLink = _get(response, [
            '_links',
            SessionLinkKeys.EXPORT_CASES,
            'href'
          ]);

          const lastRequest = response.lastRequest;
          if (lastRequest) {
            const status = lastRequest.exportRequestStatus,
              expiryTime = lastRequest.expiryTime;
            if (status === DownloadProcessStatus.SUCCESS) {
              this._caseDownloadLastRequest = lastRequest;
              const isDownloadUrlExpired = DateTime.now() > DateTime.fromISO(expiryTime);
              this._downloadReadyForAllApprovedCases.next(
                lastRequest.downloadUrl && !isDownloadUrlExpired
              );
              this._downloadAllApprovedCasesLink = this._downloadReadyForAllApprovedCases.value
                ? lastRequest.downloadUrl
                : null;
            } else if (
              status === DownloadProcessStatus.BUSY ||
              status === DownloadProcessStatus.QUEUED
            ) {
              this.markCaseExportInProgress(response);
            } else if (status === DownloadProcessStatus.FAILURE) {
              this._errorDownloadApprovedCases.next(true);
            }
          }
        },
        error: (err) => {
          this._errorDownloadApprovedCasesStatus.next(true);
          return throwError(err);
        }
      });
  }

  private markCaseExportInProgress(response) {
    this._generatingApprovedCases.next(true);
    this._cancelApprovedCasesLink = _get(response, ['_links', 'cancelExportCasesRequest', 'href']);
  }

  exportAllApprovedCases() {
    this._errorDownloadApprovedCases.next(false);
    this._downloadReadyForAllApprovedCases.next(false);
    this._generatingApprovedCases.next(true);
    return this.http.post<any>(this._exportApprovedCasesLink, {}).subscribe({
      next: (res) => {
        this.markCaseExportInProgress(res);
      },
      error: (err) => {
        this._errorDownloadApprovedCases.next(true);
        this._generatingApprovedCases.next(false);
        return throwError(() => err);
      }
    });
  }

  downloadAllApprovedCasesFile() {
    window.open(this._downloadAllApprovedCasesLink);
  }

  cancelApprovedCasesDownload() {
    return this.http.put(this._cancelApprovedCasesLink, {}).pipe(
      tap((res) => {
        this._generatingApprovedCases.next(false);
        this._caseDownloadLastRequest = _get(res, 'lastRequest');
      })
    );
  }
}
