export class Localization {
  language: string;
  dateFormatOriginal: string;
  dateFormat: string;
  dateFormatShort: string;
  dateFormatMonth: string;
  timeFormat: string;
  timeZone: string;
  decimalPlace: string;
  thousandSeparator: string;
}
