import { BaseEntity } from './baseEntity';

export class CuratedAnnotation extends BaseEntity {
  geneSummaries: string[];
  variantSummary: string;
  groupSummaries: string[];
  combinationSummaries: string[];

  constructor(jsonData: any) {
    super(jsonData);

    if (!this.arrayExist(jsonData.geneSummaries)) {
      this.geneSummaries = null;
    }

    if (jsonData.variantSummary) {
      this.variantSummary = jsonData.variantSummary.trim();
    }

    if (!this.arrayExist(jsonData.groupSummaries)) {
      this.groupSummaries = null;
    }

    if (!this.arrayExist(jsonData.combinationSummaries)) {
      this.combinationSummaries = null;
    }
  }

  get isGeneContentEmpty() {
    return !this.geneSummaries;
  }

  get isVariantContentEmpty() {
    return !this.variantSummary && !this.groupSummaries && !this.combinationSummaries;
  }

  private arrayExist(arr) {
    return arr && arr.filter((data) => data && data.trim()).length;
  }
}
