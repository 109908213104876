import { DecimalPlace } from './../model/entities/appSettingsValues';
import { Pipe, PipeTransform } from '@angular/core';
import { ThousandSeparator } from 'app/model/entities/appSettingsValues';
import * as numeral from 'numeral';

export const formatMap = {
  MAX_5_DECIMAL: '0,0.[00000]'
};

export const delimiterCharMapper = {
  comma: ',',
  space: ' ',
  period: '.',
  apostrophe: "'"
};

const locale = 'app-settings';

@Pipe({ name: 'localeNumber' })
export class LocaleNumberPipe implements PipeTransform {
  static registerDelimiters(thousandDelimiter: string, decimalDelimiter: string) {
    // If already registered, then return. No need to re-register
    if (numeral.locales[locale]) {
      return;
    }

    const thousandSeperator = delimiterCharMapper[thousandDelimiter || ThousandSeparator.COMMA];
    const decimalSeperator = delimiterCharMapper[decimalDelimiter || DecimalPlace.PERIOD];

    // Register the 'app-settings' locale to overwrite the separators
    numeral.register('locale', locale, {
      delimiters: {
        thousands: thousandSeperator,
        decimal: decimalSeperator
      }
    });
    // Use newly registered locale
    numeral.locale(locale);
  }

  transform(value: any, format: string): string {
    if (!value && value !== 0) {
      return '';
    }

    const num = Number(value);
    if (Number.isNaN(num) || !format) {
      return 'Invalid';
    }

    //pattern: two significant figures and at most two decimals
    //corner case1: if value is truely 0, then show 0
    //corner case2: if value rounds to 0, then show 0.00
    //12%, 1.2%, 0.12%, 0.10%, 0.00%, 0
    // Usage: vaf, dbNSFP
    if (format === 'TWO_SIG_FIG') {
      if (value >= 10 || value === 0 || value <= -10) {
        // If value >= 10 or value <= -10 or value = 0
        format = '0,0';
      } else if (value >= 1 || value <= -1) {
        // If 1 =< value < 10 or -10 < value <= -1
        format = '0.0';
      } else {
        // If -1 < value < 1
        format = '0.00';
      }
    }

    // Use value from formatMap if found
    format = formatMap?.[format] ?? format;

    return numeral(num).format(format);
  }

  static reset() {
    delete numeral.locales[locale];
    numeral.locale('en');
    numeral.reset();
  }
}
