import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { BreakPoint } from 'app/model/entities/mate';

@Component({
  selector: 'app-fusion-partner-name',
  templateUrl: './fusion-partner-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FusionPartnerNameComponent {
  @Input() breakPoints: BreakPoint[];
}
