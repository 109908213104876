<div
  class="notification-box"
  [matTooltip]="'case-activity' | ruoIvdMapper:'research-case-activity' | async"
  oneTooltip
  [class.small-size]="smallSize"
>
  <ng-container *ngIf="!hasError; else errorDisplay">{{
    count > COUNT_THRESHOLD ? COUNT_THRESHOLD + '+' : count
  }}</ng-container>
  <ng-template #errorDisplay> - </ng-template>
</div>
