import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit {
  @Input() userFullName: string;
  @Input() email?: string;
  hasViewPatientPermission$: Observable<boolean>;
  isPHIhidden$: Observable<boolean>;

  get hasViewPatientPermissionGetter$(): Observable<boolean> {
    return of(this.authService.hasPermission('viewPatient'));
  }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.hasViewPatientPermission$ = this.hasViewPatientPermissionGetter$;
    this.isPHIhidden$ = this.authService.hidePHI;
  }

  openHelpLink(): void {
    this.authService.openHelpLink();
  }

  togglePHI(): void {
    this.authService.togglePHI();
  }
}
