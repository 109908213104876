import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';

import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class GenesService {
  private _genes = [];
  private _loadingGenes = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get loadingGenes() {
    return this._loadingGenes.asObservable();
  }

  loadGenes() {
    if (this._genes.length > 0) {
      return observableOf(this._genes);
    } else {
      this._loadingGenes.next(true);

      return this.http.get<any>(this.authService.getURL(SessionLinkKeys.LIST_GENES)).pipe(
        tap((json: []) => (this._genes = json)),
        finalize(() => this._loadingGenes.next(false))
      );
    }
  }
}
