// CoreModule with providers for the singleton services you load when the application starts.
// Should consist entirely of providers and have no declarations.
// Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
// https://angular.io/guide/ngmodule-faq#coremodule

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CommonService } from './services/common.service';
import { AutoLogoutService } from './services/auto-logout.service';
import { VariantService } from './services/variant.service';
import { CaseService } from './services/case.service';
import { LabNoteService } from './services/lab-note.service';
import { AssayService } from './services/assay.service';
import { CountriesService } from './services/countries.service';
import { CaseListService } from './services/case-list.service';
import { ToasterService } from './services/toaster.service';
import { FileUploadService } from './services/file-upload.service';
import { CaseNavigationService } from './services/case-navigation.service';
import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';
import { Okta } from './services/okta.service';
import { AuthGuard } from './guards/auth.guard';
import { TrialService } from './services/trial.service';
import { ApprovalService } from './services/approval.service';
import { GenesService } from './services/genes.service';
import { UserService } from './services/user.service';
import { NavBarService } from './services/nav-bar.service';
import { DialogService } from './services/dialog.service';
import { AnnotationSourcesService } from './services/annotationSources.service';
import { AppSettingsService } from './services/app-settings.service';
import { OrganizationService } from 'app/services/organization.service';
import { AuditService } from 'app/services/audit.service';
import { AppBuildService } from './services/app-build.service';
import { PertinentNegativeDatabase } from './services/pertinentNegative.database';
import { BiomarkersService } from './services/biomarkers.service';
import { ClinicalRegionsService } from './services/clinical-regions.service';
import { CustomIconService } from './services/custom-icon.service';
import { AnalyticsService } from './services/analytics.service';
import { DateFormatPipe } from './pipes/date-format-pipe';
import { DataSharingService } from './services/data-sharing.service';
import { UpgradeService } from './services/upgrade.service';
import { SearchService } from './services/search.service';
import { ApiAccessExportService } from './services/api-access-export.service';
import { BrowserStorageService } from './services/browser-storage.service';
import { DiagnosisService } from './services/diagnosis.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { TherapyProcessorService } from './services/therapy-processor.service';
import { TmbMsiThresholdVersionService } from './services/tmb-msi-threshold-version.service';

@NgModule({
  imports: [HttpClientModule, CommonModule],
  providers: [
    CommonService,
    VariantService,
    TrialService,
    CaseService,
    AssayService,
    CountriesService,
    GenesService,
    CaseListService,
    ToasterService,
    FileUploadService,
    CaseNavigationService,
    LoaderService,
    AuthService,
    ApprovalService,
    UserService,
    Okta,
    AuthGuard,
    NavBarService,
    DialogService,
    AutoLogoutService,
    AnnotationSourcesService,
    AppSettingsService,
    OrganizationService,
    AuditService,
    AppBuildService,
    PertinentNegativeDatabase,
    BiomarkersService,
    ClinicalRegionsService,
    DiagnosisService,
    TmbMsiThresholdVersionService,
    LabNoteService,
    CustomIconService,
    AnalyticsService,
    DateFormatPipe,
    DataSharingService,
    UpgradeService,
    SearchService,
    FeatureFlagService,
    ApiAccessExportService,
    BrowserStorageService,
    TherapyProcessorService
  ]
})
export class CoreModule {}
