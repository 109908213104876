<ng-container
      *ngIf="showRuo; else ivdDisclaimer"
      i18n="DisclaimerFirstLine.Notes@@disclaimer-first-line-message1"
    >
    The information available in this report is obtained from third-party sources (such as biomedical literature, medical guidelines, and publicly available data such as drug labels and clinical trials) and is subject to change over time based on future findings (including scientific and medical research).
    </ng-container>
    <ng-template #ivdDisclaimer>
      <ng-container i18n="DisclaimerFirstLine.Notes@@disclaimer-first--message2"
        >Decisions on patient care and treatment must be based on the independent medical judgment
        of the treating physician, taking into consideration all applicable information concerning
        the patient’s condition, including information not contained in this report such as other
        potentially clinically significant information. The information available in this report is
        obtained from third-party sources (such as biomedical literature, medical guidelines, and
        publicly available data such as drug labels and clinical trials) and is subject to change
        over time based on future scientific and medical findings. The selection of any, all or none
        of the potential therapies identified in this report resides entirely within the discretion
        of the treating physician, and this report makes no promises or guarantees that such
        potential therapies will be effective in the treatment of any patient.</ng-container
      >
    </ng-template>
