import { Coordinate, HRD_LEVEL, QC_STATUS } from './coordinate';
import { BaseEntity } from './baseEntity';
import { VariantDisplayField } from './variantDisplayField';
import { Properties } from './properties';
import { Therapy, AssociatedTherapyDisplay } from './therapy';
import { VariantMetaData } from './variantMetaData';
import { VariantBrief } from './variantBrief';
import { ClinicalAnnotation } from './clinicalAnnotation';
import { CuratedAnnotation } from './curatedAnnotation';
import { DbnsfpAnnotation } from './dbnsfpAnnotation';
import { TIER_NUMBER } from 'app/model/valueObjects/ampTier';
import { ClinvarAnnotation } from './clinvarAnnotation';
import { PopFreqAnnotation } from './popFreqAnnotation';
import {
  VARIANT_CLASS_VALUE,
  VARIANT_DISPLAY_FIELD_TYPE
} from '../valueObjects/variantDisplayFieldType';
import { get as _get } from 'lodash';

export interface VariantMap {
  [caseVariantId: string]: AnnotatedVariant;
}

export interface VariantName {
  first: string;
  last: string;
  alias?: string;
}

export interface ReportAnnotatedVariant extends AnnotatedVariant {
  associatedTherapies?: Array<AssociatedTherapyDisplay>;
  currentDiagnosisTherapies?: Array<Therapy>;
  displayNCCNNote?: boolean;
}

export enum RearrangementTypes {
  GENE_FUSION = 'GENE_FUSION',
  INTRAGENIC_DELETION = 'INTRAGENIC_DELETION',
  INTRAGENIC_DUPLICATION = 'INTRAGENIC_DUPLICATION',
  INTRAGENIC_INVERSION = 'INTRAGENIC_INVERSION',
  FIVE_PRIME_PARTNERLESS_REARRANGEMENT = 'FIVE_PRIME_PARTNERLESS_REARRANGEMENT',
  THREE_PRIME_PARTNERLESS_REARRANGEMENT = 'THREE_PRIME_PARTNERLESS_REARRANGEMENT',
  PARTIAL_GENE_DELETION = 'PARTIAL_GENE_DELETION',
  PARTIAL_GENE_DUPLICATION = 'PARTIAL_GENE_DUPLICATION'
}

export interface GeneRearrangementBreakpoint {
  chromosome: string;
  position: number;
}

export interface GeneRearrangementSymbolTranscriptId {
  symbol: string;
  transcriptId: string;
}

export interface GeneRearrangement {
  type: RearrangementTypes;
  fivePrime: {
    breakpoint: GeneRearrangementBreakpoint;
    gene?: GeneRearrangementSymbolTranscriptId;
  };
  threePrime: {
    breakpoint: GeneRearrangementBreakpoint;
    gene?: GeneRearrangementSymbolTranscriptId;
  };
}

export class AnnotatedVariant extends BaseEntity {
  id: string;
  name: VariantName[];
  caseVariantId: string;
  coordinates: Coordinate[];
  displayFields?: VariantDisplayField[];
  exists: string[];
  annotatedWith: string[];
  vafPercentage: number;
  readDepth: number;
  original: Properties;
  current: Properties;
  therapies: Therapy[];
  oncogenicity?: string;
  variantBrief?: VariantBrief;
  metadata?: VariantMetaData;
  clinicalAnnotation?: ClinicalAnnotation;
  curatedAnnotation?: CuratedAnnotation;
  clinvarAnnotation?: ClinvarAnnotation;
  populationFrequency?: PopFreqAnnotation;
  dbnsfpAnnotation?: DbnsfpAnnotation;
  inReportedCombination?: boolean;
  pertinentNegative?: boolean;
  tags: string[];
  geneRearrangement?: GeneRearrangement;
  hasVariantDetails?: boolean = true;

  constructor(jsonData: any) {
    super(jsonData);

    if (jsonData.coordinates) {
      this.coordinates = jsonData.coordinates.map((coordinate) => new Coordinate(coordinate));
    }

    if (jsonData.displayFields) {
      this.displayFields = jsonData.displayFields.map(
        (displayField) => new VariantDisplayField(displayField)
      );
    }

    if (jsonData.original) {
      this.original = new Properties(jsonData.original);
    }
    if (jsonData.current) {
      this.current = new Properties(jsonData.current);
    }
    if (jsonData.variantBrief) {
      this.variantBrief = new VariantBrief(jsonData.variantBrief);
    }
    if (jsonData.metadata) {
      this.metadata = new VariantMetaData(jsonData.metadata);
    }
    if (jsonData.curatedAnnotation) {
      this.curatedAnnotation = new CuratedAnnotation(jsonData.curatedAnnotation);
    }
    if (jsonData.clinicalAnnotation) {
      this.clinicalAnnotation = new ClinicalAnnotation(jsonData.clinicalAnnotation);
    }
    if (jsonData.dbnsfpAnnotation) {
      this.dbnsfpAnnotation = new DbnsfpAnnotation(jsonData.dbnsfpAnnotation);
    }
    this.therapies = jsonData.therapies
      ? jsonData.therapies.map((therapy) => new Therapy(therapy))
      : [];

    const hrdCoordinate = _get(jsonData.coordinates && jsonData.coordinates[0], ['hrd']);

    if (
      hrdCoordinate?.qcStatus === QC_STATUS.FAIL ||
      hrdCoordinate?.level === HRD_LEVEL.UNKNOWN_THRESHOLD
    ) {
      this.hasVariantDetails = false;
    }
  }

  get therapiesInReport(): Therapy[] {
    return this.therapies.filter((therapy) => therapy.includeInReport);
  }

  get isCombination(): boolean {
    return this.coordinates.length > 1;
  }

  get isCombinationWithVariantExcluded(): boolean {
    return this.isCombination && !this.current.dependentsInReport;
  }

  get isAllelicVariant(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].allelicVariant;
  }

  get isCopyNumberVariant(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].copyNumberVariant;
  }

  get isPartialGeneDeletion(): boolean {
    return this.checkFirstCoordinateType('partialGeneDeletion');
  }

  get isPartialGeneDuplication(): boolean {
    return this.checkFirstCoordinateType('partialGeneDuplication');
  }

  get isGeneFusion(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].geneFusion;
  }

  get isWildtype(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].wildtype;
  }

  get isTmb(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].tmb;
  }

  get isHrd(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].hrd;
  }

  get isMsi(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].msi;
  }

  get isGLoh(): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0].gloh;
  }

  get isIntergenic(): boolean {
    return this.isAllelicVariant && !this.coordinates[0].allelicVariant.hugoGene;
  }

  get isUndeterminedGLoh(): boolean {
    return this.isGLoh && this.coordinates[0].gloh.level === 'Undetermined';
  }

  get isWildtypeWithMutantInReport(): boolean {
    return this.isWildtype && !this.current.mutantsNotInReport;
  }

  get isOtherBiomarker(): boolean {
    return this.isTmb || this.isMsi || this.isGLoh || this.isHrd;
  }

  get rocheBrief(): string {
    if (!this.clinicalAnnotation) {
      return '';
    }
    const variantBrief = AnnotatedVariant.formatRocheBrief(this.clinicalAnnotation.variantBriefs);
    const groupBrief = AnnotatedVariant.formatRocheBrief(this.clinicalAnnotation.groupBriefs);
    const combinationBrief = AnnotatedVariant.formatRocheBrief(
      this.clinicalAnnotation.combinationBriefs
    );
    return combinationBrief || variantBrief || groupBrief || '';
  }

  get isFilteredOut(): boolean {
    return (!this.current.report || this.current.isFilteredOut) && !this.isOtherBiomarker;
  }

  static formatRocheBrief(briefs: string[]): string {
    briefs = briefs || [];
    // Backwards compatibility, if api is not handling paragraphs, join with line break.
    if (briefs.find((brief) => !brief.toLowerCase().includes('<p>'))) {
      return briefs.join('\n');
    }

    return briefs.join('');
  }

  get customizedBrief(): string {
    return this.variantBrief ? this.variantBrief.text : null;
  }

  get variantBriefToDisplay(): string {
    return this.customizedBrief || this.rocheBrief;
  }

  get customizedShowBrief(): boolean {
    return this.metadata && (this.metadata.showBrief || this.metadata.showBrief === false)
      ? this.metadata.showBrief
      : null;
  }

  get isRemoved(): boolean {
    return !this.current.report && this.original.report;
  }

  get isAdded(): boolean {
    return this.current.report && !this.original.report;
  }

  get isReclassified(): boolean {
    return this.current.tier !== this.original.tier;
  }

  get variantTags(): string {
    return this.tags ? this.tags.join(', ') : '';
  }

  get tierNumber(): number {
    return TIER_NUMBER[this.current.tier];
  }

  get originalName(): string {
    return this.name.map((n) => (n.last ? `${n.first} ${n.last}` : n.first).trim()).join(', ');
  }

  get annotationDatabases(): string {
    return this.exists.join(', ');
  }

  get numSubmission(): number {
    return this.clinvarAnnotation?.numSubmissions;
  }

  get origin(): string[] {
    return this.clinvarAnnotation?.originSimple;
  }

  get clinicalSignificance(): string {
    return this.clinvarAnnotation?.clinSig.join(', ');
  }

  get alleleFreq(): number {
    return this.populationFrequency?.overallPopFreq;
  }

  get isRearrangement(): boolean {
    return [
      VARIANT_CLASS_VALUE.REARRANGEMENT, // For DNA Gene fusion
      VARIANT_CLASS_VALUE.RNA_EXON_VARIANT // For RNA Gene fusion
    ].includes(
      this.displayFields?.find(
        (displayField) => displayField.type === VARIANT_DISPLAY_FIELD_TYPE.VARIANT_CLASS
      )?.value
    );
  }

  get typeOfRearrangement(): string {
    if (!this.isRearrangement) {
      return '';
    }

    return this.geneRearrangement?.type;
  }

  checkFirstCoordinateType(type: string): boolean {
    return this.coordinates.length === 1 && !!this.coordinates[0][type];
  }
}
