<ng-container [ngSwitch]="value">
  <span i18n="LastUpdated.Notes@@this-page-is-up-to-date" *ngSwitchCase="lastUpdated.up_to_date"
    >This page is up to date</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-1-minute-ago"
    *ngSwitchCase="lastUpdated.one_minute_ago"
    >Last updated: 1 minute ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-5-minutes-ago"
    *ngSwitchCase="lastUpdated.five_minutes_ago"
    >Last updated: 5 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-10-minutes-ago"
    *ngSwitchCase="lastUpdated.ten_minutes_ago"
    >Last updated: 10 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-15-minutes-ago"
    *ngSwitchCase="lastUpdated.fifteen_minutes_ago"
    >Last updated: 15 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-20-minutes-ago"
    *ngSwitchCase="lastUpdated.twenty_minutes_ago"
    >Last updated: 20 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-30-minutes-ago"
    *ngSwitchCase="lastUpdated.thirty_minutes_ago"
    >Last updated: 30 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-45-minutes-ago"
    *ngSwitchCase="lastUpdated.forty_five_minutes_ago"
    >Last updated: 45 minutes ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-an-hour-ago"
    *ngSwitchCase="lastUpdated.an_hour_ago"
    >Last updated: an hour ago</span
  >
  <span
    i18n="LastUpdated.Notes@@last-updated-more-than-an-hour-ago"
    *ngSwitchCase="lastUpdated.more_than_an_hour_ago"
    >Last updated: more than an hour ago</span
  >
</ng-container>
