import { BaseEntity } from './baseEntity';

export interface MateDisplay {
  value: number;
  isPercentDisplay: boolean;
  mate: Mate;
}

export const mateDisplayPercentageMap = {
  supportingReadsCount: false,
  supportingReadsFraction: true,
  supportingReadPairs: false
};

export class Mate extends BaseEntity {
  breakPoints?: BreakPoint[];
  supportingReadsCount?: number;
  supportingReadsFraction?: number;
  supportingReadPairs?: number;
  qualityScore?: number;

  constructor(jsonData: any) {
    super(jsonData);

    if (jsonData.breakPoints) {
      this.breakPoints = jsonData.breakPoints.map((breakPoint) => new BreakPoint(breakPoint));
    }
  }
}

export class BreakPoint extends BaseEntity {
  fusionPartnerName: string;
  chromosome: string;
  position: number;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
