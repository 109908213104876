<div class="chip-pertinent-negative"
     [ngClass]="biomarker.pertinentNegative ? 'chip-pertinent-negative-enabled' : 'chip-pertinent-negative-disabled'"
     matTooltip="Will not appear in the report, click to add back"
     oneTooltip
     [matTooltipDisabled]="biomarker.pertinentNegative" i18n-matTooltip="PertinentNegativeChip.Notes(chipPertinentNegative).MatTooltip@@will-not-appear-in-the-report-click-to-add-back">
  <span class="chip-pertinent-negative-title">{{biomarker.name}}</span>
  <ng-container *ngIf="!reportMode && hasPermissionForEditBiomarkerMetadata$ | async">
    <owc-icon name="circle_clear" type="filled" *ngIf="biomarker.pertinentNegative"
      (click)="togglePertinentNegative()"></owc-icon>
    <owc-icon name="circle_add" type="outlined" *ngIf="!biomarker.pertinentNegative"
      (click)="togglePertinentNegative()"></owc-icon>
  </ng-container>
</div>
