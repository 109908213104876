import { BaseEntity } from './baseEntity';

export class Therapy extends BaseEntity {
  id: string;
  names: string[];
  diagnosisId: string;
  approvals: string[];
  response: string;
  type: string;
  includeInReport: boolean;

  constructor(jsonData: any) {
    super(jsonData.therapy || jsonData);
  }

  get therapyNameString() {
    return this.names.join(' + ');
  }
}

export interface ApprovalBodiesWithDiagnoses {
  approvals: string[];
  diagnosisNames: string[];
  diagnosisIds?: string[];
}

export class AssociatedTherapyDisplay {
  id: string;
  names: string[];
  type: string;
  approvalBodiesWithDiagnoses: ApprovalBodiesWithDiagnoses[] = [];
  includeInReport: boolean;
}
