<owc-modal-dialog
  [visible]="show"
  size="md"
  disable-backdrop-click="false"
  disable-escape-keydown="false"
  disable-scroll-lock="false"
  dense-content="false"
  (visibleChange)="visibilityChangeHandler($event)"
>
  <div slot="header" class="copy-text-dialog-header">
    <span class="truncate-text">
      {{ title | truncateText }}
    </span>
  </div>
  <div class="copy-text-dialog-body">
    {{ longText }}
  </div>
  <div slot="actions" class="copy-text-dialog-footer">
    <span *ngIf="copiedSuccessfully" class="copied-text">Copied to clipboard</span>
    <owc-button variant="secondary" flat (click)="show = false">Close</owc-button>
    <owc-button flat (click)="copyToClipboard()">Copy</owc-button>
  </div>
</owc-modal-dialog>
