import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-generic-owc-menu',
  templateUrl: './generic-owc-menu.component.html',
  styleUrls: ['./generic-owc-menu.component.scss']
})
export class GenericOwcMenuComponent {
  @Input() index: number = 0;
  @Input() hideDefaultIconButton = false;
  @Input() icon = 'more_vertical';
  @Input() type = 'legacy';
  menuVisible: boolean;
}
