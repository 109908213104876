import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { RuoPopupComponent } from '../popup-window/ruo-popup/ruo-popup.component';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit, OnDestroy {
  @ViewChild(RuoPopupComponent)
  ruoModal: RuoPopupComponent;

  private ngUnsubscribe = new Subject<void>();
  currentUserCanRuo$: Observable<boolean>;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.currentUserCanRuo$ = this.authService.currentUserCanRuo;

    /**
     * Code to close the RUO modal when user clicks the cookie consent button. So that we don't have 2 modals open at the same time.
     * This event is raised when the One Trust Preference Center is initially opened.
     * It is fired when the call to open the Preference Center is made and will be raised regardless of the status of the animation
     * (i.e., the event does not wait for the animation to finish before being called).
     * Ref: Cookie Consent JavaScript Events Guide https://my.onetrust.com/s/article/UUID-bc6c634c-b234-acc7-21d3-fa2819a6e679?topicId=0TO1Q000000ssJBWAY
     */
    this.currentUserCanRuo$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((canRUO) => !!canRUO)
      )
      .subscribe(() => window.addEventListener('OneTrustPCLoaded', () => this.ruoModal?.close()));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
