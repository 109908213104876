import { DiagnosisService } from 'app/services/diagnosis.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  get as _get,
  has as _has,
  isEmpty as _isEmpty,
  keyBy as _keyBy,
  pick as _pick,
  sortBy as _sortBy
} from 'lodash';

import { FileUploadService } from 'app/services/file-upload.service';
import { AuthService } from 'app/services/auth.service';
import { Patient, PatientRevision } from 'app/model/entities/patient';
import {
  Case,
  CaseChangeDiagnosisResponseData,
  CaseChangeDiagnosisUpdatedData,
  CaseDisplaySettings,
  Diagnosis
} from 'app/model/entities/case';
import { CaseSubscriber } from 'app/model/entities/case-subscriber';
import { CaseData } from 'app/model/entities/CaseList';
import { CommonService, Replacements } from './common.service';
import { CaseLinkKeys } from 'app/model/valueObjects/caseLinksKeys';
import { AnnotationSourceType } from 'app/model/valueObjects/annotationSourceType';
import { AssayService } from './assay.service';
import { Assay } from 'app/model/entities/assay';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { CaseStatus } from 'app/model/valueObjects/caseStatus';

@Injectable()
export class CaseService {
  private _caseNameList = new BehaviorSubject<any[]>([]);
  private _diagnosisList: Diagnosis[] = [];
  private _diagnosisNamesByDOID: any;
  private _specimenTypes = [];
  private _specimenSites = [];
  private _currentCase = new BehaviorSubject<Partial<Case>>({});
  private _currentCaseError = new BehaviorSubject<boolean>(false);
  private _loadingCaseNameList = new BehaviorSubject<boolean>(false);
  private _loadingCaseAndPatient = new BehaviorSubject<boolean>(false);
  private _saveCaseError = new BehaviorSubject<string>('');
  private _loadingDiagnosis = new BehaviorSubject<boolean>(false);
  private _loadingSpecimenSites = new BehaviorSubject<boolean>(false);
  private _loadingSpecimenTypes = new BehaviorSubject<boolean>(false);
  private _patientRevision: any;
  private _subscribers = new BehaviorSubject<CaseSubscriber[]>([]);
  private _savingSubscribers = new BehaviorSubject<boolean>(false);
  private _loadingSubscribers = new BehaviorSubject<boolean>(false);
  private _loadingSubscribersError = new BehaviorSubject<boolean>(false);
  private _subscriberTargets: CaseSubscriber[] = [];
  private _loadingSubscriberTargets = new BehaviorSubject<boolean>(false);
  private _savingCase = new BehaviorSubject<boolean>(false);
  private _reportLoading = new BehaviorSubject<boolean>(false);
  private _showVariantSummaryTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // Show the table by default
  private _updatingVirtualGenePanel = new BehaviorSubject<boolean>(false);
  private _changingCaseDiagnosis = new BehaviorSubject<boolean>(false);
  private _loadingPatientInfo = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService,
    private authService: AuthService,
    private diagnosisService: DiagnosisService,
    private assayService: AssayService
  ) {}

  static setEmptyStringsToNull(dataObject: any): any {
    // Set empty string values to null
    Object.keys(dataObject).forEach((key) => {
      if (dataObject[key] === '') {
        dataObject[key] = null;
      }
    });

    return dataObject;
  }

  static toEditPage(status: string): boolean {
    return ['NEW', 'UPLOADING', 'FILE_ERROR', 'PROCESSING'].includes(status);
  }

  static getNavURL(status: string, caseId: string): string {
    return CaseService.toEditPage(status) ? `/case/${caseId}/edit` : `/case/${caseId}`;
  }

  get showVariantSummaryTable(): Observable<boolean> {
    return this._showVariantSummaryTable.asObservable();
  }

  /**
   * Fetches the display settings for a given case.
   * @param caseId The id of the case
   * @returns an Observable of null if the link is not present.
   */
  private getCaseDisplaySettings(caseResponse: Case): Observable<CaseDisplaySettings> {
    const VIEW_CASE_DISPLAY_SETTINGS_LINK = CaseLinkKeys.VIEW_CASE_DISPLAY_SETTINGS;
    if (_has(caseResponse, ['_links', VIEW_CASE_DISPLAY_SETTINGS_LINK, 'href'])) {
      const viewDisplaySettingsUrl = _get(caseResponse, [
        '_links',
        VIEW_CASE_DISPLAY_SETTINGS_LINK,
        'href'
      ]);
      return this.http.get<CaseDisplaySettings>(viewDisplaySettingsUrl);
    } else {
      return of(null);
    }
  }

  /**
   * Sets a case's display settings.
   * @param caseId The id of the case.
   * @param showTable Should the case's summary of variants be shown as a table?
   */
  setShowVariantSummaryTable(showTable: boolean): Observable<void> {
    const editDisplaySettingsLink = this.getCurrentCaseLink(
      CaseLinkKeys.EDIT_CASE_DISPLAY_SETTINGS
    );
    const editDisplaySettingsBody = { showVariantSummaryTable: showTable };
    return this.http.put<void>(editDisplaySettingsLink, editDisplaySettingsBody).pipe(
      tap(() => {
        this._showVariantSummaryTable.next(showTable);
      })
    );
  }

  get caseNameList() {
    return this._caseNameList.asObservable();
  }

  get currentCase(): Observable<Partial<Case>> {
    return this._currentCase.asObservable();
  }

  get reportLoading(): Observable<boolean> {
    return this._reportLoading.asObservable();
  }

  get reportLink(): Observable<string> {
    return combineLatest([this.currentCase, this.authService.hidePHI]).pipe(
      map((latestValues: any) => {
        const [currentCase, hidePHI] = latestValues;

        const previewReportLink = _get(currentCase, [
          '_links',
          CaseLinkKeys.PREVIEW_REPORT,
          'href'
        ]);
        if (!previewReportLink) return null;

        const url = new URL(previewReportLink);
        if (hidePHI) {
          url.searchParams.set('hidePHI', 'true');
        } else {
          url.searchParams.delete('hidePHI');
        }

        return url.href;
      })
    );
  }

  getReportBlob(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, { responseType: 'blob' });
  }

  get currentCaseStatus(): string {
    return (this._currentCase.value as Case).status;
  }

  get revisionData() {
    const currentCase: Case = this._currentCase.value as Case;

    const revisionData: any = {
      case: {
        id: currentCase.id,
        revision: currentCase.revision
      }
    };

    if (this._patientRevision) {
      revisionData.patient = this._patientRevision;
    }

    return revisionData;
  }

  get currentCaseError() {
    return this._currentCaseError.asObservable();
  }

  get loadingCaseAndPatient() {
    return this._loadingCaseAndPatient.asObservable();
  }

  get saveCaseError() {
    return this._saveCaseError.asObservable();
  }

  resetErrorCode() {
    this._saveCaseError.next('');
  }

  get loadingCaseNameList() {
    return this._loadingCaseNameList.asObservable();
  }

  get loadingDiagnosis() {
    return this._loadingDiagnosis.asObservable();
  }

  get loadingSpecimenSites() {
    return this._loadingSpecimenSites.asObservable();
  }

  get loadingSpecimenTypes() {
    return this._loadingSpecimenTypes.asObservable();
  }

  get subscribers(): Observable<CaseSubscriber[]> {
    return this._subscribers.asObservable();
  }

  get savingSubscribers(): Observable<boolean> {
    return this._savingSubscribers.asObservable();
  }

  get loadingSubscribers(): Observable<boolean> {
    return this._loadingSubscribers.asObservable();
  }

  get loadingSubscribersError(): Observable<boolean> {
    return this._loadingSubscribersError.asObservable();
  }

  get loadingSubscriberTargets(): Observable<boolean> {
    return this._loadingSubscriberTargets.asObservable();
  }

  get savingCase(): Observable<boolean> {
    return this._savingCase.asObservable();
  }

  get updatingVirtualGenePanel(): Observable<boolean> {
    return this._updatingVirtualGenePanel.asObservable();
  }

  get changingCaseDiagnosis$(): Observable<boolean> {
    return this._changingCaseDiagnosis.asObservable();
  }

  get loadingPatientInfo$(): Observable<boolean> {
    return this._loadingPatientInfo.asObservable();
  }

  get diagnosisListForSelectedCaseAssay$(): Observable<Diagnosis[]> {
    return this.currentCase.pipe(
      mergeMap((currentCase: Case) => {
        const { id: assayId } = { ...currentCase?.assay };
        this.assayService.loadAssay(assayId);
        return this.assayService.currentAssay;
      }),
      filter((currentAssay) => !_isEmpty(currentAssay)),
      tap(({ datasources }: Assay) => {
        const assayRocheContentVersion =
          datasources?.find((datasource) => datasource.type === AnnotationSourceType.ROCHE)
            ?.version || null;

        this.diagnosisService.loadDiagnosis(assayRocheContentVersion);
      }),
      concatMap(() => this.diagnosisService.diagnosis)
    );
  }

  get newPatientId$(): Observable<string | HttpErrorResponse> {
    return this.currentCase.pipe(
      mergeMap((currentCase: Case) => {
        const patientLink = currentCase?.patient?._links?.clonePatient?.href;

        if (!patientLink) {
          return of(null);
        }
        this._loadingPatientInfo.next(true);

        return this.http.post<Patient | HttpErrorResponse>(patientLink, null).pipe(
          map((patientData: Patient) => patientData.id),
          finalize(() => this._loadingPatientInfo.next(false)),
          catchError((error: HttpErrorResponse) => {
            throw error;
          })
        );
      })
    );
  }

  get isCaseIncompatibleToEdit$(): Observable<boolean> {
    return this.currentCase.pipe(
      filter((currentCase: Case) => !!currentCase?.assay?.id),
      concatMap((currentCase) =>
        this.assayService
          .getAssay$(currentCase?.assay?.id)
          .pipe(map((latestAssay) => [currentCase, latestAssay]))
      ),
      map(
        ([currentCase, latestAssay]: [Case, Assay]) =>
          currentCase.status === CaseStatus.FILE_ERROR &&
          latestAssay?.version !== currentCase.assay.version
      )
    );
  }

  getCurrentCaseLink(linkKey: string, replacements?: Replacements): string {
    let url = _get(this, ['_currentCase', 'value', '_links', linkKey, 'href']);
    if (replacements) {
      url = CommonService.replaceInURL(url, replacements);
    }
    return url || '';
  }

  hasPermission(permissionToVerify: string): boolean {
    return _has((this._currentCase.value as Case)._links, permissionToVerify);
  }

  hasPermission$(permissionToVerify: string): Observable<boolean> {
    return this.currentCase.pipe(
      map((currentCaseDetails: any) => _has(currentCaseDetails, ['_links', permissionToVerify]))
    );
  }

  loadReport(): Observable<Blob> {
    // Set it like this because of: https://github.com/angular/angular/issues/18586
    const httpOptions: Object = {
      responseType: 'blob'
    };

    this._reportLoading.next(true);

    return this.reportLink.pipe(
      take(1),
      mergeMap((reportLink: any) => this.http.get<any>(reportLink, httpOptions)),
      map((blob: Blob) => new Blob([blob], { type: 'application/pdf' })),
      finalize(() => this._reportLoading.next(false))
    );
  }

  loadCase(caseId: string, skipPatient = false, caseData = null) {
    let caseDataObservable;

    if (!caseData) {
      this._currentCase.next({});
      this._loadingCaseAndPatient.next(true);

      // TODO in ISTN-5836: use the actual originalId when calling the VIEW_CASE endpoint.
      caseDataObservable = this.http.get(
        this.authService.getURL(CaseLinkKeys.VIEW_CASE, { caseId: caseId, originalId: caseId })
      );
    } else {
      caseDataObservable = of(caseData);
    }

    return caseDataObservable
      .pipe(
        mergeMap((response: Case) => {
          const observables = [
            of(response),
            response._links.viewPatientRevision
              ? this.http.get(response._links.viewPatientRevision.href)
              : of(null),
            this.getCaseDisplaySettings(response) // Also fetch the case's display settings
          ];
          if (!skipPatient && response._links.viewPatient) {
            observables.push(this.http.get<any>(response._links.viewPatient.href));
          }
          return forkJoin(observables);
        }),
        map((results: Array<any>) => {
          const [loadedCase, patientRevision, displaySettings, patient] = results;
          this._patientRevision = !patientRevision ? null : new PatientRevision(patientRevision);
          loadedCase['patient'] =
            !patientRevision && !patient ? null : new Patient(patient, patientRevision);
          this.handleLoadCaseDisplaySettings(displaySettings);
          return loadedCase;
        }),
        finalize(() => this._loadingCaseAndPatient.next(false))
      )
      .subscribe(
        (json) => {
          this._currentCaseError.next(false);
          this._currentCase.next(json);
        },
        (err) => this._currentCaseError.next(true)
      );
  }

  private handleLoadCaseDisplaySettings(displaySettings: CaseDisplaySettings) {
    const showVariantSummaryTable: boolean = !displaySettings
      ? false
      : displaySettings['showVariantSummaryTable'];
    this._showVariantSummaryTable.next(showVariantSummaryTable);
  }

  validateCaseID(caseID) {
    return this.http
      .get(this.authService.getURL('countByCaseId'), {
        params: new HttpParams().append('caseId', caseID)
      })
      .pipe(map((count: number) => count > 0));
  }

  loadDiagnosis() {
    if (this._diagnosisList.length > 0) {
      return of(this._diagnosisList);
    } else {
      this._loadingDiagnosis.next(true);

      return this.http
        .get<Diagnosis[]>(this.authService.getURL(SessionLinkKeys.LIST_DIAGNOSIS))
        .pipe(
          tap((json: Diagnosis[]) => {
            this._diagnosisList = json;
            this._diagnosisNamesByDOID = _keyBy(json, 'externalId');
          }),
          finalize(() => this._loadingDiagnosis.next(false))
        );
    }
  }

  getDiagnosisName(DOID: string): string {
    return this._diagnosisNamesByDOID[DOID] ? this._diagnosisNamesByDOID[DOID].value : DOID;
  }

  loadSpecimenTypes() {
    if (this._specimenTypes.length > 0) {
      return of(this._specimenTypes);
    } else {
      this._loadingSpecimenTypes.next(true);

      return this.http.get<any>(this.authService.getURL(SessionLinkKeys.LIST_SPECIMEN_TYPES)).pipe(
        map((json: any) => _sortBy(json, 'value')),
        tap((json: any) => (this._specimenTypes = json)),
        finalize(() => this._loadingSpecimenTypes.next(false))
      );
    }
  }

  loadSpecimenSites() {
    if (this._specimenSites.length > 0) {
      return of(this._specimenSites);
    } else {
      this._loadingSpecimenSites.next(true);

      return this.http.get<any>(this.authService.getURL(SessionLinkKeys.LIST_SPECIMEN_SITES)).pipe(
        map((json: any) => _sortBy(json, 'value')),
        tap((json: any) => (this._specimenSites = json)),
        finalize(() => this._loadingSpecimenSites.next(false))
      );
    }
  }

  private patientRequest(caseData) {
    const patientFields = ['name', 'medicalRecordNumber', 'ethnicity', 'dateOfBirth', 'sex'];

    const patientData = CaseService.setEmptyStringsToNull(_pick(caseData.patient, patientFields));

    if (caseData.editMode && caseData.patient.id) {
      return this.http.put<any>(
        (this._currentCase.value as Case).patient._links.editPatient.href,
        Object.assign(patientData, { caseId: caseData.id })
      );
    } else if (patientFields.some((field) => caseData.patient[field])) {
      return this.http.post<any>(this.authService.getURL('createPatient') || '', patientData);
    } else {
      return of({});
    }
  }

  private ordererRequest(caseData, patientId) {
    const ordererFields = [
      'country',
      'instituteId',
      'instituteName',
      'orderingPhysicianName',
      'postalCode',
      'stateOrProvince'
    ];

    const ordererData = Object.assign(
      CaseService.setEmptyStringsToNull(_pick(caseData.orderer, ordererFields)),
      { patientId }
    );

    if (caseData.editMode && caseData.orderer.id) {
      return this.http.put<any>(this.getCurrentCaseLink(CaseLinkKeys.EDIT_ORDERER), ordererData);
    } else if (ordererFields.some((field) => caseData.orderer[field])) {
      return this.http.post<any>(this.authService.getURL('createOrderer') || '', ordererData);
    } else {
      return of({});
    }
  }

  private subscribersRequest(caseData: any, caseResult: any): Observable<any> {
    if (caseData.subscribers) {
      return this.saveSubscribers(
        caseData.subscribers,
        caseResult._links.editSubscribersCase.href
      ).pipe(mergeMap(() => of(caseResult)));
    } else {
      return of(caseResult);
    }
  }

  private caseRequest(caseData, ordererId, patientId) {
    const caseFields = [
      'assayId',
      'caseId',
      'clinicalSynopsis',
      'diagnosisExternalId',
      'indication',
      'specimen',
      'vcfFile',
      'bamFile',
      'cnvs',
      'fusions',
      'tmb',
      'msi',
      'biomarkerFiles'
    ];

    if (caseData.editMode) caseFields.push('vcfId', 'bamId');

    const specimenFields = [
      'accessionNumber',
      'collectionDate',
      'receiptDate',
      'site',
      'tumorPurity',
      'type'
    ];

    const caseBody = CaseService.setEmptyStringsToNull(
      Object.assign(
        _pick(caseData, caseFields),
        { patientId, ordererId },
        {
          specimen: CaseService.setEmptyStringsToNull(_pick(caseData.specimen, specimenFields))
        }
      )
    );

    if (caseData.editMode) {
      return this.http.put<any>(this.getCurrentCaseLink(CaseLinkKeys.EDIT_CASE), caseBody);
    } else {
      return this.http.post<any>(this.authService.getURL('createCase') || '', caseBody);
    }
  }

  private setCaseSaveError(response: any) {
    this._saveCaseError.next(_get(response, 'error.message', 'CASE_SAVE_ERROR'));
  }

  saveCase(caseData) {
    let patientId;

    this._savingCase.next(true);

    return this.patientRequest(caseData).pipe(
      mergeMap((json: CaseData) => {
        patientId = json.id;
        return this.ordererRequest(caseData, patientId);
      }),
      mergeMap((ordererJSON: any) => this.caseRequest(caseData, ordererJSON.id, patientId)),
      mergeMap((caseResult: any) => this.subscribersRequest(caseData, caseResult)),
      tap((caseResult: any) => {
        if (caseData.filesToUpload.length > 0) {
          this.fileUploadService.uploadFile(
            caseResult._links.upload.href,
            caseData.filesToUpload,
            caseData.caseId,
            caseData.editMode
          );
        }
      }),
      tap(() => this.resetErrorCode()),
      catchError((response) => {
        this.setCaseSaveError(response);
        throw response;
      }),
      finalize(() => this._savingCase.next(false))
    );
  }

  moveCaseInAnalysis(caseId: string) {
    this._currentCase.next({});
    this._loadingCaseAndPatient.next(true);

    // TODO in ISTN-5829: use the actual originalId when calling the "checkStartAnalysis" URL.
    return this.http
      .put(
        this.authService.getURL('checkStartAnalysis', { caseId: caseId, originalId: caseId }),
        {}
      )
      .pipe(catchError((err) => of(null)));
  }

  resetCurrentCase() {
    this._currentCase.next({});
    this._patientRevision = null;
  }

  loadSubscribers() {
    this._loadingSubscribers.next(true);

    return this.http
      .get(this.getCurrentCaseLink(CaseLinkKeys.VIEW_SUBSCRIBERS_CASE))
      .pipe(finalize(() => this._loadingSubscribers.next(false)))
      .subscribe({
        next: (res) => {
          this._subscribers.next(res['subscribers']);
          this._loadingSubscribersError.next(false);
        },
        error: () => this._loadingSubscribersError.next(true)
      });
  }

  saveSubscribers(ids: string[], url?: string) {
    this._savingSubscribers.next(true);
    return this.http
      .put(url || this.getCurrentCaseLink(CaseLinkKeys.EDIT_SUBSCRIBERS_CASE), {
        subscribers: ids
      })
      .pipe(finalize(() => this._savingSubscribers.next(false)));
  }

  loadSubscriberTargets(): Observable<CaseSubscriber[]> {
    if (this._subscriberTargets.length > 0) {
      return of(this._subscriberTargets);
    }

    this._loadingSubscriberTargets.next(true);

    return this.http.get<CaseSubscriber[]>(this.authService.getURL('listSubscribersCase')).pipe(
      tap((res: CaseSubscriber[]) => (this._subscriberTargets = res)),
      finalize(() => this._loadingSubscriberTargets.next(false))
    );
  }

  loadCaseNameList() {
    this._loadingCaseNameList.next(true);
    return this.http
      .get<any[]>(this.authService.getURL('listCaseNames'))
      .pipe(finalize(() => this._loadingCaseNameList.next(false)))
      .subscribe((cases) => {
        this._caseNameList.next(cases);
      });
  }

  /*
   * Recieves an object and the path to the entities whom diagnosis
   * should be translated and returns an Observable which
   * will return the translated entity
   */
  renameDiagnosis<T>(entity: T, diagnosisListPath, keys?: any[]): Observable<T> {
    const list = _get(entity, diagnosisListPath);
    return !list
      ? of(entity)
      : this.loadingDiagnosis.pipe(
          filter((loaded) => !loaded),
          first(),
          map(() => {
            keys = keys || Object.keys(list);
            keys.forEach((key) => {
              const entity = list[key];
              if (entity) {
                entity.diagnosis.name = this.getDiagnosisName(entity.diagnosis.externalId);
              }
            });
            return entity;
          })
        );
  }

  updateVirtualGenePanel(genePanelId: string): Observable<any> {
    const url = this.getCurrentCaseLink(CaseLinkKeys.UPDATE_VIRTUAL_GENE_PANEL, {
      vgpId: genePanelId
    });
    this._updatingVirtualGenePanel.next(true);
    return this.http.put(url, {}).pipe(
      map((data) => data),
      finalize(() => this._updatingVirtualGenePanel.next(false))
    );
  }

  onEditDiagnosis$(): Observable<CaseChangeDiagnosisResponseData | HttpErrorResponse> {
    const editDiagnosisLink = this.getCurrentCaseLink(CaseLinkKeys.EDIT_CASE_CLINICAL);
    const requestBody = {
      operation: CaseLinkKeys.EDIT_CASE_CLINICAL
    };
    this._changingCaseDiagnosis.next(true);

    return this.http
      .post<CaseChangeDiagnosisResponseData | HttpErrorResponse>(
        editDiagnosisLink || '',
        requestBody
      )
      .pipe(finalize(() => this._changingCaseDiagnosis.next(false)));
  }

  onProcessCaseForChangeDiagnosis$(
    requestBody: CaseChangeDiagnosisUpdatedData
  ): Observable<CaseChangeDiagnosisResponseData | HttpErrorResponse> {
    if (!requestBody) {
      return;
    }

    const editDiagnosisLink = this.getCurrentCaseLink(CaseLinkKeys.EDIT_CASE_CLINICAL);
    this._changingCaseDiagnosis.next(true);
    return this.http
      .patch<CaseChangeDiagnosisResponseData | HttpErrorResponse>(editDiagnosisLink, {
        ...requestBody,
        operation: CaseLinkKeys.EDIT_CASE_CLINICAL
      })
      .pipe(finalize(() => this._changingCaseDiagnosis.next(false)));
  }
}
