<div class="page-content-no-header page-container">
  <div id="okta-login-container"></div>
  <div class="cookie-policy-container">
    <a href="https://www.roche.com/cookie-notice/" rel="noopener" target="_blank" i18n="FooterBar.CookiePolicy@@cookie-policy">Cookie
      Policy</a> |
    <!-- OneTrust Cookies Settings button start -->
    <a (click)="onToggleCookieInfoDisplay()" id="ot-sdk-btn" class="ot-sdk-show-settings" i18n="FooterBar.CookiePolicy@@cookie-settings">Cookie
      Settings</a>
    <!-- OneTrust Cookies Settings button end -->
  </div>
  <div *ngIf="showRuoMessage" class="ruo-message mat-body-2">
    <app-translation translationKey="RUO_MESSAGE"></app-translation>
  </div>
  <div *ngIf="showEvalMessage || showBetaMessage" class="page-disclaimer mat-caption"
    i18n="LoginPage.Notes(pageDisclaimer,caption)@@for-evaluation-purposes-only">
    For evaluation purposes only
  </div>
</div>
