<!--
This is where we add the strings and corresponding IDs for use in email templates.
The `i18n:extract` command will pick them up and bundle them into the master XLF file.
The backend can then use the appropriate IDs for email generation.
Add entries with an i18n ID that begins with "email-"
-->

<!--Email 1: When an internal note has been added to the case-->
<ng-container i18n="@@email-subject-comments">Comments were added to case</ng-container>
<ng-container i18n="@@email-subject-comments-ruo">Comments were added to research case</ng-container>
<ng-container i18n="@@email-comment-added">A comment was added to Case</ng-container>
<ng-container i18n="@@email-comment-added-ruo">A comment was added to Research Case</ng-container>

<!--Email 2a: When case status transitions to Ready for analysis-->
<ng-container i18n="@@email-subject-ready-analysis">Ready for analysis: case</ng-container>
<ng-container i18n="@@email-subject-ready-analysis-ruo">Ready for analysis: research case</ng-container>
<ng-container i18n="@@email-case-ready-analysis">is ready for analysis.</ng-container>

<!--Email 2b: When case status transitions to Data file error -->
<ng-container i18n="@@email-subject-data-error">Data file error: case</ng-container>
<ng-container i18n="@@email-subject-data-error-ruo">Data file error: research case</ng-container>
<ng-container i18n="@@email-case-data-error">Unable to process due to data file error.</ng-container>

<!--Email 3: When case status transitions to sent for approval-->
<ng-container i18n="@@email-subject-sent-approval">Sent for Approval: case</ng-container>
<ng-container i18n="@@email-subject-sent-confirmation-ruo">Sent for Confirmation: research case</ng-container>
<ng-container i18n="@@email-case-sent-approval">has been sent for approval.</ng-container>
<ng-container i18n="@@email-case-sent-confirmation-ruo">has been sent for confirmation.</ng-container>

<!--Email 4: When case status transitions to approved/rejected -->
<ng-container i18n="@@email-subject-transition-approved">Approved: case</ng-container>
<ng-container i18n="@@email-subject-transition-confirmed-ruo">Confirmed: research case</ng-container>
<ng-container i18n="@@email-case-transition-approved">has been approved.</ng-container>
<ng-container i18n="@@email-case-transition-confirmed-ruo">has been confirmed.</ng-container>
<ng-container i18n="@@email-subject-transition-rejected">Rejected: case</ng-container>
<ng-container i18n="@@email-subject-transition-rejected-ruo">Rejected: research case</ng-container>
<ng-container i18n="@@email-case-transition-rejected">has been rejected.</ng-container>

<!--Email 5: When users are subscribed to a case -->
<ng-container i18n="@@email-subject-subscribed">Subscribed to: case</ng-container>
<ng-container i18n="@@email-subject-subscribed-ruo">Subscribed to: research case</ng-container>
<ng-container i18n="@@email-case-subscribed">You have been subscribed to Case</ng-container>
<ng-container i18n="@@email-case-subscribed-ruo">You have been subscribed to Research Case</ng-container>

<!--Email 6:  When users are unsubscribed from a case  -->
<ng-container i18n="@@email-subject-unsubscribed">Unsubscribed from: case</ng-container>
<ng-container i18n="@@email-subject-unsubscribed-ruo">Unsubscribed from: research case</ng-container>
<ng-container i18n="@@email-case-unsubscribed">You have been unsubscribed from Case</ng-container>
<ng-container i18n="@@email-case-unsubscribed-ruo">You have been unsubscribed from Research Case</ng-container>
<ng-container i18n="@@email-unsubscribe-message">You are receiving this email because you were unsubscribed from this case.</ng-container>
<ng-container i18n="@@email-unsubscribe-message-ruo">You are receiving this email because you were unsubscribed from this research case.</ng-container>

<!--Email 7: For Roche admin to send when content update is available-->
<ng-container i18n="@@email-subject-content-update">content update for navify® Mutation Profiler</ng-container>
<ng-container i18n="@@email-new-content-update">New content update for</ng-container>
<ng-container i18n="@@email-content-available">is now available for navify® Mutation Profiler.</ng-container>
<ng-container i18n="@@email-content-select-message">You can select this version in your Assay configuration.</ng-container>
<ng-container i18n="@@email-content-update-subscribe-message">You are receiving this email because you are subscribed to content update notifications.</ng-container>
<ng-container i18n="@@email-view-content-update-notes">You can view content update notes at</ng-container>

<!--Common strings-->
<ng-container i18n="@@email-case-subscribe-message">You are receiving this email because you are subscribed to notifications for this case.</ng-container>
<ng-container i18n="@@email-case-subscribe-message-ruo">You are receiving this email because you are subscribed to notifications for this research case.</ng-container>
<ng-container i18n="@@email-sent-by-message">This message was sent by navify® Mutation Profiler</ng-container>
<ng-container i18n="@@email-hi">Hi</ng-container>
<ng-container i18n="@@email-case">Case</ng-container>
<ng-container i18n="@@email-research-case-ruo">Research Case</ng-container>
<ng-container i18n="@@email-new">New</ng-container>
