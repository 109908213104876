export enum CaseStatus {
  NEW = 'NEW',
  UPLOADING = 'UPLOADING',
  FILE_ERROR = 'FILE_ERROR',
  PROCESSING = 'PROCESSING',
  READY_FOR_ANALYSIS = 'READY_FOR_ANALYSIS',
  ANALYSIS = 'ANALYSIS',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  SIGNED = 'SIGNED',
  CANCELED = 'CANCELED',
  IMPORTED = 'IMPORTED'
}
