import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TierNames } from 'app/model/valueObjects/ampTier';

@Component({
  selector: 'app-tier-description',
  templateUrl: './tier-description.component.html',
  styleUrls: ['./tier-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TierDescriptionComponent {
  @Input() value: string;
  TierNames = TierNames;
}
