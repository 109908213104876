import { BaseEntity } from './baseEntity';

export class ClinicalTrial extends BaseEntity {
  id: string;
  phase: string;
  description: string;
  include: boolean;
  biomarkers: string[];
  link: string;
  matchType: string;
  constructor(jsonData: any) {
    super(jsonData);
  }
}
