import { Component } from '@angular/core';

@Component({
  selector: 'app-ruo-popup',
  templateUrl: './ruo-popup.component.html',
  styleUrls: ['./ruo-popup.component.scss']
})
export class RuoPopupComponent {
  visible: boolean = false;

  close(): void {
    this.visible = false;
  }

  open(): void {
    this.visible = true;
  }
}
