import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableSort } from 'app/model/entities/OwcTableSortedDataSource';

/**
 * Sets the OWC table sorting icon based on the current sorting column and order.
 */
@Directive({
  selector: '[appSortOrderIconSetter]'
})
export class SortOrderIconSetterDirective implements OnChanges {
  @Input() sortConfig: TableSort | null;
  @Input() columnName: string;
  constructor(private _elRef: ElementRef) {}

  ngOnChanges({ sortConfig }: SimpleChanges): void {
    if (sortConfig) {
      const sortDirection =
        this.sortConfig?.column === this.columnName ? this.sortConfig?.order : '';
      this._elRef.nativeElement.setAttribute('sort-order', sortDirection);
    }
  }
}
