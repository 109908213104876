import { BaseEntity } from './baseEntity';
import { AnnotationDisease } from './annotationDisease';
export class TcgaAnnotation extends BaseEntity {
  firstCancer: string;
  firstCancerCount: number;
  firstMutationCount: number;
  secondCancer: string;
  secondCancerCount: number;
  secondMutationCount: number;
  thirdCancer: string;
  thirdCancerCount: number;
  thirdMutationCount: number;
  diseases: AnnotationDisease[];

  constructor(jsonData: any) {
    super(jsonData);

    this.diseases = [];

    if (jsonData.firstCancer) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.firstCancer,
          jsonData.firstCancerCount,
          jsonData.firstMutationCount
        )
      );
    }

    if (jsonData.secondCancer) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.secondCancer,
          jsonData.secondCancerCount,
          jsonData.secondMutationCount
        )
      );
    }

    if (jsonData.thirdCancer) {
      this.diseases.push(
        new AnnotationDisease(
          jsonData.thirdCancer,
          jsonData.thirdCancerCount,
          jsonData.thirdMutationCount
        )
      );
    }
  }
}
