import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

/**
 * To add a custom icon,
 * 1. Register it here by adding to constant 'svgIcons' in constructor:
 *    this.svgIcon('<iconName>', '<iconPath>')
 * 2. Use the icon in component template:
 *    <mat-icon svgIcon="<iconName>"></mat-icon>
 */

@Injectable()
export class CustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    const svgIcons = [
      this.svgIcon('variant-tag', '../../assets/icons/variant_tag_icon.svg'),
      this.svgIcon('pertinent-negative', '../../assets/icons/pertinent_negative_icon.svg'),
      this.svgIcon('pin', '../../assets/icons/pin_icon.svg'),
      this.svgIcon('unpin', '../../assets/icons/unpin_icon.svg'),
      this.svgIcon('ce-marking', '../../assets/icons/7.002.1_CE_marking.svg'),
      this.svgIcon('catalogue-number', '../../assets/icons/7.014.1_Catalogue_number.svg'),
      this.svgIcon('manufacturer', '../../assets/icons/7.015.1_Manufacturer.svg'),
      this.svgIcon(
        'authorized-representative',
        '../../assets/icons/7.016.1_Authorized_representative_in_the_EC.svg'
      ),
      this.svgIcon(
        'medical-device',
        '../../assets/icons/7.019.1_In_vitro_diagnostic_medical_device.svg'
      ),
      this.svgIcon('gtin', '../../assets/icons/7.046.1_GTIN.svg'),
      this.svgIcon(
        'operating-instructions',
        '../../assets/icons/8.001.1_Operators_manual_operating_instructions.svg'
      ),
      this.svgIcon('toggle-on', '../../assets/icons/baseline-toggle_on-24px.svg'),
      this.svgIcon('toggle-off', '../../assets/icons/baseline-toggle_off-24px.svg'),
      this.svgIcon('remove-from-queue', '../../assets/icons/baseline-remove_from_queue-24px.svg'),
      this.svgIcon('add-to-queue', '../../assets/icons/baseline-add_to_queue-24px.svg'),
      this.svgIcon('date-of-manufacturing', '../../assets/icons/7.008.1_Date_of_manufacture.svg'),
      this.svgIcon('rx-only', '../../assets/icons/7.097.1_RX_only.svg'),
      this.svgIcon('data-sharing-colors', '../../assets/icons/data-sharing-one-color-blue-900.svg'),
      this.svgIcon('data-sharing', '../../assets/icons/data-sharing.svg'),
      this.svgIcon('how-to-reg', '../../assets/icons/baseline-how_to_reg-24px.svg'),
      this.svgIcon('analytics', '../../assets/icons/analytics-24px.svg'),
      this.svgIcon('clinical-variant', '../../assets/icons/variant-clinical.svg'),
      this.svgIcon('clinical-gene', '../../assets/icons/gene-match-24px.svg'),
      this.svgIcon('unknown-variant-icon', '../../assets/icons/help-outline-24px.svg'),
      this.svgIcon('reclassify', '../../assets/icons/reclassified-icon-24px.svg'),
      this.svgIcon('bulk-reclassify', '../../assets/icons/bulk-reclassified-icon-24px.svg'),
      this.svgIcon('unique-device-identifier', '../../assets/icons/unique-device-identifier.svg'),
      this.svgIcon('importer', '../../assets/icons/importer.svg')
    ];

    svgIcons.forEach((icon: { name: string; path: string }) =>
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      )
    );
  }

  private svgIcon(name: string, path: string): { name: string; path: string } {
    return { name, path };
  }
}
