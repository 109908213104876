import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() inputControl: FormControl;
  @Output() blurEvent = new EventEmitter<boolean>();
  errorCode$: Observable<string | null>;

  ngOnInit() {
    this.errorCode$ = this.getFirstErrorCode$();
  }

  numberInputValidation(event): void {
    const pattern = /^[\d+-.eE]*$/; // same with number type input
    const inputValue = String.fromCharCode(event.charCode);
    if (!pattern.test(inputValue)) {
      event.preventDefault();
    }
  }

  // Three possible errors: pattern, max and required
  // pattern and max will share same error message
  getFirstErrorCode$(): Observable<string | null> {
    return this.inputControl.statusChanges.pipe(
      startWith(this.inputControl.status),
      map(() => {
        const errors = this.inputControl.errors;
        return errors ? Object.keys(errors)[0] : null;
      })
    );
  }
}
