import { BaseEntity } from './baseEntity';
import { AnnotationSourceType } from '../valueObjects/annotationSourceType';
import { omit as _omit } from 'lodash';

export class AnnotationSource extends BaseEntity {
  bundleChecksum?: string;
  bundleUrl?: string;
  createdAt?: string;
  createdByUserId?: string;
  releaseNotes: string;
  snapshotUrl?: string;
  type: AnnotationSourceType;
  datasourceId: string;
  version: string;
  updateEmail?: {
    status: string;
    lastModifiedAt: string;
  };
  sentUpdateEmail?: boolean; // This is only used on UI to disable the menu after sending email once for the update.

  constructor(jsonData: Object) {
    super(_omit(jsonData, 'name'));
  }

  get name() {
    return `${this.type} ${this.version}`;
  }
}
