export enum SessionLinkKeys {
  CREATE_USER = 'createUser',
  EXPORT_ORGANIZATION_STATISTICS = 'exportOrganizationStatistics',
  VIEW_USER = 'viewUser',
  OPERATIONAL_ANALYTICS = 'operationalAnalytics',
  LIST_GENES = 'listGenes',
  EXPORT_USER_LIST = 'exportUserList',
  CAIR_LITE_BUILD = 'cairLiteBuild',
  LIST_AUDIT_CASE_SUMMARY = 'listAuditCaseSummary',
  LIST_STATES_FOR_COUNTRY = 'listStatesForCountry',
  EXPORT_API_FIELD_MAPPING = 'exportApiFieldMapping',
  LIST_COUNTRIES = 'listCountries',
  APP_LANGUAGE = 'appLanguage',
  VIEW_SHARING = 'viewSharing',
  LIST_INTERPRETATION_CASE_SUMMARY = 'listInterpretationCaseSummary',
  VIEW_VARIANT_ANALYTICS = 'viewVariantAnalytics',
  LIST_CLOSED_CASES = 'listClosedCases',
  LIST_APP_SETTINGS_VALUES = 'listAppSettingsValues',
  LIST_SPECIMEN_TYPES = 'listSpecimenTypes',
  VIEW_ORG_LOGO = 'viewOrgLogo',
  LIST_CASES_IN_ANALYSIS = 'listCasesInAnalysis',
  VIEW_LICENSES = 'viewLicenses',
  LIST_USER_NAMES = 'listUserNames',
  LIST_DIAGNOSIS = 'listDiagnosis',
  CREATE_ORGANIZATION = 'createOrganization',
  VIEW_UPGRADE_STATUS = 'viewUpgradeStatus',
  LIST_REPORT_SETTINGS_VALUES = 'listReportSettingsValues',
  CREATE_LANGUAGE_PACK = 'createLanguagePack',
  LIST_CATEGORIES_AND_VARIANTS = 'listCategoriesAndVariants',
  VIEW_REPORT_SETTINGS = 'viewReportSettings',
  LIST_DATA_SOURCES = 'listDatasources',
  VIEW_OPEN_SOURCE = 'viewOpenSource',
  LIST_ASSAYS = 'listAssays',
  LIST_CASE_NAMES = 'listCaseNames',
  VIEW_APP_SETTINGS = 'viewAppSettings',
  LIST_SPECIMEN_SITES = 'listSpecimenSites',
  HELP_PLUGIN = 'helpPlugin',
  EXPORT_AUDIT_RECORD = 'exportAuditRecord',
  LIST_USERS = 'listUsers',
  VIEW_API_KEY_METADATA = 'viewApiKeyMetadata',
  VIEW_DATA_SHARING_ANALYTICS = 'viewDataSharingAnalytics',
  VIEW_ORGANIZATION = 'viewOrganization',
  LIST_BIOMARKERS = 'listBiomarkers',
  LIST_ORGANIZATIONS = 'listOrganizations',
  TREATMENT_BUILD = 'treatmentBuild',
  LIST_CASES_IN_PROGRESS = 'listCasesInProgress',
  CREATE_DATA_SOURCE = 'createDatasource',
  EDIT_APP_SETTINGS = 'editAppSettings',
  VIEW_CONTENT_RELEASE_NOTE = 'viewContentReleaseNote',
  START_UPGRADE = 'startUpgrade',
  LIST_ASSAY_NAMES = 'listAssayNames',
  EDIT_REPORT_SETTINGS = 'editReportSettings',
  SEARCH_CASE = 'searchCase',
  EXPORT_CASES = 'exportCases',
  VIEW_FEATURES = 'viewFeatures',
  CREATE_OR_EDIT_SHARING = 'createOrEditSharing',
  VIEW_ASSAY = 'viewAssay',
  EDIT_ASSAY = 'editAssay',
  CREATE_ASSAY = 'createAssay',
  CREATE_CASE = 'createCase',
  PARSE_VARIANT_TAGS = 'parseVariantTags',
  MIGRATE_PERTINENT_NEGATIVE_BIOMARKERS = 'migratePertinentNegativeBiomarkers',
  COUNT_BY_ASSAY_NAME = 'countByAssayName',
  COMPARE_ROCHE_CONTENT_DISEASES = 'compareRocheContentDiseases',
  ROCHE_CONTENT = 'rocheContent',
  DETECT_OUTPUT_FILE_FORMAT = 'detectOutputFileFormat',
  VALIDATE_VGP_COMPATIBILITY = 'validateVgpCompatibility',
  UPLOAD_VGP_FILE = 'uploadVGPFile',
  SEARCH_INTERPRETATION = 'searchInterpretation',
  EXPORT_VARIANT_CUSTOMS = 'exportVariantCustoms',
  EXPORT_DIAGNOSIS = 'exportDiagnosis',
  LIST_SUBSCRIBERS_CASE = 'listSubscribersCase',
  VIEW_TRIAL_RESULT = 'viewTrialResult',
  VIEW_TRIAL_METADATA = 'viewTrialMetadata',
  VIEW_TRIAL_FILTER = 'viewTrialFilter'
}
