import { BaseEntity } from './baseEntity';
export class Dialog extends BaseEntity {
  type: string;
  data?: any;
  componentToRender?: any;
  negativeButton?: string;
  positiveButton?: string;
  positiveButtonPrimary?: boolean;
  width: string;
  height: string;
  maxHeight?: string;
  minHeight?: string;
  maxWidth?: string;
  minWidth?: string;

  constructor(configData: any) {
    super(configData);
  }
}
