import { Injectable } from '@angular/core';
import {
  combineLatest as observableCombineLatest,
  Observable,
  Subject,
  BehaviorSubject
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable()
export class LoaderService {
  private _loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _owcProgressSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * TODO: Phase 4: Remove old implementation once OWC migration is finished for all pages.
   * Reference: https://rds-csi.atlassian.net/browse/ISTN-6656
   * */
  get loadingStatus$(): Observable<boolean> {
    return this._loadingStatus.asObservable();
  }

  get owcProgressSpinner$(): Observable<boolean> {
    return this._owcProgressSpinner.asObservable();
  }

  static generateLoadingObservable(
    loadingIndicators: Observable<boolean>[],
    unsubscribe: Subject<any>
  ): Observable<boolean> {
    return observableCombineLatest(loadingIndicators).pipe(
      takeUntil(unsubscribe),
      map((loadingArray: boolean[]) => loadingArray.includes(true))
    );
  }

  enable(): void {
    this._loadingStatus.next(true);
  }

  disable(): void {
    this._loadingStatus.next(false);
  }

  /**
   * TODO: Phase 4: Remove old implementation once OWC migration is finished for all pages.
   * Reference: https://rds-csi.atlassian.net/browse/ISTN-6656
   * */
  showLoadingMask(loadingIndicators: Observable<boolean>[], unsubscribe: Subject<any>): void {
    LoaderService.generateLoadingObservable(loadingIndicators, unsubscribe).subscribe((result) => {
      if (result) this.enable();
      else this.disable();
    });
  }

  showOwcLoadingMask(loadingIndicators: Observable<boolean>[], unsubscribe: Subject<any>): void {
    LoaderService.generateLoadingObservable(loadingIndicators, unsubscribe).subscribe((result) => {
      if (result) this.enableOwcLoader();
      else this.disableOwcLoader();
    });
  }

  enableOwcLoader(): void {
    this._owcProgressSpinner.next(true);
  }

  disableOwcLoader(): void {
    this._owcProgressSpinner.next(false);
  }
}
