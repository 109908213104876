import { Component, Input } from '@angular/core';
import { LastUpdated } from 'app/model/valueObjects/lastUpdatedEnum';

@Component({
  selector: 'app-last-saved',
  templateUrl: './last-saved.component.html'
})
export class LastSavedComponent {
  @Input() value: string;
  lastUpdated = LastUpdated;
}
