import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'app/services/dialog.service';
import { Dialog } from '../model/entities/dialog';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CandeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(public dialog: MatDialog, private dialogService: DialogService) {}

  canDeactivate(targetComponent: ComponentCanDeactivate) {
    return targetComponent.canDeactivate ? targetComponent.canDeactivate() : true;
  }

  openDialog(popupCode) {
    const dialog = new Dialog({
      type: popupCode,
      negativeButton: 'CANCEL',
      positiveButton: popupCode === 'LEAVE_PAGE_DIALOG' ? 'DISCARD_CHANGES' : 'CONTINUE',
      width: '460px',
      height: 'auto'
    });
    return this.dialogService.open(dialog);
  }
}
