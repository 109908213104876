import { CommonService } from 'app/services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { Localization } from 'app/model/valueObjects/localization';
import { TierClassification } from 'app/model/entities/dataSharing';
import { TIER_LIST } from 'app/model/valueObjects/ampTier';
import { AuthService } from 'app/services/auth.service';
import { uniq as _uniq } from 'lodash';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'app-roche-network-vf-panel',
  templateUrl: './roche-network-vf-panel.component.html',
  styleUrls: ['./roche-network-vf-panel.component.scss']
})
export class RocheNetworkVFPanelComponent implements OnInit {
  @Input() classification: TierClassification;
  @Input() updatedAt: string;
  @Input() optedIn: boolean;
  @Input() variant: any;
  @Input() diagnosis: any;
  combinedVariantName$: Observable<string>;
  hasArrayName$: Observable<boolean>;
  authorizedToShare$: Observable<boolean>;

  tierList = TIER_LIST;

  get authorizedToShareGetter$(): Observable<boolean> {
    return of(this.authService.hasPermission(SessionLinkKeys.CREATE_OR_EDIT_SHARING));
  }

  get hasArrayNameGetter$(): Observable<boolean> {
    return of(this.variant).pipe(map((variant) => variant && Array.isArray(variant.name)));
  }

  get combinedVariantNameGetter$(): Observable<string> {
    return this.hasArrayNameGetter$.pipe(
      map((hasArrayName) => {
        if (hasArrayName) {
          return CommonService.stringVariantName(this.variant.name);
        } else {
          return this.variant && (this.variant.name || this.variant.value);
        }
      })
    );
  }

  constructor(
    public localization: Localization,
    public commonService: CommonService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.hasArrayName$ = this.hasArrayNameGetter$;
    this.combinedVariantName$ = this.combinedVariantNameGetter$;
    this.authorizedToShare$ = this.authorizedToShareGetter$;
  }

  differentClinicalRegions(regions: string[]): string[] {
    return regions ? _uniq(regions) : [];
  }
}
