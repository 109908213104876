import { BaseEntity } from './baseEntity';

interface MitelmanAnnotationItem {
  singleGene?: string;
  threePrimeGene?: string;
  fivePrimeGene?: string;
  clinicalUrl?: string;
  molecularUrl?: string;
  clinicalCount?: number;
  molecularCount?: number;
}

export class MitelmanAnnotation extends BaseEntity {
  annotations: MitelmanAnnotationItem[];

  constructor(jsonData: any) {
    super(jsonData);
  }
}
