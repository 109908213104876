import { HttpClient } from '@angular/common/http';
import { CaseData } from 'app/model/entities/CaseList';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { finalize, mergeMap, map } from 'rxjs/operators';
import { pick as _pick } from 'lodash';
import { CaseListService } from './case-list.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { get as _get } from 'lodash';

import {
  SearchCaseRequestData,
  SearchFormData,
  CaseDataResponse,
  InterpretationRequestData,
  CaseSummaryResponse
} from 'app/model/entities/search';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class SearchService {
  private _searchResults = new BehaviorSubject<CaseData[]>([]);
  private _loadingSearch = new BehaviorSubject<boolean>(false);
  private loadMoreCasesUrl: string = null;
  private lastSearchRequest;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private caseListService: CaseListService
  ) {}

  get casesFromSearch(): Observable<CaseData[]> {
    return this._searchResults.asObservable();
  }

  get loadingSearch(): Observable<boolean> {
    return this._loadingSearch.asObservable();
  }

  private interpretationSearch(
    interpretationRequest: InterpretationRequestData
  ): Observable<CaseSummaryResponse> {
    const url = this.authService.getURL(SessionLinkKeys.SEARCH_INTERPRETATION);
    return this.http.post<CaseSummaryResponse>(url, interpretationRequest);
  }

  private caseSearch(searchCaseRequest: SearchCaseRequestData): Observable<CaseData[]> {
    this.lastSearchRequest = searchCaseRequest;
    const url = this.authService.getURL(SessionLinkKeys.SEARCH_CASE);
    return this.http.post<CaseDataResponse>(url, searchCaseRequest).pipe(
      map((res: CaseDataResponse) => {
        this.loadMoreCasesUrl = _get(res._links, 'next.href', null);
        return res._embedded.caseUploadSummaries;
      })
    );
  }

  search(data: SearchFormData) {
    this._loadingSearch.next(true);
    const interpretationData = <InterpretationRequestData>_pick(data, ['categoryOrVariant']);
    const caseId = _get(data.caseName, 'id');
    let criteria = {
      caseIds: null,
      diagnosisId: _get(data.diagnosis, 'externalId', null)
    };
    if (caseId) {
      criteria.caseIds = [data.caseName.id];
    }
    const request = !interpretationData.categoryOrVariant
      ? this.caseSearch(criteria)
      : this.interpretationSearch(interpretationData).pipe(
          mergeMap((cases: CaseSummaryResponse) => {
            const nothingMatched = cases.caseSummaries.length === 0;
            const previousCaseIdIsNotPresent = caseId && !cases.caseSummaries.includes(caseId);
            // there's no need for another request if nothing matched or the chosen case is not matched
            if (previousCaseIdIsNotPresent || nothingMatched) {
              return observableOf([]);
            } else if (caseId) {
              // Only send the case ID that was chosen, discard the other interpretation search results
              criteria.caseIds = [caseId];
            } else {
              criteria.caseIds = cases.caseSummaries;
            }
            return this.caseSearch(criteria);
          })
        );

    request
      .pipe(
        mergeMap((responses: CaseData[]) => this.caseListService.processCaseData([responses])),
        map((responses: Array<CaseData[]>) => responses[0]),
        finalize(() => this._loadingSearch.next(false))
      )
      .subscribe((cases) => {
        this._searchResults.next(cases);
      });
  }

  clearSearchResults() {
    this._searchResults.next([]);
  }

  get canLoadMoreCases() {
    return this.loadMoreCasesUrl ? true : false;
  }

  loadMoreCases() {
    this._loadingSearch.next(true);
    return this.http
      .post<CaseDataResponse>(this.loadMoreCasesUrl, this.lastSearchRequest)
      .pipe(
        map((res: CaseDataResponse) => {
          this.loadMoreCasesUrl = _get(res._links, 'next.href', null);
          return res._embedded.caseUploadSummaries;
        }),
        mergeMap((responses: CaseData[]) => this.caseListService.processCaseData([responses])),
        map((responses: Array<CaseData[]>) => responses[0]),
        finalize(() => this._loadingSearch.next(false))
      )
      .subscribe((cases: CaseData[]) => {
        const searchResultList = this._searchResults.value.concat(cases);
        this._searchResults.next(searchResultList);
      });
  }
}
