import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AdminAnnotationSource } from '../model/entities/adminAnnotationSource';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

@Injectable()
export class AnnotationSourcesService {
  private _annotationSourceList = new BehaviorSubject<AdminAnnotationSource[]>([]);
  private _loadingAnnotationSourceList = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get loadingAnnotationSourceList$() {
    return this._loadingAnnotationSourceList.asObservable();
  }

  get annotationSourceList$() {
    return this._annotationSourceList.asObservable();
  }

  load() {
    this._loadingAnnotationSourceList.next(true);
    this._annotationSourceList.next([]);
    return this.http
      .get(this.authService.getURL(SessionLinkKeys.LIST_DATA_SOURCES))
      .pipe(finalize(() => this._loadingAnnotationSourceList.next(false)))
      .subscribe((data: any) => {
        this._annotationSourceList.next(
          data._embedded.datasources.map((source) => new AdminAnnotationSource(source))
        );
      });
  }

  reset() {
    this._annotationSourceList.next([]);
  }

  create(annotationSourceData): Observable<any> {
    return this.http.post(
      this.authService.getURL(SessionLinkKeys.CREATE_DATA_SOURCE),
      annotationSourceData
    );
  }

  sendEmail(url: string) {
    return this.http.post(url, {});
  }
}
