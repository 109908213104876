<ng-container [ngSwitch]="oncogenicity">
  <span *ngSwitchCase="'GOF'" i18n="@@variant-oncogenicity-GOF">Gain of function</span>
  <span *ngSwitchCase="'GOFPredicted'" i18n="@@variant-oncogenicity-GOFPredicted"
    >Gain of function (predicted)</span
  >
  <span *ngSwitchCase="'LOF'" i18n="@@variant-oncogenicity-LOF">Loss of function</span>
  <span *ngSwitchCase="'LOFPredicted'" i18n="@@variant-oncogenicity-LOFPredicted"
    >Loss of function (predicted)</span
  >
  <span *ngSwitchCase="'Unknown'" i18n="@@variant-oncogenicity-Unknown">Unknown</span>
  <span *ngSwitchCase="'None'" i18n="@@variant-oncogenicity-None">None</span>
  <span *ngSwitchCase="'NoEffect'" i18n="@@variant-oncogenicity-noeffect">No effect</span>
  <span *ngSwitchCase="'NoEffectPredicted'" i18n="@@variant-oncogenicity-noeffectpredicted"
    >No effect (predicted)</span
  >
  <span *ngSwitchCase="'Ambiguous'" i18n="@@variant-oncogenicity-ambiguous">Ambiguous</span>
  <span *ngSwitchDefault class="cair-color-gray-400" i18n="Variant oncogenicity missing@@na"
    >n/a</span
  >
</ng-container>
