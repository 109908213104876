import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-pic',
  template: `
    <span class="avatar-pic" [ngStyle]="{ height: avatarSizePixels, width: avatarSizePixels }">
      <mat-icon [ngClass]="iconClasses">user</mat-icon>
    </span>
  `,
  styleUrls: ['./avatar-pic.component.scss']
})

/**
 * Component for avatar - Light blue circle with person icon
 * @param:
 *    - avatarSize: Total size of the avatar, Default is '30'.
 *    - iconClasses: Classes to be applied on the icon, default classes are 'md-24 md-light'. Supply 'md-light' for displaying icon in light color.
 * @example:
 *  <app-avatar-pic iconClasses="md-36 md-light" avatarSize="60"></app-avatar-pic>
 */
export class AvatarPicComponent {
  @Input() avatarSize = '30';
  @Input() iconClasses = 'md-24 md-light';

  get avatarSizePixels(): string {
    return this.avatarSize + 'px';
  }
}
