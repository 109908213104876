import { BaseEntity } from './baseEntity';
import { Coordinate } from './coordinate';
class BaseBiomarker extends BaseEntity {
  name: string;
}

export class Biomarker extends BaseBiomarker {
  id: number;
  coordinates: Coordinate[];

  constructor(jsonData: any) {
    super(jsonData);
    this.coordinates = jsonData.coordinates
      ? jsonData.coordinates.map((coordinate) => new Coordinate(coordinate))
      : [];
  }
}

export class PertinentNegativeBiomarker extends BaseBiomarker {
  id: string;
  pertinentNegative?: boolean;
}
