import { BaseEntity } from './baseEntity';

export class VariantTag extends BaseEntity {
  gene: number;
  variant: string;
  tags: string[];
}

export class VariantTagError extends BaseEntity {
  field: string;
  errorCode: string;
  records: any[];
}
